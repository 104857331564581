import React, { useState, useEffect } from 'react';
import {
  Container,
  Form,
  FormControl,
  Dropdown,
  Button,
  Table,
  Modal,
  FormGroup,
  ModalBody,
  Spinner,
} from 'react-bootstrap';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import '../assets/scss/Accounts.scss';
import { serverUrl } from '../serverUrl';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import CryptoJS from 'crypto-js';

function Accounts() {

  const [isLoading,setIsloading] = useState(false)
  const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
  const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const [isDescending, setIsDescending] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAccountModal, setAccountModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showOfficerModal, setOfficerModal] = useState(false);
  const [showAddOfficerModal, setAddOfficerModal] = useState(false);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [originalAccounts, setOriginalAccounts] = useState([])
  const [roData, setRoData] = useState([]);;
  const [formErrors, setFormErrors] = useState([]);;



  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const handleopenmodal = (roData) => {
    setSelectedAccount(roData);
    setShowDetailsModal(true);

  };
  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };

  //getting user id
  const decodedToken = jwtDecode(access);
  const userId = decodedToken.user_id;
  // display the selected franchise
  const selectedFranchise = localStorage.getItem('selectedFranchise');
  const decryptedData = decryptData(selectedFranchise, secretKey);

  const [formData, setFormData] = useState({
    roName: '',
    roPhoneNumber: '',
    roEmail: '',
    roDebtCollection: '',
    roVettedBusinesses: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [filterData, setFilterData] = useState({
    search: '',
    roName: '',
    roPhoneNumber: '',
    roEmail: '',
  });

  const applyFilters = () => {
    const { search, roName, roPhoneNumber, roEmail } = filterData;
    const filtered = roData.filter((account) => {
      return (
        (search === '' ||
          account.user.full_name.toLowerCase().includes(search.toLowerCase()) ||
          account.user.phone_number
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          account.user.email.toLowerCase().includes(search.toLowerCase())) &&
        (roName === '' ||
          account.user.full_name
            .toLowerCase()
            .includes(roName.toLowerCase())) &&
        (roPhoneNumber === '' ||
          account.user.phone_number.includes(roPhoneNumber)) &&
        (roEmail === '' ||
          account.user.email.toLowerCase().includes(roEmail.toLowerCase()))
      );
    });
    setFilteredAccounts(filtered);
    setShowFilterModal(false);
  };

  const [officer, setOfficer] = useState([]);
  const [officerData, setOfficerData] = useState({
    officerName: '',
    officerEmail: '',
    officerPhone: '',
    officerGender: '',
    officerDob: '',
    officerPassword: '',
  });

  const [emergencyContact, setEmergencyContact] = useState([]);
  const [emergencyContactData, setEmergencyContactData] = useState({
    EmergencyFullName: '',
    EmergencyPhone: '',
    EmergencyRelation: '',
    EmergencyEmail: '',
  });

  const validateForm = () => {
    const errors = {};

    // User Details validation
    if (!officerData.officerName) errors.officerName = 'Full Name is required';
    if (!officerData.officerEmail) errors.officerEmail = 'Email is required';
    if (!officerData.officerPhone) {
      errors.officerPhone = 'Phone Number is required';
    } else if (officerData.officerPhone.length !== 10) {
      errors.officerPhone = 'Phone Number must be 10 digits';
    }
    if (!officerData.officerGender) errors.officerGender = 'Gender is required';

    // Date of Birth validation
    if (!officerData.officerDob) {
      errors.officerDob = 'Date of Birth is required';
    } else {
      const dob = new Date(officerData.officerDob);
      const today = new Date();
      var age = today.getFullYear() - dob.getFullYear();
      const month = today.getMonth() - dob.getMonth();

      if (month < 0 || (month === 0 && today.getDate() < dob.getDate())) {
        age--;
      }

      if (age < 18) {
        errors.officerDob = 'Officer must be at least 18 years old';
      }
    }

    // Emergency Contact validation
    // Check if any of the fields are not empty
    const isAnyFieldNotEmpty = Object.values(emergencyContactData).some(field => field !== '');

    if (isAnyFieldNotEmpty) {
      if (!emergencyContactData.EmergencyFullName) errors.EmergencyFullName = 'Emergency Contact Full Name is required';
      if (!emergencyContactData.EmergencyRelation) errors.EmergencyRelation = 'Emergency Contact Relationship is required';
      if (!emergencyContactData.EmergencyPhone) {
        errors.EmergencyPhone = 'Emergency Contact Phone Number is required';
      } else if (emergencyContactData.EmergencyPhone.length !== 10) {
        errors.EmergencyPhone = 'Emergency Contact Phone Number must be 10 digits';
      }
      if (!emergencyContactData.EmergencyEmail) errors.EmergencyEmail = 'Emergency Contact Email is required';

    }

    return errors;
  };

  const handleUserDetails = (e) => {
    e.preventDefault();
    setLoading(true)
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const data = {
      full_name: officerData.officerName,
      email: officerData.officerEmail,
      phone_number: officerData.officerPhone,
      gender: officerData.officerGender,
      dob: officerData.officerDob,
      password: officerData.officerPhone,
    };
    
    serverUrl
      .post(`/user/register/`, data, config)
      .then((res) => {
        const roData = {
          user: res.data.data.id,
          franchise: decryptedData,
        };
        serverUrl.post(`/officers_management/relationship-officer/`, roData, config)
          .then((res) => {

            const isAnyFieldNotEmpty = Object.values(emergencyContactData).some(field => field !== '');

            if (isAnyFieldNotEmpty) {
              const emergencyData = {
                name: emergencyContactData.EmergencyFullName,
                phone_number: emergencyContactData.EmergencyPhone,
                relationship: emergencyContactData.EmergencyRelation,
                email: emergencyContactData.EmergencyEmail,
                relationshipOfficer: res.data.id,
              };
              serverUrl
                .post(`/officers_management/emergency-contact/`, emergencyData, config)
                .then((res) => {
                  toast.success('Successfully created the Emergency Contact');
                  setOfficerModal(false); setLoading(false)

                  serverUrl.get(`/officers_management/relationship-officer-summary/?franchise_id=${decryptedData}`, config)
                    .then(() => {
                      setRoData(res.data.results);
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                    
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            else {
              setLoading(false)
              toast.success('Successfully created the Field Officer');
              setOfficerModal(false);
              serverUrl.get(`/officers_management/relationship-officer-summary/?franchise_id=${decryptedData}`, config)
                .then((res) => {
                  setRoData(res.data);
                })
                .catch((error) => {
                  console.log(error)
                })

            }
          })
          .catch((error) => {
            console.log(error)

          })
      });
  };


  const handleAddOfficer = (e) => {
    e.preventDefault();
    handleUserDetails(e);

  };

  const handleOfficerChange = (e) => {
    const { name, value } = e.target;

    // Update officerData state
    if (name in officerData) {
      setOfficerData({
        ...officerData,
        [name]: value,
      });
    }

    // Update emergencyContactData state
    if (name in emergencyContactData) {
      setEmergencyContactData({
        ...emergencyContactData,
        [name]: value,
      });
    }
  };



  useEffect(() => {

    setIsloading(true)

    const fetchRoData = async () => {
      if (decryptedData) {
        try {
          const response = await serverUrl.get(
            `/officers_management/relationship-officer-summary/?franchise_id=${decryptedData}`,
            config
          );
          setRoData(response.data);
          setOriginalAccounts(response.data);
          setIsloading(false)
        } catch (error) {
          console.error('Error fetching officers data:', error);
          setIsloading(false)
        }
      }
    };

    fetchRoData();
  }, []);


  // Function to handle click on trash icon
  const handleDeleteClick = (roData) => {
    setSelectedAccount(roData);
    setShowDeleteModal(true);
  };

  const handledeleteSubmit = () => {
    serverUrl
      .delete(
        `/officers_management/relationship-officer/${selectedAccount.ro_id}/`,
        config
      )
      .then((res) => {

        setShowDeleteModal(false);


        serverUrl.get(`/officers_management/relationship-officer-summary/?franchise_id=${decryptedData}`, config)
          .then((res) => {
            setRoData(res.data);
            toast.success('officer deleted successfully');
            setShowDeleteModal(false);
          })
          .catch((error) => {
            console.error('Error deleting officer data:', error);
            // Show an error message using toast
            toast.error('Failed to delete officer data.');
            setShowDeleteModal(false);
          });
      })
      .catch((error) => {
        console.error('Error deleting officer:', error);
        // Show an error message using toast
        toast.error('Failed to delete officer. Please try again later.');
        setShowDeleteModal(false);
      });
  };

  const handleEditmodal = (roData) => {
    setSelectedAccount(roData);
    setShowEditModal(true);
  };
  const [showEditModal, setShowEditModal] = useState(false);
  const handleEditSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    const data = {
      full_name: selectedAccount.officerName,
      email: selectedAccount.officerEmail,
      phone_number: selectedAccount.officerPhone,
      gender: selectedAccount.officerGender,
      dob: selectedAccount.officerDob,
      password: selectedAccount.officerPhone,
    };

    // First, update user details
    serverUrl
      .patch(`/user/edit-user/${selectedAccount.id}`, data, config)
      .then((res) => {
        // Update relationship officer details
        const roData = {
          user: selectedAccount.id,
          franchise: decryptedData,
        };

        return serverUrl.patch(`/officers_management/relationship-officer/${selectedAccount.relationshipOfficerId}/`, roData, config);
      })
      .then((res) => {
        // Update emergency contact details
        const emergencyData = {
          name: selectedAccount.EmergencyFullName,
          phone_number: selectedAccount.EmergencyPhone,
          relationship: selectedAccount.EmergencyRelation,
          email: selectedAccount.EmergencyEmail,
          relationshipOfficer: selectedAccount.relationshipOfficerId, // Assuming this is correct
        };

        return serverUrl.patch(`/officers_management/emergency-contact/${selectedAccount.emergencyContactId}/`, emergencyData, config);
      })
      .then((res) => {
        toast.success('Successfully updated the officer and emergency contact details');
        setShowEditModal(false); // Close the officer modal

        // Fetch updated relationship officer data
        return serverUrl.get(`/officers_management/relationship-officer-summary/?franchise_id=${decryptedData}`, config);
      })
      .then((res) => {
        setRoData(res.data.results); // Update state with new data
      })
      .catch((error) => {
        console.error('Error occurred:', error);
        toast.error('An error occurred while updating the details');
      });
  };




  const toggleOrder = () => {
    setIsDescending(!isDescending);
    setRoData([...roData].reverse());
  };

  const flattenObject = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? `${prefix}.` : '';
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        Object.assign(acc, flattenObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchQuery(searchQuery);
    if (searchQuery === '') {
      setRoData(originalAccounts);
    } else {
      const filteredAccounts = originalAccounts.filter((roData) => {
        const flatTask = flattenObject(roData);
        return Object.values(flatTask).some(
          (value) =>
            typeof value === 'string' &&
            value.toLowerCase().includes(searchQuery)
        );
      });
      setRoData(filteredAccounts);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Maximum of 10 tasks per page

  // Calculate index of the first and last item of the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current items to display
  let currentItems = [];
  if (roData && roData.length > 0) {
    currentItems = roData.slice(indexOfFirstItem, indexOfLastItem);
  }


  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  // Calculate total number of pages
  const totalPages = roData ? Math.ceil(roData.length / itemsPerPage) : 0;

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedFilterType, setSelectedFilterType] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const years = Array.from({ length: 25 }, (_, i) => 2000 + i); // List of years from 2000 to 2024
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]; // List of month names




  const handleFilter = () => {

    // console.log("selectedTaskType:" , selectedTaskType.task_type)
    // Create a copy of the original tasks array to apply filters on
    let filteredTasks = [...originalAccounts];

    // Apply filters based on selected criteria

    // Filter by Task Status

    if (selectedName !== '') {
      filteredTasks = filteredTasks.filter(roData => roData.full_name === selectedName);
    }

    // Filter by Task Type
    if (selectedPhone !== '') {
      filteredTasks = filteredTasks.filter(roData => roData.phone_number === selectedPhone);
    }
    if (selectedGender !== '') {
      filteredTasks = filteredTasks.filter(roData => roData.gender === selectedGender);
    }
    if (selectedEmail !== '') {
      filteredTasks = filteredTasks.filter(roData => roData.email === selectedEmail);
    }


    // Filter by Date
    if (selectedFilterType === 'year' && selectedYear !== '') {
      filteredTasks = filteredTasks.filter(task => new Date(task.task_date).getFullYear() === parseInt(selectedYear));
    } else if (selectedFilterType === 'month' && selectedMonth !== '') {
      filteredTasks = filteredTasks.filter(task => new Date(task.task_date).getMonth() === parseInt(selectedMonth) - 1);
    } else if (selectedFilterType === 'specificDate' && selectedDate !== '') {
      filteredTasks = filteredTasks.filter(task => task.task_date === selectedDate);
    }

    // Set the filtered tasks as the new tasks to display
    setRoData(filteredTasks);
    setShowFilterModal(false);
  };




  const validateDob = (dob) => {
    const errors = {};
    const today = new Date();
    const selectedDate = new Date(dob);
    const age = today.getFullYear() - selectedDate.getFullYear();
    const month = today.getMonth() - selectedDate.getMonth();

    // Adjust age if the birthday hasn't been reached this year
    if (month < 0 || (month === 0 && today.getDate() < selectedDate.getDate())) {
      age--;
    }

    if (age < 18) {
      errors.officerDob = 'Officer must be at least 18 years old.';
    }

    setFormErrors(errors);
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const eighteenYearsAgo = new Date(today);
  eighteenYearsAgo.setFullYear(today.getFullYear() - 18);


  const getCurrentDate = () => {
    const currentDate = new Date();
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1); // Set to tomorrow
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
    const day = String(tomorrow.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  if (isLoading) {
    return (
      <div className='loading-screen'>
        <div className='logo'>

        </div>
      </div>
    )
  }


  // console.log(selectedAccount.phone_number)
  return (
    <>
      <SideBar />
      <Header />
      <Container fluid className="accounts">
        <Button
          className="btn btn-warning btn-sm justify-content-start d-block addoffice"
          onClick={() => setOfficerModal(true)}
        >
          <i className="bi bi-plus"></i>Add Officer
        </Button>
        <br></br>
        <div className="d-flex justify-content-between topFiltersData">
          <Form className="searchBar">

          </Form>
          <div className="d-flex justify-content-between filterTopsData">
            <Button className='lastappdate accountss'
              onClick={toggleOrder}
            >
              <i className="bi bi-arrow-up"></i>
              <i className="bi bi-arrow-down me-2"></i>
              <span>Last Appointment Date</span>
            </Button>
          </div>
        </div>


        {roData && roData.length === 0 ? (
          <p style={{ fontSize: '25px', marginTop: '10px' }}>
            No Field Officers available
          </p>
        ) : (

          <div className='taskDisplay'>
            <Form className="searchBar">
              <FormControl
                type="text"
                placeholder="Search Officer"
                onChange={handleSearch}
              />
            </Form>
            <Table>

              <thead>
                <tr className="table">
                  <th>Field Officer</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th>Debt Collection</th>
                  <th>Vetted Business</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((roDatum) => (
                  <tr key={roDatum.id}>
                    <td>{roDatum.full_name}</td>
                    <td>{roDatum.phone}</td>
                    <td>{roDatum.email}</td>
                    <td>{roDatum.debt_colected}</td>
                    <td>{roDatum.vetted_business}</td>

                    <td className="actions">
                      <Dropdown>
                        <Dropdown.Toggle variant="link" className="btn btn-sm text-dark p-0">
                          <i className="bi bi-three-dots-vertical"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleopenmodal(roDatum)}>
                            <Button style={{ width: "10vw" }} className='btn btn-sm btn-warning'> <i className="bi bi-eye me-2"></i> View</Button>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleEditmodal(roDatum)}>
                            <Button style={{ width: "10vw" }} className='btn btn-sm btn-info'><i className="bi bi-pen me-2"></i> Edit</Button>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeleteClick(roDatum)}>
                            <Button style={{ width: "10vw" }} className='btn btn-sm btn-danger'><i className="bi bi-trash me-2"></i> Delete</Button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {roData && roData.length > 0 && (
              <nav>
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage - 1)}>
                      {'<'}
                    </button>
                  </li>
                  <li className="page-item disabled">
                    <span className="page-link">
                      {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, roData.length)} of {roData.length}
                    </span>
                  </li>
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage + 1)}>
                      {'>'}
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </div>


        )}


        {/* Delete Confirmation Modal */}
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Account</Modal.Title>
          </Modal.Header>
          {/* Modal content */}
          <ModalBody>Are you sure you want to delete this data?</ModalBody>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Close
            </Button>
            <Button
              className="delete"
              variant="info"
              onClick={handledeleteSubmit}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Details Modal */}

        {selectedAccount && (
          <Modal
            show={showDetailsModal}
            onHide={() => setShowDetailsModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Officer Details</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: "scroll", height: "70vh" }}>
              <Form className="formTask">
                <h4>User Details</h4>
                <FormGroup>
                  <Form.Label>Full Name</Form.Label>
                  <input
                    className="form-control"
                    name="full_name"
                    value={selectedAccount.full_name || ''}
                    readOnly
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Phone Number</Form.Label>
                  <FormControl
                    name="phone_number"
                    value={selectedAccount.phone_number || ''}
                    readOnly
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Gender</Form.Label>
                  <FormControl
                    name="gender"
                    value={selectedAccount.gender || ''}
                    readOnly
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Email</Form.Label>
                  <FormControl
                    name="email"
                    value={selectedAccount.email || ''}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>Date Of Birth</Form.Label>
                  <FormControl
                    name="dob"
                    value={selectedAccount.dob || ''}
                    readOnly
                  />
                </FormGroup>

                <h5 className='mt-2'>Emergency Contact</h5>

                <FormGroup>
                  <Form.Label>Full Name</Form.Label>
                  <FormControl
                    name="status"
                    value={selectedAccount.emergency_name || ''}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>Relationship</Form.Label>
                  <FormControl
                    name="relationship"
                    value={selectedAccount.emergency_relationship || ''}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>Phone Number</Form.Label>
                  <FormControl
                    name="phone_number"
                    value={selectedAccount.EmergencyPhone || ''}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>Email</Form.Label>
                  <FormControl
                    name="email"
                    value={selectedAccount.emergency_email || ''}
                    readOnly
                  />
                </FormGroup>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDetailsModal(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}


        {/* Edit Modal */}

        {selectedAccount && (
          <Modal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Officer Details</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: "scroll", height: "70vh" }}>
              <Form onSubmit={handleEditSubmit} className="formTask">
                <FormGroup>
                  <Form.Label>Full Name</Form.Label>
                  <input
                    type="text"
                    className="form-control"
                    name="full_name"
                    value={selectedAccount.full_name || ''}
                    onChange={(e) =>
                      setSelectedAccount({
                        ...selectedAccount,
                        full_name: e.target.value,
                      })
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone_number"
                    value={selectedAccount.phone || ''}
                    maxLength={10}
                    onChange={(e) =>
                      setSelectedAccount({
                        ...selectedAccount,
                        phone: e.target.value,
                      })
                    }
                  ></Form.Control>
                </FormGroup>

                <FormGroup>
                  <Form.Label>Gender</Form.Label>
                  <FormControl
                    as='select'
                    name="gender"
                    value={selectedAccount.gender || ''}
                    onChange={(e) =>
                      setSelectedAccount({
                        ...selectedAccount,
                        gender: e.target.value,
                      })
                    }
                  >
                    <option value="#">Choose Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </FormControl>
                </FormGroup>

                <FormGroup>
                  <Form.Label>Email</Form.Label>
                  <FormControl
                    type="email"
                    name="email"
                    value={selectedAccount.email || ''}
                    onChange={(e) =>
                      setSelectedAccount({
                        ...selectedAccount,
                        email: e.target.value,
                      })
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Date of Birth</Form.Label>
                  <FormControl
                    type='date'
                    name="dob"
                    value={selectedAccount.dob || ''}
                    onChange={(e) =>
                      setSelectedAccount({
                        ...selectedAccount,
                        dob: e.target.value,
                      })
                    }
                    max={getCurrentDate()}

                  />
                </FormGroup>

                <h5 className='d-none'>Emergency Contact</h5>
                <FormGroup className='d-none'>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type='text'
                    name="emergency_name"
                    value={selectedAccount.emergency_name || ''}
                    onChange={(e) =>
                      setSelectedAccount({
                        ...selectedAccount,
                        emergency_name: e.target.value,
                      })
                    }
                  >
                  </Form.Control>
                </FormGroup>

                <FormGroup className='d-none'>
                  <Form.Label>Relationship</Form.Label>
                  <FormControl
                    as='select'
                    name="emergency_relationship"
                    value={selectedAccount.emergency_relationship || ''}
                    onChange={(e) =>
                      setSelectedAccount({
                        ...selectedAccount,
                        emergency_relationship: e.target.value,
                      })
                    }
                  >
                    <option value="#">Choose Relationship</option>
                    <option value="spouse">Spouse</option>
                    <option value="parent">Parent</option>
                    <option value="sibling">Sibling</option>
                    <option value="friend">Friend</option>
                    <option value="colleague">Colleague</option>
                  </FormControl>
                </FormGroup>

                <FormGroup className='d-none'>
                  <Form.Label>Phone Number</Form.Label>
                  <FormControl
                    type='text'
                    maxLength={10}
                    name="emergency_phone_number"
                    value={selectedAccount.emergency_phone_number || ''}
                    onChange={(e) =>
                      setSelectedAccount({
                        ...selectedAccount,
                        emergency_phone_number: e.target.value,
                      })
                    }
                  >
                  </FormControl>
                </FormGroup>

                <FormGroup className='d-none'>
                  <Form.Label>Email</Form.Label>
                  <FormControl
                    type='email'
                    name="emergency_email"
                    value={selectedAccount.emergency_email || ''}
                    onChange={(e) =>
                      setSelectedAccount({
                        ...selectedAccount,
                        emergency_email: e.target.value,
                      })
                    }
                  >
                  </FormControl>
                </FormGroup>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="dark"
                onClick={() => setShowEditModal(false)}
              >
                Cancel
              </Button>
              <Button variant="warning" type="submit" onClick={handleEditSubmit}>
                Edit
              </Button>
            </Modal.Footer>
          </Modal>
        )}


        {/* filter officer modalx  */}
        <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Filter Officer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleAddOfficer} className="formTask">
              <h5>User Details</h5>
              <FormGroup>
                <Form.Label>Full Name</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="officerName"
                  value={selectedName}
                  onChange={(e) => setSelectedName(e.target.value)}
                  placeholder='Enter Your Name'
                />
              </FormGroup>
              <FormGroup>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type='text'
                  maxLength={10}
                  name="officerPhone"
                  value={selectedPhone}
                  // onChange={handleOfficerChange}x
                  onChange={(e) => setSelectedPhone(e.target.value)}
                  placeholder='Enter Phone Number'
                />
              </FormGroup>
              <FormGroup>
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="officerGender"
                  value={selectedGender}
                  // onChange={handleOfficerChange}
                  onChange={(e) => setSelectedGender(e.target.value)}

                >
                  <option value="#">Choose Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Control>
              </FormGroup>
              <FormGroup>
                <Form.Label>Email</Form.Label>
                <FormControl
                  type="email"
                  name="officerEmail"
                  value={selectedEmail}
                  // onChange={handleOfficerChange}
                  onChange={(e) => setSelectedEmail(e.target.value)}
                  placeholder="Enter Your Email"
                />
              </FormGroup>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={() => setOfficerModal(false)}>
              Cancel
            </Button>
            <Button variant="warning" type="submit" onClick={handleFilter}>
              Filter
            </Button>
          </Modal.Footer>
        </Modal>


        {/* Adding Officer Modal */}
        <Modal
          show={showOfficerModal}
          onHide={() => setOfficerModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Officer</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "70vh", overflowY: "scroll" }}>
            <Form onSubmit={handleUserDetails} className="formTask">
              <h5>User Details</h5>
              <FormGroup>
                <Form.Label>Full Name</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="officerName"
                  value={officerData.officerName}
                  onChange={handleOfficerChange}
                  placeholder="Enter Your Name"
                />
                {formErrors.officerName && <span className="text-danger">{formErrors.officerName}</span>}
              </FormGroup>
              <FormGroup>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="officerPhone"
                  maxLength={10}
                  value={officerData.officerPhone}
                  onChange={handleOfficerChange}
                  placeholder="Enter Phone Number"
                />
                {formErrors.officerPhone && <span className="text-danger">{formErrors.officerPhone}</span>}
              </FormGroup>
              <FormGroup>
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="officerGender"
                  value={officerData.officerGender}
                  onChange={handleOfficerChange}
                >
                  <option value="">Choose Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Control>
                {formErrors.officerGender && <span className="text-danger">{formErrors.officerGender}</span>}
              </FormGroup>
              <FormGroup>
                <Form.Label>Email</Form.Label>
                <FormControl
                  type="email"
                  name="officerEmail"
                  value={officerData.officerEmail}
                  onChange={handleOfficerChange}
                  placeholder="Enter Your Email"
                />
                {formErrors.officerEmail && <span className="text-danger">{formErrors.officerEmail}</span>}
              </FormGroup>
              <FormGroup>
                <Form.Label>Date of Birth</Form.Label>
                <input
                  type="date"
                  className="form-control"
                  name="officerDob"
                  value={officerData.officerDob}
                  onChange={handleOfficerChange}
                  max={getCurrentDate()}

                />
                {formErrors.officerDob && <span className="text-danger">{formErrors.officerDob}</span>}
              </FormGroup>
              <h5 className='mt-3'>Emergency Contact (optional)</h5>
              <FormGroup>
                <Form.Label>Full Name</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="EmergencyFullName"
                  value={emergencyContactData.EmergencyFullName}
                  onChange={handleOfficerChange}
                  placeholder="Enter Your Full Name"
                />
                {formErrors.EmergencyFullName && <span className="text-danger">{formErrors.EmergencyFullName}</span>}
              </FormGroup>
              <FormGroup>
                <Form.Label>Relationship</Form.Label>
                <Form.Control
                  as="select"
                  name="EmergencyRelation"
                  value={emergencyContactData.EmergencyRelation}
                  onChange={handleOfficerChange}
                >
                  <option value="">Choose Relationship</option>
                  <option value="spouse">Spouse</option>
                  <option value="parent">Parent</option>
                  <option value="sibling">Sibling</option>
                  <option value="friend">Friend</option>
                  <option value="colleague">Colleague</option>
                </Form.Control>
                {formErrors.EmergencyRelation && <span className="text-danger">{formErrors.EmergencyRelation}</span>}
              </FormGroup>
              <FormGroup>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={10}
                  name="EmergencyPhone"
                  value={emergencyContactData.EmergencyPhone}
                  onChange={handleOfficerChange}
                  placeholder="Enter Phone Number"
                />
                {formErrors.EmergencyPhone && <span className="text-danger">{formErrors.EmergencyPhone}</span>}
              </FormGroup>
              <FormGroup>
                <Form.Label>Email</Form.Label>
                <FormControl
                  type="email"
                  name="EmergencyEmail"
                  value={emergencyContactData.EmergencyEmail}
                  onChange={handleOfficerChange}
                  placeholder="Enter Email"
                />
                {formErrors.EmergencyEmail && <span className="text-danger">{formErrors.EmergencyEmail}</span>}
              </FormGroup>
              {/* <button type="submit" className="btn btn-primary">Submit</button> */}
            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={() => setShowFilterModal(false)}>
              Cancel
            </Button>
            {loading ?

              <Button variant="warning"><Spinner /></Button>

              :
              <Button variant="warning" type='submit' onClick={handleUserDetails}>Add</Button>

            }
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default Accounts;
