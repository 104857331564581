import React, { useState } from 'react';
import '../assets/scss/WalletOtp.scss';
import { Container, Row, Col, FormControl, Button, Image, Spinner } from 'react-bootstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import OtpImage from '../assets/images/Mobile-login.jpg';
import { serverUrl } from '../serverUrl';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

function WalletOtp() {
  const [otp, setOtp] = useState(['', '', '', '']);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value === '' && index > 0) {
      document.getElementById(`otpInput-${index - 1}`).focus();
    } else if (index < 3 && value !== '') {
      document.getElementById(`otpInput-${index + 1}`).focus();
    }
  };

  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  const decodedToken = jwtDecode(access);
  const userId = decodedToken.user_id;

  const handleSubmit = () => {
    setIsLoading(true);
    const formattedOtp = otp.join('');


    serverUrl.get(`/officers_management/wallet-login-otp/?otp=${formattedOtp}`)
      .then((res) => {
        toast.success('Succesfully verified the otp')
        setIsLoading(false);
        navigate('/wallet')

      })
      .catch((error) => {
        console.log(error)
      })

  };

  const handleResendOtp = () => {
    setIsLoading(true)

    // post the otp data
    const data = {
      user: userId
    }
    serverUrl.post(`/officers_management/wallet-login-otp/`, data, config)
      .then((res) => {
        toast.success(`Succesfully sent an otp `)
        setIsLoading(false)

      })
      .catch((error) => {
        console.log(error)
      })


  };

  return (
    <div className='otpPage'>
      <Container fluid>
        <Row>
          <Col md={6} className='otpPageDisplay'>
            <h1 className='text-bold text-danger'>OTP</h1>
            <p>Please enter the OTP sent to your mobile number</p>
            <div className='otpDigits'>
              {otp.map((digit, index) => (
                <FormControl
                  key={index}
                  className='text-center'
                  id={`otpInput-${index}`}
                  type='text'
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  disabled={isLoading}
                />
              ))}
            </div>
            <span className='text-muted' style={{ fontSize: "14px" }}>Didn't receive an OTP?</span>
            <p className='text-dark text-decoration-underline' style={{ fontWeight: "500" }} onClick={handleResendOtp}>Resend OTP</p>

            {isLoading ?
              <Button className='btn btn-sm btn-danger text-white' style={{ fontSize: "15px", width: "45%" }}>
                <Spinner />
              </Button>
              :
              <Button onClick={handleSubmit} className='btn btn-sm btn-danger text-white' style={{ fontSize: "15px", width: "45%" }}>
                Confirm OTP
              </Button>
            }

          </Col>
          <Col md={6} className='otpPageImage'>
            <Image src={OtpImage} />
          </Col>
        </Row>

      </Container>
    </div>
  );
}

export default WalletOtp;
