import React, { useState, useMemo } from 'react';
import '../assets/scss/Login.scss';
import { Col, Container, Form, FormControl, Image, Row, Button, Spinner } from 'react-bootstrap';
import Loginimage from '../assets/images/login-image.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { serverUrl } from '../serverUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../assets/images/usalama_logo.png'


function WalletLogin() {

    const [showPassword, setShowPassword] = useState(false);
    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)
    let [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        phoneNumber: "",
        password: "",
    });
    const yellowHappyFace = '\u{1F600}';

    const togglePasswordVisibility = () => {
        // console.log("visibility")
        setShowPassword(!showPassword);
    };


    const handleFormInput = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleLogin = (e) => {
        e.preventDefault();

        const loginData = {
            phone_number: formData.phoneNumber,
            password: formData.password,
        }
        setLoading(true)

        serverUrl.post(`/user/login/`, loginData)
            .then((res) => {
                const data = res.data.data
                setAuthTokens(data.tokens)
                setUser(jwtDecode(data.tokens.access))
                const decodedToken = jwtDecode(data.tokens.access);
                const userId = decodedToken.user_id;
                localStorage.removeItem('number')
                localStorage.setItem('authTokens', JSON.stringify(data.tokens))
                const config = {
                    headers: { Authorization: `Bearer ${data.tokens.access}` },
                };
                // get the admin user
                serverUrl.get(`/officers_management/admins/?user=${userId}`, config)
                    .then((res) => {
                        // post the otp data
                        const data = {
                            user: userId
                        }
                        serverUrl.post(`/officers_management/wallet-login-otp/`, data, config)
                            .then((res) => {
                                toast.success(`Kindly enter the otp sent `)
                                navigate('/wallet-otp')
                                setLoading(false)
                            })
                            .catch((error) => {
                                console.log(error)
                            })

                    })
                    .catch((error) => {
                        console.log(error)
                    })


            })
            .catch((error) => {
                console.log(error);
                setLoading(false);

                if (!error.response || !error.response.status) {
                    toast.error("An error occurred. Please try again later.");
                } else if (error.response.status === 401) {
                    toast.error("Sorry, the entered credentials are not correct.");
                }
            });

    };


    return (
        <div className='signUpPage'>
            <Container fluid>
                <Row>
                    <Col md={6} className='sideDisplay'>
                        {/* <Image src={#} style={{ width: "35%" }} /> */}
                        <p>

                            "Welcome to our tracking system wallet. Please log in to access your petty cash account, finances management, expense logging, finance planning, safety features, and administrative tools for enhanced supervision and efficiency."
                        </p>
                        {/* <Image  src={Loginimage} /> */}
                    </Col>

                    <Col md={6} className='signUp'>
                        <div className='form-header'>
                            <Image src={Logo} style={{ width: "20%" }} />

                            <h3>

                                {/* <i class="bi bi-geo-alt text-warning"></i> */}

                                Usalama Field Wallet
                            </h3>
                            <p>Login to access your wallet account</p>
                        </div>



                        <Form className='FormSignUp' >
                            <label>Phone Number</label>
                            <FormControl
                                type='text'
                                name='phoneNumber'
                                placeholder='Enter Phone Number'
                                value={formData.phoneNumber}
                                onChange={handleFormInput}
                                required
                            />


                            <label>Password</label>
                            <div className="password-field d-flex">
                                <FormControl
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter Password'
                                    name='password'
                                    value={formData.password}
                                    onChange={handleFormInput}
                                    required
                                />
                                <span className='visibility' style={{ cursor: "pointer" }} onClick={togglePasswordVisibility}>
                                    {showPassword ? <i className="bi bi-eye-slash-fill"></i> : <i className="bi bi-eye-fill"></i>}
                                </span>
                            </div>

                            <Link className='text-dark mb-3'>
                                <span className='text-dark fw-bold float-end d-flex forgot' style={{ position: "relative", right: "100px" }}>Forgot password</span>

                            </Link>
                            <br></br>

                            {loading ?

                                <Button className='btn btn-sm bg-danger btn-danger text-white text-upperase w-75 ms-2' ><Spinner /></Button>

                                :
                                <Button className='btn btn-sm bg-danger btn-danger text-white text-upperase w-75 ms-2' type='submit' onClick={handleLogin}>Login</Button>

                            }

                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default WalletLogin;
