import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { serverUrl } from '../serverUrl';
import { jwtDecode } from 'jwt-decode';
import CryptoJS from 'crypto-js';

export const useBuyAirtime = () => {
    const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
    const decryptData = (cipherText, secretKey) => {
        const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };

    const accessToken = localStorage.getItem('authTokens');
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };

    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;
    const [franchiseSelected, setFranchise] = useState('');

    useEffect(() => {
        const selectedFranchise = localStorage.getItem('selectedFranchise');
        if (selectedFranchise) {
            const decryptedData = decryptData(selectedFranchise, secretKey);
            setFranchise(decryptedData);
        }
    });

    const [isLoading, setIsloading] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('');

    const handleShowModal = (type) => {
        setModalType(type);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);



    const [walletAirtimeBalance, setWalletAirtimeBalance] = useState({});
    const [walletFound, setWalletFound] = useState([]);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [currentWallet, setCurrentWallet] = useState('');
    const [subscription, setSubscription] = useState('');
    const [fieldofficerCount, setFieldOfficerCount] = useState('');
    const [fieldofficer, setFieldOfficer] = useState([]);
    const [airtimeTransactions, setAirtimeTransactions] = useState([]);
    const [airtimeTransactionsCount, setAirtimeTransactionsCount] = useState('');
    const [adminId, setAdminId] = useState('');
    const [adminPhone, setAdminPhone] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setIsloading(true);
            try {
                const admin = await serverUrl.get(`/officers_management/admins/?user=${userId}`, config);
                setAdminId(admin.data.results[0].id)
                setAdminPhone(admin.data.results[0].user.phone_number)
                const walletresponse = await serverUrl.get(`/officers_management/wallet/?account_type=airtime&franchise=${franchiseSelected}`, config);
                // the airtime transactions
                const airtimeResponse = await serverUrl.get(`/officers_management/airtime-transactions/?wallet=${walletresponse.data.results[0].id}`, config);
                setAirtimeTransactions(airtimeResponse.data.results)
                setAirtimeTransactionsCount(airtimeResponse.data.count)
                const walletresponseSubscription = await serverUrl.get(`/officers_management/wallet/?account_type=subscription&franchise=${franchiseSelected}`, config);
                setSubscription(walletresponseSubscription.data.results[0].id);
                const response = await serverUrl.get(`/officers_management/wallet-account-balance?wallet=${walletresponse.data.results[0].id}`, config);
                setWalletAirtimeBalance(response.data.results[0].wallet_balance);
                setCurrentWallet(response.data.results[0].wallet);
                const subscriptionStatusResponse = await serverUrl.get(`/officers_management/subscription-summary?franchise_id=${franchiseSelected}`, config);
                setSubscriptionStatus(subscriptionStatusResponse.data.results[0].summary[0].subscription_status);
                const wallestDisplay = await serverUrl.get(`officers_management/wallet/?franchise=${franchiseSelected}`);
                const filterWalletLatest = wallestDisplay.data.results.filter((wallet) => wallet.id !== walletresponse.data.results[0].id);

                const availablewallets = await Promise.all(filterWalletLatest.map(async (wallet) => {
                    try {
                        const res = await serverUrl.get(`/officers_management/wallet-account-balance?wallet=${wallet.id}`, config);
                        return {
                            ...wallet,
                            balance: res.data.results[0]?.wallet_balance || 0
                        };
                    } catch (error) {
                        console.error(`Error fetching balance for wallet ${wallet.id}:`, error);
                        return {
                            ...wallet,
                            balance: 0
                        };
                    }
                }));
                setWalletFound(availablewallets);

                const relationhipResponse = await serverUrl.get(`/officers_management/relationship-officer-summary/?franchise_id=${franchiseSelected}`, config);
                setFieldOfficerCount(relationhipResponse.data.length);
                setFieldOfficer(relationhipResponse.data);

                setIsloading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsloading(false);
            }
        };
        fetchData();
    }, [franchiseSelected]);

    if (isLoading) {
        return (
            <div className='loading-screen'>
                <div className='logo'></div>
            </div>
        );
    }

    return {
        walletAirtimeBalance, walletFound, subscription,
        subscriptionStatus, adminPhone,
        handleCloseModal,
        showModal,
        handleShowModal,
        modalType,
        fieldofficerCount,
        fieldofficer, adminId,airtimeTransactionsCount,
        currentWallet, franchiseSelected, config,airtimeTransactions
    };
};
