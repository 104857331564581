import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Polygon, Polyline, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const FitBounds = ({ bounds }) => {
  const map = useMap();
  useEffect(() => {
    if (bounds.length) {
      const leafletBounds = L.latLngBounds(bounds);
      map.fitBounds(leafletBounds);
    }
  }, [bounds, map]);
  return null;
};

const doLinesIntersect = (p1, p2, q1, q2) => {
  const ccw = (a, b, c) => {
    return (c[1] - a[1]) * (b[0] - a[0]) > (b[1] - a[1]) * (c[0] - a[0]);
  };
  return ccw(p1, q1, q2) !== ccw(p2, q1, q2) && ccw(p1, p2, q1) !== ccw(p1, p2, q2);
};

const arePathsIntersecting = (path) => {
  for (let i = 0; i < path.length - 1; i++) {
    for (let j = i + 2; j < path.length - 1; j++) {
      if (doLinesIntersect(path[i], path[i + 1], path[j], path[j + 1])) {
        return true;
      }
    }
  }
  return false;
};

const MapKenyaComponent = ({ selectedSubcounty }) => {
  const [directions, setDirections] = useState(null);

  useEffect(() => {
    if (selectedSubcounty) {
      const coordinates = selectedSubcounty.map(subcounty => [subcounty.latitude, subcounty.longitude]);
      console.log('Coordinates:', coordinates);

      if (coordinates.length > 1) {
        const origin = coordinates[0];
        const destination = coordinates[coordinates.length - 1];
        const waypoints = coordinates.slice(1, -1);

        console.log('Origin:', origin);
        console.log('Destination:', destination);
        console.log('Waypoints:', waypoints);

        const simulatedDirections = [origin, ...waypoints, destination];

        if (!arePathsIntersecting(simulatedDirections)) {
          setDirections(simulatedDirections);
        } else {
          console.log('Paths intersect, not displaying the path.');
        }
      }
    }
  }, [selectedSubcounty]);

  const bounds = selectedSubcounty ? selectedSubcounty.map(subcounty => [subcounty.latitude, subcounty.longitude]) : [];

  return (
    <MapContainer center={[1.2921, 36.8219]} zoom={6} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {selectedSubcounty && (
        <Polygon
          positions={selectedSubcounty.map(subcounty => [subcounty.latitude, subcounty.longitude])}
        />
      )}
      {directions && (
        <Polyline
          positions={directions}
          color="blue"
        />
      )}
      <FitBounds bounds={bounds} />
    </MapContainer>
  );
};

export default MapKenyaComponent;
