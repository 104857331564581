    import React, { useState, useEffect } from 'react'
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import { serverUrl } from '../serverUrl';
import '../assets/scss/Invoices.scss';
import { Button, Nav, Table, Col, Row, Modal, Card, FormSelect, Form } from 'react-bootstrap';
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

function Invoices() {
    const [isLoading,setIsloading] = useState(false)
    // get the user who is logged in and the date related to them
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;
    // get the invoices of that wallet
    const [invoices, setInvoices] = useState([])
    const [paidInvoices, setPaidInvoices] = useState([])
    const [activeTab, setActiveTab] = useState('invoices');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }
    const [subscriptionPaymentModal, setSubscriptionPaymentModal] =
        useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedMpesaOption, setSelectedMpesaOption] = useState(null);
    const [invoiceData, setInvoiceData] = useState({});
    const [modalTitle, setModalTitle] = useState([]);
    const [type, setType] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [walletSummary, setWalletSummary] = useState({});
    const [franchiseData, setfranchiseData] = useState([]);
    const [franchiseWallets, setFranchiseWallets] = useState([])
    const [selectedWalletBalance, setSelectedWalletBalance] = useState(null);
    const [selectedCurrentWalletBalance, setSelectedCurrentWalletBalance] = useState(null);
    const [enteredAmount, setEnteredAmount] = useState("");
    const [phoneNumberPayment, setPhoneNumberPayment] = useState("");

    const [userLogedIn, setUserLogedIn] = useState([]);

    const handleAmountChange = (amount) => {
        setEnteredAmount(amount);
    };

    const handleSubscriptionPaymentClose = () => {
        setSubscriptionPaymentModal(false);
        setSelectedPaymentMethod(null);
        setSelectedMpesaOption(null);
    };
    const handleButtonClick = (title, type) => {
        setModalTitle(title);
        setType(type)
        setShowModal(true);
    }
    const [franchiseFound, setFranchiseFound] = useState('')
    const [amountPhone, setAmountPhone] = useState("");

    const handleCloseModal = () => {
        setShowModal(false);
    }
    const handleAmountPayment = (e) => {
        setAmountPhone(e.target.value);
      };
      const handlePhoneNumberChange = (e) => {
        setPhoneNumberPayment(e.target.value);
      };
      const mpesSubscriptionBalance = parseInt(walletSummary.total_due) - parseInt(amountPhone)

    useEffect(() => {
        setIsloading(true)

        const fetchData = async () => {
            try {
                const admin = await serverUrl.get(`/officers_management/admins/?user=${userId}`, config);
                const response = await serverUrl.get(`/officers_management/subscription-summary?franchise_id=${admin.data.results[0].franchise}`, config);
                setFranchiseFound(admin.data.results[0].franchise)
                setInvoices(response.data.results[0].invoices);
                setPaidInvoices(response.data.results[0].receipts);
                setWalletSummary(response.data.results[0].summary[0]);
                const responseFranchise = await serverUrl.get(`/officers_management/franchise/${admin.data.results[0].franchise}`, config);
                setfranchiseData(responseFranchise.data)
            


                const franchiseWallet = await serverUrl.get(`/officers_management/wallet/?franchise=${admin.data.results[0].franchise}&collection_type=franchise`, config);
                

                const availablewallets = await Promise.all(franchiseWallet.data.results.map(async (wallet) => {
                    try {
                        const res = await serverUrl.get(`/officers_management/wallet-account-balance?wallet=${wallet.id}`, config);
                        return {
                            ...wallet,
                            balance: res.data.results[0]?.wallet_balance || 0
                        };
                    } catch (error) {
                        console.error(`Error fetching balance for wallet ${wallet.id}:`, error);
                        setIsloading(false)
                        return {
                            ...wallet,
                            balance: 0
                        };
                    }
                }));

                setFranchiseWallets(availablewallets);
                


                // get the franchise details 
                const responseUser = await serverUrl.get(`/user/edit-user/${userId}`, config);
                setUserLogedIn(responseUser.data)
                setIsloading(false)

            } catch (error) {
                console.error('Error fetching tasks data:', error);
                setIsloading(false)

            }
        };

        fetchData(); // Call the async function
    }, []);


    const handlePaySubscription = () => {
        if (selectedPaymentMethod === "wallet") {
          const data = {
            subscription_wallet: parseInt(selectedWallet),
            wallet: invoiceData.wallet,
            wallet_balance: parseInt(selectedWalletBalance) - parseInt(enteredAmount),
            wallet_name: selectedWalletName.name,
            invoice: parseInt(invoiceData.id),
            amount_paid: parseInt(enteredAmount),
            franchise: franchiseFound
          };
    
          const formData = new FormData();
          // Append each key-value pair to the FormData object
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              formData.append(key, String(data[key]));
            }
          }
          // post the data for the payment
          serverUrl
            .post(`/officers_management/pay-subscription-from-wallet/`, formData, config)
            .then((res) => {
              // show paid and close the modal
              toast.success("Succesfuly made payment for the subscription");
              setEnteredAmount(null);
              setSelectedWallet(null);
              setSelectedWalletName(null);
              setSelectedPaymentMethod(null);
              setSubscriptionPaymentModal(false);
              const response = serverUrl.get(`/officers_management/subscription-summary?franchise_id=${franchiseFound}`, config);
              setWalletSummary(response.data.results[0].summary[0]);
              setInvoices(response.data.results[0].invoices);
              setPaidInvoices(response.data.results[0].receipts);
            })
            .catch((error) => {
              console.log(error);
            });
        }
    
        else {
          let modifiedPhoneNumber = "";
    
          if (selectedMpesaOption === "my_number") {
            modifiedPhoneNumber = userLogedIn.phone_number;
    
            if (userLogedIn.phone_number.length === 10 && userLogedIn.phone_number.startsWith("0")) {
              modifiedPhoneNumber = "254" + userLogedIn.phone_number.slice(1);
            }
          } else if (selectedMpesaOption === "other_number") {
            modifiedPhoneNumber = phoneNumberPayment;
    
            if (phoneNumberPayment.length === 10 && phoneNumberPayment.startsWith("0")) {
              modifiedPhoneNumber = "254" + phoneNumberPayment.slice(1);
            }
          }
    
          const current_balance = parseInt(invoiceData.amount) - amountPhone
          const formDataDepositWallet = new FormData();
          formDataDepositWallet.append('franchise_id', franchiseFound);
          formDataDepositWallet.append('wallet_id', invoiceData.wallet);
          formDataDepositWallet.append('phone_number', modifiedPhoneNumber);
          formDataDepositWallet.append('current_balance', current_balance);
          formDataDepositWallet.append('amount', amountPhone);
    
          // post the data
          serverUrl.post(`/officers_management/deposit-wallet/`, formDataDepositWallet, config)
            .then((res) => {
              toast.success('Request is succesful kindly wait for you to input your pin')
    
              setEnteredAmount(null);
              setSelectedWallet(null);
              setSelectedWalletName(null);
              setSelectedPaymentMethod(null);
              setSubscriptionPaymentModal(false);
              const response = serverUrl.get(`/officers_management/subscription-summary?franchise_id=${franchiseFound}`, config);
              setWalletSummary(response.data.results[0].summary[0]);
              setInvoices(response.data.results[0].invoices);
              setPaidInvoices(response.data.results[0].receipts);
            })
            .catch((error) => {
              console.log(error)
            })
    
    
        }
      };

      
    const handleOpenSubscriptionInvoice = (invoiceId) => {
        setSubscriptionPaymentModal(true);

        // get the details of the selected invoice
        serverUrl
            .get(`/officers_management/subscription-invoice/${invoiceId}/`, config)
            .then((res) => {
                setInvoiceData(res.data);

            })
            .catch((error) => {
                console.log(error);
            });

    };
    const handleMpesaOption = (method) => {
        setSelectedMpesaOption(method);
    };
    const [selectedWalletName, setSelectedWalletName] = useState([]);

    const [selectedWallet, setSelectedWallet] = useState(null);

    const handlePaymentMethod = (method) => {
        setSelectedPaymentMethod(method);
        setSelectedWalletName([]);
        handleMpesaOption(null);
    };
    function handleWalletSelect(selectedWalletId) {
        setSelectedWallet(selectedWalletId);
        serverUrl
            .get(
                `/officers_management/wallet-account-balance?wallet=${selectedWalletId}`,
                config
            )
            .then((res) => {
                setSelectedWalletBalance(res.data.results[0].wallet_balance);
                const balancePayment =
                    res.data.results[0].wallet_balance - parseInt(invoiceData.amount);
                setSelectedCurrentWalletBalance(balancePayment);

                const balance = parseInt(invoiceData.amount) - balancePayment;
            })
            .catch((error) => {
                console.log(error);
            });

        // get the details of the selected wallet
        serverUrl
            .get(`/officers_management/wallet/${selectedWalletId}/`, config)
            .then((res) => {
                setSelectedWalletName(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    if (isLoading) {
        return (
          <div className='loading-screen'>
            <div className='logo'>
    
            </div>
          </div>
        )
      }

    return (

       
        <>

            <Header />
            <SideBar />

            <div className='invoices'>
                <div className='d-flex justify-content-between me-4 ms-2'>
                    <p>Your subscription status is {walletSummary.subscription_status === 'partial' ? <span className="text-warning">Partial</span> : walletSummary.subscription_status === 'paid' ? <span className="text-success">Paid</span> : <span className="text-danger">Unpaid</span>}</p>
                    {walletSummary.subscription_status !== 'paid' && (
                        <p>Total unpaid balance is <b>Ksh.{walletSummary.total_due?.toLocaleString('en-US')}</b> </p>
                    )}
                </div>

                <Nav variant="tabs" defaultActiveKey="invoices" className="mb-4 mt-4">
                    <Nav.Item>
                        <Nav.Link eventKey="invoices" onClick={() => handleTabChange('invoices')}>Invoices</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="paidInvoices" onClick={() => handleTabChange('paidInvoices')}>Paid Invoices</Nav.Link>
                    </Nav.Item>
                </Nav>

                {activeTab === 'invoices' && (
                    <div className="tasks invoices">
                        <h5 className="float-start">My list of invoice</h5>
                        <div className='taskDisplay'> 
                        <Table responsive>
                            <thead className="underline">
                                <tr className='table'>
                                    <th>Invoice Date</th>
                                    <th>Invoice Month</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map((invoice) => (
                                    <tr>
                                        <td>
                                            {new Date(invoice.invoice_date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: '2-digit'
                                            })}
                                        </td>
                                        <td style={{ textTransform: "capitalize" }}>{invoice.month}</td>
                                        <td>Ksh. {invoice.amount.toLocaleString()}</td>
                                        {invoice.status === "unpaid" ?
                                            <td className='text-danger' style={{ textTransform: "capitalize" }}>{invoice.status}</td>
                                            : invoice.status === "partial" ?
                                                <td className='text-warning' style={{ textTransform: "capitalize" }}>{invoice.status}</td>
                                                :
                                                <td className='text-success' style={{ textTransform: "capitalize" }}>{invoice.status}</td>

                                        }
                                        <td className='d-flex '>
                                            {invoice.status === "paid" ?
                                                <Button
                                                    className={`btn btn-sm btn-white bg-white border-0 text-white bg-transparent ms-4 me-4 `}

                                                >
                                                    Pay Invoice
                                                </Button>
                                                :
                                                <Button
                                                    className={`btn btn-sm btn-info text-white bg-info ms-4 me-4`}
                                                    onClick={() => handleOpenSubscriptionInvoice(invoice.id)}
                                                >
                                                    Pay Invoice
                                                </Button>
                                            }

                                            <Button className='btn btn-sm btn-success text-white bg-success me-2' onClick={() => handleButtonClick(invoice, 'Invoice')}><i className='bi bi-eye'></i> Invoice</Button>
                                            <Button className='btn btn-sm btn-warning text-white bg-warning' onClick={() => handleButtonClick('Download Invoice')}><i className='bi bi-download'></i> Invoice</Button>

                                        </td>


                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                        </div>
                    </div>
                )}

                {activeTab === 'paidInvoices' && (
                    <div className="tasks">
                        <h5 className="float-start">My list of paid invoices</h5>
                        <div className='taskDisplay'>
                        <Table responsive>
                            <thead className="underline">
                                <tr>
                                    <th>Date Paid</th>
                                    <th>Invoice Month</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paidInvoices.map((invoice) => (
                                    <tr>
                                        <td>
                                            {new Date(invoice.paid_date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: '2-digit'
                                            })}
                                        </td>
                                        <td style={{ textTransform: "capitalize" }}>{invoice.month}</td>
                                        <td style={{ textTransform: "capitalize" }}>{invoice.amount}</td>
                                        <td>Ksh. {invoice.amount.toLocaleString()}</td>
                                        <td className='text-success' style={{ textTransform: "capitalize" }}>Paid</td>

                                        <td className='d-flex '>
                                            <Button className='btn btn-sm btn-success text-white bg-success me-2' onClick={() => handleButtonClick(invoice, 'Receipts')}><i className='bi bi-eye'></i> Receipt</Button>
                                            <Button className='btn btn-sm btn-warning text-white bg-warning' onClick={() => handleButtonClick('Download Invoice')}><i className='bi bi-download'></i> Receipt</Button>
                                        </td>
                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                        </div>
                       
                    </div>
                )}


                {/* modal payment process */}
                <Modal
                    show={subscriptionPaymentModal}
                    onHide={handleSubscriptionPaymentClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title
                            style={{ fontSize: "1.5rem", color: "#0dcaf0" }}
                        >
                            Choose payment method
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Your subscription balance is <b>Ksh. {walletSummary.total_due?.toLocaleString('en-US')}</b></p>
                        {franchiseWallets.length !== 0 ? (
                            <div className="d-flex justify-content-between mb-3">
                                <Button
                                    className="border-0"
                                    style={{ background: "#343a4080" }}
                                    onClick={() => handlePaymentMethod("wallet")}
                                >
                                    Wallet
                                </Button>
                                <Button
                                    className="border-0"
                                    style={{ background: "rgb(255 215 26)" }}
                                    onClick={() => handlePaymentMethod("mpesa")}
                                >
                                    {" "}
                                    Mpesa
                                </Button>
                            </div>
                        ) : (
                            <div className="d-flex justify-content-between">
                                <Button
                                    className="btn btn-sm  text-white border-0"
                                    style={{ background: "#9b59b6" }}
                                    onClick={() => handleMpesaOption("my_number")}
                                >
                                    Pay with my number
                                </Button>
                                <Button
                                    className="btn btn-sm text-white border-0"
                                    style={{ background: "#34495e" }}
                                    onClick={() => handleMpesaOption("other_number")}
                                >
                                    Pay with another number
                                </Button>
                            </div>
                        )}

                        {selectedPaymentMethod !== null ? (
                            selectedPaymentMethod === "wallet" ? (
                                <>
                                    <FormSelect
                                        className="mt-2"
                                        value={selectedWallet}
                                        onChange={(e) => handleWalletSelect(e.target.value)}
                                    >
                                        <option selected disabled value="">
                                            Select wallet to pay from
                                        </option>
                                        {franchiseWallets.map((wallet) => (
                                            <option key={wallet.id} value={wallet.id}>
                                                {wallet.name}
                                            </option>
                                        ))}
                                    </FormSelect>
                                    {selectedWalletName.length === 0 ? (
                                        <></>
                                    ) : (
                                        <>
                                            {selectedWalletBalance === 0 ? (
                                                <p className="mt-3 fw-bold" style={{ fontSize: "0.9rem", color: "purple" }}>
                                                    Sorry, {selectedWalletName.name} does not have
                                                    any money in it to make payment
                                                </p>
                                            ) : (
                                                <p className="mt-3 fw-bold" style={{ fontSize: "0.9rem", color: "purple" }}>
                                                    {selectedWalletName.name} has a balance of
                                                    Ksh. {selectedWalletBalance?.toLocaleString()}
                                                </p>
                                            )}
                                        </>
                                    )}
                                    {selectedWalletBalance === 0 ? (
                                        <></>
                                    ) : (
                                        <>
                                            <input
                                                type="number"
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 mt-2"
                                                placeholder="Enter amount to pay"
                                                value={enteredAmount}
                                                onChange={(e) =>
                                                    handleAmountChange(e.target.value)
                                                }
                                            />

                                            {enteredAmount ? (
                                                parseInt(enteredAmount) === 0 ?
                                                    <p className="mt-2 text-danger fw-bold">
                                                        You cannot make a payment of Ksh. 0.
                                                    </p>
                                                    :
                                                    <>
                                                        {selectedWalletBalance < enteredAmount ? (
                                                            <p className="mt-2 text-danger fw-bold">
                                                                Sorry, you have insufficient funds in your account.
                                                            </p>
                                                        ) : (
                                                            <p className="mt-3 fw-bold" style={{ fontSize: "0.9rem", color: "goldenrod" }}>
                                                                After this transaction, your balance in {selectedWalletName.name} will be Ksh. {(selectedWalletBalance - enteredAmount)?.toLocaleString()}.
                                                            </p>
                                                        )}
                                                    </>
                                            ) : (
                                                <></> // No message displayed when amount is not entered
                                            )}



                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="d-flex justify-content-between">
                                    <Button
                                        className="btn btn-sm text-white border-0"
                                        style={{ background: "#9b59b6" }}
                                        onClick={() => handleMpesaOption("my_number")}
                                    >
                                        Pay with my number
                                    </Button>
                                    <Button
                                        className="btn btn-sm  text-white border-0"
                                        style={{ background: "#34495e" }}
                                        onClick={() => handleMpesaOption("other_number")}
                                    >
                                        Pay with another number
                                    </Button>
                                </div>
                            )
                        ) : (
                            <></>
                        )}

                        <Form>
                            {selectedMpesaOption !== null ? (
                                <>
                                    {selectedMpesaOption === "my_number" ? (
                                        <div className="d-block">
                                            <input
                                                value={userLogedIn.phone_number}
                                                type="number"
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 mt-2"
                                                placeholder="Enter amount to pay"
                                            />
                                            <input
                                                type="number"
                                                value={amountPhone}
                                                name="amount_phone"
                                                onChange={handleAmountPayment}
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 mt-2"
                                                placeholder="Enter amount to pay"
                                            />
                                        </div>
                                    ) : (
                                        <div className="d-block">
                                            <input
                                                type="number"
                                                value={phoneNumberPayment}
                                                name="phone_number"
                                                onChange={handlePhoneNumberChange}
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 mt-2"
                                                placeholder="Enter phone number"
                                            />
                                            <input
                                                type="number"
                                                value={amountPhone}
                                                name="amount_phone"
                                                onChange={handleAmountPayment}
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 mt-2"
                                                placeholder="Enter amount to pay"
                                            />
                                        </div>
                                    )}
                                    {amountPhone && (
                                        <p className="mt-2 text-warning">The subscription balance will be {mpesSubscriptionBalance}</p>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-info text-white fw-bold opacity-75"
                            onClick={handlePaySubscription}
                        >
                            Pay
                        </Button>
                    </Modal.Footer>
                </Modal>


                {/* Modal */}
                <Modal show={showModal} onHide={handleCloseModal} size='xl'>
                    <Modal.Header closeButton>
                        {type === 'Receipts' ?
                            <Modal.Title>RECEIPT</Modal.Title>

                            :
                            <Modal.Title>INVOICE</Modal.Title>

                        }
                    </Modal.Header>
                    <Modal.Body>
                        {modalTitle ? (
                            <Card style={{ overflow: "hidden", border: "none" }}>
                                <div className="blue-ribbon"></div>
                                <h3 className='px-4 mt-3 logoDisplay'>USALAMA</h3>
                                <Row className='px-4'>
                                    <Col md={8}>
                                        <h5 className='fw-bold mb-2'>RECEIPIENT</h5>
                                        <div className='d-grid'>
                                            <span>{franchiseData.name}</span>
                                            <span>{franchiseData.phone_number} </span>
                                            <span>{franchiseData.email}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} className='mt-3'>
                                        {type === 'Receipts' ?
                                            <h5 className='fw-bold mb-2'>RECEIPT</h5>

                                            :
                                            <h5 className='fw-bold mb-2'>INVOICE</h5>

                                        }
                                        <div className='d-grid'>
                                            <span>{modalTitle.id}/{modalTitle.invoice_date ? modalTitle.invoice_date.slice(0, 10) : ''}</span>
                                            {modalTitle.status && (
                                                <span className={`text-${modalTitle.status === "partial" ? "warning" : modalTitle.status === "paid" ? "success" : "danger"} fw-bold`} style={{ textTransform: "capitalize" }}>{modalTitle.status}</span>
                                            )}
                                            <span>Ksh. {modalTitle.amount_due && modalTitle.amount_due > 0 ? modalTitle.amount_due.toLocaleString() : modalTitle.amount_due}</span>
                                        </div>
                                    </Col>
                                </Row>
                                {type === 'Receipts' ?
                                    <></>
                                    :
                                    <h3 className='align-self-center fw-bold mt-4 mb-4'>Total due : <b style={{ textTransform: "capitalize" }}>{modalTitle.amount_due}</b></h3>
                                }
                                <Row className=''>
                                    <Col md={3}>
                                        <Table responsive>
                                            <thead>
                                                <tr className='table'>
                                                    <th className='topHeader'>Invoice Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='fw-bold'>{modalTitle.invoice_date ? modalTitle.invoice_date.slice(0, 10) : ''}</td>
                                                </tr>
                                                {type === 'Receipts' ?
                                                    <></>
                                                    :
                                                    <tr>
                                                        <td className='fw-bold'>
                                                            Due Date<br />
                                                            <b className='text-danger fw-bold'>
                                                                {modalTitle.invoice_date && (() => {
                                                                    // Parse the existing date string into a Date object
                                                                    const currentDate = new Date(modalTitle.invoice_date);

                                                                    // Add 7 days to the current date
                                                                    const nextWeekDate = new Date(currentDate);
                                                                    nextWeekDate.setDate(nextWeekDate.getDate() + 7);

                                                                    // Format the next week date to display in the desired format
                                                                    const formattedNextWeekDate = nextWeekDate.toISOString().slice(0, 10);

                                                                    return formattedNextWeekDate;
                                                                })()}

                                                            </b>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>

                                    <Col md={9}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className='topHeader'>Subscription Plan</th>
                                                    <th className='topHeader'>Balance</th>
                                                    <th className='topHeader'>Total paid</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textTransform: "capitalize" }}>Monthly</td>
                                                    <td>Ksh. {modalTitle.amount_due && modalTitle.amount_due > 0 ? modalTitle.amount_due.toLocaleString() : modalTitle.amount_due}</td>
                                                    <td>Ksh. {modalTitle.amount}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        {type === 'Invoice' ?
                                            <div className='d-flex justify-content-between align-items-center mt-4'>
                                                <div>
                                                    <h5 className='fw-bold'>Paying for your invoice</h5>
                                                    <ul className='w-75 d-grid' style={{ fontSize: "0.9rem" }}>
                                                        <li style={{ listStyleType: "auto" }}>Make sure you have sufficient funds in your wallet or M-Pesa account.</li>
                                                        <li style={{ listStyleType: "auto" }}>Click on the "Pay Invoice" button.</li>
                                                        <li style={{ listStyleType: "auto" }}>If paying with your wallet, enter the amount and await your receipt.</li>
                                                        <li style={{ listStyleType: "auto" }}>If paying via M-Pesa, verify your phone number details, enter the amount, and await the M-Pesa prompt. Then, enter your PIN.</li>
                                                        <li style={{ listStyleType: "auto" }}>After a successful payment, await your receipt.</li>
                                                    </ul>
                                                </div>
                                                {/* <div className='me-2' style={{ width: "25%" }}>
                          <Button className='border-0' style={{ backgroundColor: '#1F57F8' }}>
                            Pay Invoice
                          </Button>
                        </div> */}
                                            </div>
                                            :
                                            <div className='d-flex justify-content-between align-items-center mt-4'>
                                                <div>
                                                    <h5 className='fw-bold'></h5>
                                                    <span className='w-75 d-flex' style={{ fontSize: "0.9rem", color: "transparent" }} >
                                                    </span>
                                                </div>
                                                <div className='me-2' style={{ width: "45%" }}>
                                                    <p className='d-flex justify-content-between align-items-center'>
                                                        <h6 className='fw-bold'>SUBTOTAL</h6> <span>Ksh {modalTitle.amount?.toLocaleString('en-US')}</span>
                                                    </p>
                                                    <p className='d-flex justify-content-between align-items-center'>
                                                        <h6 className='fw-bold'>DISCOUNT</h6> <span>Ksh 0</span>
                                                    </p>
                                                    <p className='d-flex justify-content-between align-items-center totalButton'>
                                                        <h6 className='fw-bold'>TOTAL</h6> <span>Ksh   {modalTitle.amount?.toLocaleString('en-US')}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </Card>
                        ) : (
                            <></>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="dark" onClick={handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Invoices