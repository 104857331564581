import React, { useState, useEffect } from 'react';
import { Container, Form, FormControl, Button, Table, Modal, FormGroup, Dropdown } from 'react-bootstrap';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import '../assets/scss/Feedback.scss';
import { serverUrl } from '../serverUrl';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import CryptoJS from 'crypto-js';
import '../assets/scss/LoaderDisplay.scss';

function Feedback() {
  const [isLoading, setIsloading] = useState(false)

  const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
  const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };
  const [isDescending, setIsDescending] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [originalFeedbacks, setOriginalFeedbacks] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Maximum of 10 tasks per page

  // Calculate index of the first and last item of the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current items to display
  const currentItems = feedbacks.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  const totalPages = Math.ceil(feedbacks.length / itemsPerPage);

  const toggleOrder = () => {
    setIsDescending(!isDescending);
    // const reversed = setFeedbacks([...feedbacks].reverse());
  };
    // display the selected franchise
    const selectedFranchise = localStorage.getItem('selectedFranchise');
    const decryptedData = decryptData(selectedFranchise, secretKey);

  useEffect(() => {
    setIsloading(true)
    const fetchData = async () => {
      try {
        const response = await serverUrl.get(`/officers_management/feedback/?franchise_id=${decryptedData}`, config);
        const tasks = response.data.results;
        const sortedFeedbacks = tasks.sort((a, b) => {
          return new Date(b.task_date) - new Date(a.task_date);
        });
        setFeedbacks(sortedFeedbacks);
        setOriginalFeedbacks(sortedFeedbacks); // Set originalFeedbacks when fetching data
        setIsloading(false)
      } catch (error) {
        console.error('Error fetching tasks data:', error);
        setIsloading(false)

      }
    };
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    // const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString(undefined, options);
  };

  const handleDetailsClick = (feedback) => {
    setSelectedFeedback(feedback);
    setShowDetailsModal(true);
  };

  const handleEditClick = (feedback) => {
    setSelectedFeedback(feedback);
    setShowEditModal(true);
  };

  const handleDeleteClick = (feedback) => {
    setSelectedFeedback(feedback);
    setShowDeleteModal(true);
  };

  const handledeleteSubmit = () => {
    serverUrl
      .delete(`/officers_management/feedback/${selectedFeedback.id}/`, config)
      .then(() => {
        setShowDeleteModal(false);
        serverUrl
          .get(`/officers_management/feedback/?franchise_id=${decryptedData}`, config)
          .then((res) => {
            setFeedbacks(res.data.results);
            toast.success('Feedback deleted successfully');
            setShowDeleteModal(false);
          })
          .catch((error) => {
            console.error('Error deleting Feedback data:', error);
            toast.error('Failed to delete feedback data.');
          });
      })
      .catch((error) => {
        console.error('Error deleting feedback:', error);
        toast.error('Failed to delete feedback. Please try again later.');
      });
  };
  const flattenObject = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? `${prefix}.` : '';
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        Object.assign(acc, flattenObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchQuery(searchQuery);

    if (searchQuery === '') {
      setFeedbacks(originalFeedbacks);
    } else {
      const filteredFeedbacks = originalFeedbacks.filter((feedback) => {
        // Flatten the feedback object and convert values to lowercase for comparison
        const flatFeedback = flattenObject(feedback);
        return Object.values(flatFeedback).some(
          (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery)
        );
      });
      setFeedbacks(filteredFeedbacks);
    }
  };


  const [selectedDate, setSelectedDate] = useState('');
  const [selectedFeedbackType, setSelectedFeedbackType] = useState('');
  const [selectedFilterType, setSelectedFilterType] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const years = Array.from({ length: 25 }, (_, i) => 2000 + i); // List of years from 2000 to 2024
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]; // List of month names

  const handleFilter = () => {

    //   console.log("selectedTaskType:", selectedFeedbackType)
    // Create a copy of the original tasks array to apply filters on
    let filteredFeedbacks = [...originalFeedbacks];
    //   console.log(filteredFeedbacks)
    // Apply filters based on selected criteria

    // Filter by Task Status

    if (selectedFeedbackType !== '') {
      filteredFeedbacks = filteredFeedbacks.filter(feedback => feedback.feedback_type === selectedFeedbackType);
    }



    // Filter by Date
    if (selectedFilterType === 'year' && selectedYear !== '') {
      filteredFeedbacks = filteredFeedbacks.filter(feedback => new Date(feedback.created_at).getFullYear() === parseInt(selectedYear));
    } else if (selectedFilterType === 'month' && selectedMonth !== '') {
      filteredFeedbacks = filteredFeedbacks.filter(feedback => new Date(feedback.created_at).getMonth() === parseInt(selectedMonth) - 1);
    } else if (selectedFilterType === 'specificDate' && selectedDate !== '') {
      const formattedSelectedDate = formatDate(selectedDate);
      filteredFeedbacks = filteredFeedbacks.filter(feedback => formatDate(feedback.created_at) === formattedSelectedDate);
    }

    // Set the filtered tasks as the new tasks to display
    setFeedbacks(filteredFeedbacks);
    console.log(filteredFeedbacks)
    setShowFilterModal(false);

  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  if(isLoading){
    return(
      <div className='loading-screen'>
        <div className='logo'>

        </div>
      </div>
    )
  }

  return (
    <>
      <SideBar />
      <Header />
      <Container fluid className='feedback'>


        <div className="d-flex justify-content-between topFiltersData">
          <Button
            className="btn btn-sm border-0 bg-white addTask text-white"
          >
          </Button>

          <div className="d-flex justify-content-between filterTopsData">
            {/* <Button onClick={() => setShowFilterModal(true)} className="btn-warning">
              <i className="bi bi-funnel-fill me-2"></i>
              <span>Filters</span>
            </Button> */}

            {selectedFeedbackType || selectedFilterType || selectedYear || selectedDate  || selectedMonth ?

              <Button className='btn btn-sm bg-danger border-danger text-white' style={{ borderRadius: "20px", height: "3vh" }} onClick={() => { setSelectedFeedbackType(''); setSelectedFilterType(''); setSelectedFilterType(''); setSelectedDate(''); setSelectedMonth(''); setSelectedFilterType(''); setFeedbacks(originalFeedbacks); }}>
                
                  Reset Filter
              </Button>

              :
              <Button className='btn btn-sm bg-transparent border-danger text-danger' style={{ borderRadius: "20px", height: "4vh" }} onClick={() => setShowFilterModal(true)}>
                <i className="bi bi-funnel-fill me-2"></i>
                  Filter
              </Button>
            }

            <Button className="lastappdate btn-warning">
              <i className="bi bi-arrow-up"></i>
              <i className="bi bi-arrow-down me-2"></i>
              <span onClick={toggleOrder}>Last Appointment Date</span>
            </Button>
          </div>

        </div>

        {feedbacks.length === 0 ?
          <p style={{ fontSize: "25px", marginTop: "10px" }}>No feedback available</p>
          :
          <div className='taskDisplay'>
            <Form className="searchBar">
              <FormControl
                type="text"
                style={{width:"15% !important"}}
                className='feedbackInput'
                placeholder="Search Feedback"
                onChange={handleSearch}
              />
            </Form>
            <Table responsive>

              <thead>
                <tr className='table'>
                  <th>Date</th>
                  <th >Feedback Type</th>
                  <th>Feedback</th>
                  <th>Field Officer</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((feedback) => (
                  <tr key={feedback.id}>
                    <td>{formatDate(feedback.created_at)}</td>
                    <td>

                      <span style={{ borderRadius: "20px" }} className={`btn text-white w-75 btn-sm ${feedback.feedback_type === 'complaint' ? ' bg-danger' : feedback.feedback_type === 'suggestion' ? 'bg-info' : 'bg-warning' }`}>{feedback.feedback_type}</span>
                    </td>
                    <td>{truncateText(feedback.feedback, 55)}</td>
                    <td>{feedback.relationship_officer.user.full_name}</td>





                    <td className="actions">
                      <Dropdown>
                        <Dropdown.Toggle variant="link" className="btn btn-sm text-dark p-0">
                          <i className="bi bi-three-dots-vertical"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleDetailsClick(feedback)}>
                            <Button style={{ width: "10vw" }} className='btn btn-sm btn-warning'> <i className="bi bi-eye me-2"></i> View</Button>
                          </Dropdown.Item>

                          <Dropdown.Item onClick={() => handleDeleteClick(feedback)}>
                            <Button style={{ width: "10vw" }} className='btn btn-sm btn-danger'><i className="bi bi-trash me-2"></i> Delete</Button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {feedbacks.length > 0 && (
              <nav>
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage - 1)}>
                      {'<'}
                    </button>
                  </li>
                  <li className="page-item disabled">
                    <span className="page-link">
                      {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, feedbacks.length)} of {feedbacks.length}
                    </span>
                  </li>
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage + 1)}>
                      {'>'}
                    </button>
                  </li>
                </ul>
              </nav>
            )}

          </div>

        }

    

      </Container>

      {/* Details Modal */}
      <Modal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Feedback Details
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form className="formTask">

            <FormGroup>
              <Form.Label>Feedback</Form.Label>
              <FormControl
                as='textarea'
                name="customerName"
                value={selectedFeedback?.feedback}
                readOnly
                rows={10}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDetailsModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>



      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this feedback?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          {/* Add an onClick handler for deleting the selected feedback */}
          <Button variant="warning" onClick={handledeleteSubmit}>Delete</Button>
        </Modal.Footer>
      </Modal>

      {/* modal for filtering the tasks */}

      <Modal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter Feedbacks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Task Type filter */}
          <Form.Group controlId="formTaskType">
            <Form.Label>Filter By Feedback Type</Form.Label>
            <Form.Control
              as="select"
              value={selectedFeedbackType}
              onChange={(e) => setSelectedFeedbackType(e.target.value)}
            >
              <option value="">Select Feedback type</option>
              <option value="complaint">Complaint</option>
              <option value="request">Request</option>
              <option value="suggestion">Suggestion</option>
            </Form.Control>
          </Form.Group>

          {/* Date filter */}
          <Form.Group controlId="formDate">
            <Form.Label>Filter By Date</Form.Label>
            <div>
              <Form.Check
                type="radio"
                label="Year"
                checked={selectedFilterType === 'year'}
                onChange={() => setSelectedFilterType('year')}
              />
              {selectedFilterType === 'year' && (
                <Form.Control
                  as="select"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {/* Render the list of years from 2000 to 2024 */}
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Form.Control>
              )}
            </div>

            <div>
              <Form.Check
                type="radio"
                label="Month"
                checked={selectedFilterType === 'month'}
                onChange={() => setSelectedFilterType('month')}
              />
              {selectedFilterType === 'month' && (
                <Form.Control
                  as="select"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  {/* Render the list of months */}
                  <option value="" selected disabled>Select Month </option>

                  {months.map((month, index) => (
                    <option key={index} value={index + 1}>
                      {month}
                    </option>
                  ))}
                </Form.Control>
              )}
            </div>

            <div>
              <Form.Check
                type="radio"
                label="Specific Date"
                checked={selectedFilterType === 'specificDate'}
                onChange={() => setSelectedFilterType('specificDate')}
              />
              {selectedFilterType === 'specificDate' && (
                <Form.Control
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              )}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowFilterModal(false)}
          >
            Close
          </Button>
          <Button variant="info" onClick={handleFilter}>
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Feedback;
