import React, { useState, useEffect } from 'react'
import { Container, Card, Button, Form, FormControl, Table, Modal, FormGroup, ModalBody, Image, ModalHeader, FormSelect, ModalFooter } from 'react-bootstrap'
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import '../assets/scss/Customers.scss'
import { serverUrl } from '../serverUrl'
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import MapComponentClaim from '../components/MapComponentClaim';
import TaskAll from '../assets/images/claim (2).png'
import TaskAllNew from '../assets/images/claim.png'
import CryptoJS from 'crypto-js';
import '../assets/scss/LoaderDisplay.scss';

function Customers() {
    const [isLoading, setIsloading] = useState(false)
    const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
    const decryptData = (cipherText, secretKey) => {
        const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };
    const [searchQuery, setSearchQuery] = useState('');
    const [claimCount, setClaimCount] = useState(0);
    const [totalClaimSum, setTotalClaimSum] = useState(0);
    const [totalClaimPending, setTotalClaimPending] = useState(0);
    const [originalClaims, setOriginalClaims] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [selectedClaims, setSelectedClaims] = useState({})
    const [isDescending, setIsDescending] = useState(true);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showClaim, setShowClaim] = useState(false);
    const [selectedClaimStatus, setSelectedClaimStatus] = useState(false);
    const [claims, setClaims] = useState([]); // Filtered claims to display
    const [selectedClaim, setSelectedClaim] = useState('');



    const toggleOrder = () => {
        setIsDescending(!isDescending);
        const reversed = setClaims([...claims].reverse());
    };

    const handleopenmodal = (claim) => {
        setShowDetailsModal(true)
        setSelectedClaims(claim)
    }

    const [showEditModal, setShowEditModal] = useState(false)
    const handleEditmodal = (claim) => {
        setShowEditModal(true)
        setSelectedClaims(claim)
    }

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const handleDeleteModal = (claim) => {
        setShowDeleteModal(true);
        setSelectedClaims(claim);
    };



    const accessToken = localStorage.getItem('authTokens');
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    // const [claims, setClaims] = useState([])
    // display the selected franchise
    const selectedFranchise = localStorage.getItem('selectedFranchise');
    const decryptedData = decryptData(selectedFranchise, secretKey);
    const [fieldOfficers, setfieldOfficers] = useState([])
    const fetchData = async () => {
        try {


            const response = await serverUrl.get(`/officers_management/customer/?franchise=${decryptedData}`, config);
            const customer = response.data.results;
            const sortedClaims = customer.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
            const totalEstimatedAmount = customer.length;
            const Inactivecustomer = customer.filter(task => task.status === 'Inactive');
            const Activecustomer = customer.filter(task => task.status === 'Active');
            const totalInactiveCustomer = Inactivecustomer.length;
            const totalAactiveCustomer = Activecustomer.length;
            const responseRO = await serverUrl.get(`/officers_management/relationship-officer/?franchise=${decryptedData}`, config);

            setfieldOfficers(responseRO.data.results);
            setTotalClaimSum(totalAactiveCustomer)
            setTotalClaimPending(totalInactiveCustomer)


            setClaimCount(totalEstimatedAmount)
            setClaims(sortedClaims);
            setOriginalClaims(sortedClaims);
            setIsloading(false)

        } catch (error) {
            console.error('Error fetching tasks data:', error);
            setIsloading(false)
        }
    };
    useEffect(() => {
        setIsloading(true)
        fetchData();


    }, []);
    // console.log(claims)

    const flattenObject = (obj, prefix = '') => {
        return Object.keys(obj).reduce((acc, k) => {
            const pre = prefix.length ? `${prefix}.` : '';
            if (typeof obj[k] === 'object' && obj[k] !== null) {
                Object.assign(acc, flattenObject(obj[k], pre + k));
            } else {
                acc[pre + k] = obj[k];
            }
            return acc;
        }, {});
    };

    const handleSearch = (event) => {
        const searchQuery = event.target.value.toLowerCase();
        setSearchQuery(searchQuery);
        if (searchQuery === '') {
            setClaims(originalClaims);
        } else {
            const filteredTasks = originalClaims.filter((task) => {
                const flatTask = flattenObject(task);
                return Object.values(flatTask).some(
                    (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery)
                );
            });
            setClaims(filteredTasks);
        }
    };


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Maximum of 10 tasks per page

    // Calculate index of the first and last item of the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Get the current items to display
    const currentItems = claims.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total number of pages
    const totalPages = Math.ceil(claims.length / itemsPerPage);

    const [selectedDate, setSelectedDate] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedClaimType, setSelectedClaimType] = useState('');
    const [selectedFilterType, setSelectedFilterType] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');

    // add a customer
    const handleAddCustomerSubmit = async () => {
        const customerDataWithFranchise = {
            ...newCustomerData,
            franchise: decryptedData,
        };
        try {
            const res = await serverUrl.post(`/officers_management/customer/`, customerDataWithFranchise, config);
            toast.success('Successfully added a new customer');
            await fetchData();
            setNewCustomerData({
                full_name: '',
                phone_number: '',
                id_number: '',
                loan_limit: '',
                credit_score: '',
                status: '',
                relationship_officer: '',
            });
            handleCloseAddCustomerModal();
        } catch (error) {
            console.log(error);
            await fetchData();
            setNewCustomerData({
                full_name: '',
                phone_number: '',
                id_number: '',
                loan_limit: '',
                credit_score: '',
                status: '',
                relationship_officer: '',
            });
            handleCloseAddCustomerModal();
            toast.error('An error occurred!');
        }
    };

    const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
    const [newCustomerData, setNewCustomerData] = useState({
        full_name: '',
        phone_number: '',
        id_number: '',
        loan_limit: '',
        credit_score: '',
        status: '',
        relationship_officer: '',
    });

    const handleShowAddCustomerModal = () => setShowAddCustomerModal(true);
    const handleCloseAddCustomerModal = () => setShowAddCustomerModal(false);

    const handleAddCustomerChange = (e) => {
        const { name, value } = e.target;
        setNewCustomerData({
            ...newCustomerData,
            [name]: value,
        });
    };

    if (isLoading) {
        return (
            <div className='loading-screen'>
                <div className='logo'>

                </div>
            </div>
        )
    }


    return (
        <>
            <SideBar />
            <Header />
            <Container fluid className='claims'>
                <div className='claimsCard'>
                    <div className='claims-card1'>
                        <Card className="totalCard customers">

                            <div className="">
                                <Image src={TaskAll} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%", marginLeft: "15px" }} />


                                <div className='ms-3'>
                                    <h2 className='text-white'>Total Customers</h2>
                                    <span className='text-white'>{claimCount} Customers</span>

                                </div>
                            </div>

                        </Card>

                        <Card className="assignedCard customers">
                            <div className=''>
                                <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                                <div className='ms-3'>
                                    <h2>Total Active </h2>
                                    <span>{totalClaimSum && totalClaimSum.toLocaleString('en-US')} Customers</span>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className='cards-claims2'>
                        <Card className="assignedCard approved customers">
                            <div className=''>
                                <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                                <div className='ms-3'>
                                    <h2>Total Inactive</h2>
                                    <span>{totalClaimPending && totalClaimPending.toLocaleString('en-US')} Customers</span>
                                </div>
                            </div>
                        </Card>


                    </div>

                </div>

                <div className="d-flex justify-content-between topFiltersData">

                    <div className="d-flex justify-content-between w-100 filterTopsData">

                        <Button onClick={handleShowAddCustomerModal} className='btn btn-sm bg-transparent border-danger text-danger' style={{ borderRadius: "20px", height: "4vh" }}>Add Customer</Button>


                        <div className='d-none'>
                            {selectedStatus || selectedClaimType || selectedYear || selectedDate || selectedMonth || selectedFilterType ?

                                <Button className='btn btn-sm bg-danger border-danger text-white' style={{ borderRadius: "20px", height: "4vh" }} onClick={() => { setSelectedClaim(''); setSelectedStatus(''); setSelectedFilterType(''); setSelectedDate(''); setSelectedMonth(''); setSelectedFilterType(''); setClaims(originalClaims); }}>

                                    Reset Filter
                                </Button>

                                :
                                <Button className='btn btn-sm bg-transparent border-danger text-danger' style={{ borderRadius: "20px", height: "4vh" }} onClick={() => setShowFilterModal(true)}>
                                    <i className="bi bi-funnel-fill me-2"></i>
                                    Filter
                                </Button>
                            }



                        </div>


                    </div>

                </div>


                {claims.length === 0 ?
                    <p style={{ fontSize: "25px", marginTop: "10px" }}>No customers available</p>
                    :

                    <div className='taskDisplay'>
                        <Form className="searchBar">
                            <FormControl
                                type="text"
                                placeholder="Search Claim"
                                onChange={handleSearch}
                            />
                        </Form>
                        <Table responsive>

                            <thead>
                                <tr className='table'>
                                    <th>Full Name</th>
                                    <th>Phone Number</th>
                                    <th>Id Number</th>
                                    <th>Loan Limit</th>
                                    <th>Credit Score</th>
                                    <th>Status</th>
                                    <th>Field Officer</th>
                                </tr>
                            </thead>

                            <tbody>
                                {currentItems.map((customer) => (
                                    <tr key={customer.id}>

                                        <td className='text-capitalize'>{customer.full_name}</td>
                                        <td className='text-capitalize'>{customer.phone_number}</td>
                                        <td className='text-capitalize'>{customer.id_number}</td>
                                        <td className='text-capitalize'>{customer.loan_limit}</td>
                                        <td className='text-capitalize'>{customer.credit_score}</td>
                                        <td>

                                            <span style={{ borderRadius: "20px" }} className={`btn text-white w-100 btn-sm ${customer.status === 'Active' ? 'bg-success' : customer.status === 'Inactive' ? 'bg-danger' : 'bg-warning'}`}>{customer.status}</span>
                                        </td>
                                        <td className='text-capitalize'>{customer.relationship_officer}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        {claims.length > 0 && (
                            <nav>
                                <ul className="pagination">
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => paginate(currentPage - 1)}>
                                            {'<'}
                                        </button>
                                    </li>
                                    <li className="page-item disabled">
                                        <span className="page-link">
                                            {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, claims.length)} of {claims.length}
                                        </span>
                                    </li>
                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => paginate(currentPage + 1)}>
                                            {'>'}
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        )}
                    </div>

                }
                {/* add a customer */}
                <Modal show={showAddCustomerModal} onHide={handleCloseAddCustomerModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Customer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <FormGroup className="mb-3">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="full_name"
                                    value={newCustomerData.full_name}
                                    onChange={handleAddCustomerChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone_number"
                                    value={newCustomerData.phone_number}
                                    onChange={handleAddCustomerChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Form.Label>ID Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="id_number"
                                    value={newCustomerData.id_number}
                                    onChange={handleAddCustomerChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Form.Label>Loan Limit</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="loan_limit"
                                    value={newCustomerData.loan_limit}
                                    onChange={handleAddCustomerChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Form.Label>Credit Score</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="credit_score"
                                    value={newCustomerData.credit_score}
                                    onChange={handleAddCustomerChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Form.Label>Customer Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="status"
                                    value={newCustomerData.status}
                                    onChange={handleAddCustomerChange}
                                    required
                                >
                                    <option value='' selected disabled>Select customer status</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </Form.Control>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <Form.Label>Field Officer</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="relationship_officer"
                                    value={newCustomerData.relationship_officer}
                                    onChange={handleAddCustomerChange}
                                    required
                                >
                                    <option value='' selected disabled>Select Field officer</option>
                                    {fieldOfficers.map((officer, index) => (
                                        <option key={index} value={officer.user.full_name}>
                                            {officer.user.full_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </FormGroup>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn btn-sm ' variant="dark" onClick={handleCloseAddCustomerModal}>
                            Close
                        </Button>
                        <Button className='btn btn-sm bg-transparent text-danger btn-danger' onClick={handleAddCustomerSubmit}>
                            Add Customer
                        </Button>
                    </Modal.Footer>
                </Modal>



            </Container>
        </>
    )
}

export default Customers