import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/scss/SideBar.scss';
import { serverUrl } from '../serverUrl';

import Home from '../assets/images/home.png';
import Task from '../assets/images/test.png';
import Accounts from '../assets/images/multiple-users-silhouette.png';
import Feedback from '../assets/images/feedback.png';
import Panic from '../assets/images/fire-alarm.png';
import Claim from '../assets/images/money.png';
import Leaderboard from '../assets/images/crown (2).png';
import Profile from '../assets/images/user2.png';
import Logout from '../assets/images/power-button-off.png';
import BuyAirtime from '../assets/images/phone (1).png';
import PanicYellow from '../assets/images/warning.png';
import ContactImage from '../assets/images/contact.png';
import WalletWhite from '../assets/images/wallet -white.png'
import FlagWhite from '../assets/images/notification.png'
import InvoiceWhite from '../assets/images/invoice.png'
import SettingsWhite from '../assets/images/settings.png'
import CustomerWhite from '../assets/images/customer-review.png'

import { toast } from 'react-toastify';
import Logo from '../assets/images/usalama_logo.png'

import { jwtDecode } from 'jwt-decode';


function SideBar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  const [userDetails, setUserDetails] = useState({});


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const hash = window.location.hash
  const currentUrl = hash.substring(2)
  const handleLogout = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem("authTokens");
    localStorage.removeItem("selectedFranchise");
    toast.success('Successfull logged out !')
    navigate('login')
  }

  const handleLogoutWallet = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem("authTokens");
    toast.success('Kindly Login !')
    navigate('/wallet-login')
  }
  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };

  const decodedToken = jwtDecode(access);
  const userId = decodedToken.user_id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await serverUrl.get(`/user/edit-user/${userId}`, config);
        setUserDetails(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>


      <Navbar expand="md" className={`sidebar ${isOpen ? 'open' : ''}`}>

        <Navbar.Toggle onClick={toggleSidebar} aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">

          <Nav className="flex-column navigationBarDisplay">
            <div className='mb-2'>
              <Image src={Logo} style={{ width: "25%", margin: "0 auto" }} />


            </div>
            {currentUrl === 'dashboard' || currentUrl === '' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/dashboard">
                  <Image src={Home} />
                  <span>Dashboard</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/dashboard">
                  <Image src={Home} />
                  <span>Dashboard</span>
                </Nav.Link>
              </Nav.Item>

            }

            {currentUrl === 'tasks' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/tasks">
                  <Image src={Task} />
                  <span>Tasks</span>
                  {/* <Image src={TaskYellow} />
                  <span className='text-warning'>Tasks</span> */}
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/tasks">
                  <Image src={Task} />
                  <span>Tasks</span>
                </Nav.Link>
              </Nav.Item>

            }

            {currentUrl === 'claims' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/claims">
                  <Image src={Claim} />
                  <span>Claims</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/claims">
                  <Image src={Claim} />
                  <span>Claims</span>
                </Nav.Link>
              </Nav.Item>

            }

            {currentUrl === 'feedback' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/feedback">
                  <Image src={Feedback} />
                  <span>Feedback</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/feedback">
                  <Image src={Feedback} />
                  <span>Feedback</span>
                </Nav.Link>
              </Nav.Item>

            }



            {currentUrl === 'panic' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/panic">
                  <Image src={Panic} />
                  <span>Panic</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/panic">
                  <Image src={Panic} />
                  <span>Panic</span>
                </Nav.Link>
              </Nav.Item>

            }


            {currentUrl === 'accounts' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/accounts">
                  <Image src={Accounts} />
                  <span>Accounts</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/accounts">
                  <Image src={Accounts} />
                  <span>Accounts</span>
                </Nav.Link>
              </Nav.Item>

            }

            {currentUrl === 'emergencycontacts' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/emergencycontacts">
                  <Image src={ContactImage} />
                  <span> Contacts</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/emergencycontacts">
                  <Image src={ContactImage} />
                  <span> Contacts</span>
                </Nav.Link>
              </Nav.Item>

            }


            {currentUrl === 'leaderboard' ?
              <Nav.Item className='activeLink d-none leadersboard'>
                <Nav.Link as={Link} to="/leaderboard">
                  <Image src={Leaderboard} />
                  <span>Leaderboard</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item className='d-none'>
                <Nav.Link as={Link} to="/leaderboard">
                  <Image src={Leaderboard} />
                  <span>Leaderboard</span>
                </Nav.Link>
              </Nav.Item>

            }


            {currentUrl === 'flags' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/flags">
                  <Image src={FlagWhite} />
                  <span>Flags</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/flags">
                  <Image src={FlagWhite} />
                  <span>Flags</span>
                </Nav.Link>
              </Nav.Item>

            }
            {currentUrl === 'invoice' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/invoice">
                  <Image src={InvoiceWhite} />
                  <span className=''>Invoices</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/invoice">
                  <Image src={InvoiceWhite} />
                  <span>Invoices</span>
                </Nav.Link>
              </Nav.Item>
            }

            {/* 

            {currentUrl === 'region' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/region">
                  <Image src={RestrictedYellow} />
                  <span className='text-warning'>Region</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/region">
                  <Image src={RestrictedWhite} />
                  <span>Region</span>
                </Nav.Link>
              </Nav.Item>
            } */}




            {currentUrl === 'wallet' ?
              <Nav.Item className='activeLink' onClick={handleLogoutWallet} >
                <Nav.Link as={Link} to="/wallet-login">
                  <Image src={WalletWhite} />
                  <span>Wallet</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item onClick={handleLogoutWallet} >
                <Nav.Link as={Link} to="/wallet-login">
                  <Image src={WalletWhite} />
                  <span>Wallet</span>
                </Nav.Link>
              </Nav.Item>

            }


            {currentUrl === 'buy-airtime' ?
              <Nav.Item className='activeLink' >
                <Nav.Link as={Link} to="/buy-airtime">
                  <Image src={BuyAirtime} />
                  <span>Buy Airtime</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item >
                <Nav.Link as={Link} to="/buy-airtime">
                  <Image src={BuyAirtime} />
                  <span>Buy Airtime</span>
                </Nav.Link>
              </Nav.Item>

            }
              {currentUrl === 'customer' || currentUrl === '/customer' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/customer">
                  <Image src={CustomerWhite} />
                  <span>Customers</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/customer">
                  <Image src={CustomerWhite} />
                  <span>Customers</span>
                </Nav.Link>
              </Nav.Item>

            }
             {/* {currentUrl === 'settings' || currentUrl === '' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/settings">
                  <Image src={SettingsWhite} />
                  <span>Settings</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/settings">
                  <Image src={SettingsWhite} />
                  <span>Settings</span>
                </Nav.Link>
              </Nav.Item>
            } */}

            {currentUrl === 'profile' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/profile">
                  <Image src={Profile} />
                  <span>Profile</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item>
                <Nav.Link as={Link} to="/profile">
                  <Image src={Profile} />
                  <span>Profile</span>
                </Nav.Link>
              </Nav.Item>

            }



            {currentUrl === 'logout' ?
              <Nav.Item className='activeLink logout' onClick={handleLogout} style={{ position: "absolute", bottom: "30px", cursor: "pointer" }}>
                <Nav.Link as={Link} to="/login" onClick={handleLogout}  >
                  <Image src={PanicYellow} />
                  <span className='text-warning'>Logout</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item className='logout'>
                <Nav.Link as={Link} to="/login" onClick={handleLogout}  >
                  <Image src={Logout} />
                  <span>Logout</span>
                </Nav.Link>
              </Nav.Item>

            }


            {/* {currentUrl === 'logout' ?
              <Nav.Item className='activeLink'>
                <Nav.Link as={Link} to="/logout">
                  <Image src={PanicYellow} />
                  <span className='text-warning'>Logout</span>
                </Nav.Link>
              </Nav.Item>
              :
              <Nav.Item className='logout'>
                <Nav.Link as={Link} to="/logout">
                  <Image src={Logout} />
                  <span>Logout</span>
                </Nav.Link>
              </Nav.Item>

            } */}

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default SideBar;
