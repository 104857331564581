import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../assets/scss/LoaderDisplay.scss';


const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return (
      <div className='loading-screen'>
        <div className='logo'>

        </div>
      </div>
    )
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
