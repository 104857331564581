import React, { useEffect, useState } from 'react'
import '../assets/scss/Tasks.scss';
import '../assets/scss/FlagsActivity.scss';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import { serverUrl } from '../serverUrl';
import { Form, FormControl, Container, Image, Modal, Card, Table, Button, ModalBody, FormSelect } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TaskAllNew from '../assets/images/bell.png'
import TaskAll from '../assets/images/bell (1).png'
import CryptoJS from 'crypto-js';
import MapFlags from '../components/MapFlags';


function FlagsDisplay() {
    const [isLoading, setIsLoading] = useState(false)
    const accessToken = localStorage.getItem('authTokens');
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
    const decryptData = (cipherText, secretKey) => {
        const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };
    // display the selected franchise
    const [franchiseSelected, setFranchise] = useState('');

    useEffect(() => {
        const selectedFranchise = localStorage.getItem('selectedFranchise');
        if (selectedFranchise) {
            const decryptedData = decryptData(selectedFranchise, secretKey);
            setFranchise(decryptedData);
        }
    }, []);
    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;
    const [flagsData, setFlagsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [flagCount, setFlagCount] = useState('');
    const [flagCountOverstaying, setFlagCountOverstaying] = useState('');
    const [flagCountOffline, setFlagCountOffline] = useState('');
    const [flagCountRestrictedRegion, setFlagCountRestrictedRegion] = useState('');
    const [flagCountLowBattery, setFlagCountLowBattery] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [resetFilterData, setRestFilterData] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showMonthPicker, setShowMonthPicker] = useState(false);
    const [showYearPicker, setShowYearPicker] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedFlag, setSelectedFlag] = useState({});

    useEffect(() => {
        if (!franchiseSelected) {
            return; // Exit early if franchiseSelected is not set
        }

        setIsLoading(true);

        const fetchData = async () => {
            try {
                const responseRO = await serverUrl.get(`/officers_management/flags-activity/?franchise=${franchiseSelected}`, config);
                setFlagsData(responseRO.data.results);

                // Loop through the responseRO.data.results and update the read_status to True
                responseRO.data.results
                    .filter((data) => !data.read_status)
                    .forEach((data) => {
                        serverUrl.patch(`/officers_management/flags-activity/${data.id}/`, { read_status: true }, config)
                            .then((res) => {
                                // Handle successful update if needed
                            })
                            .catch((error) => {
                                console.error('Error updating flag activity:', error);
                            });
                    });

                const overstayingCount = responseRO.data.results.filter((overstaying) => overstaying.flag_type === 'overstaying');
                // const restrictedCount = responseRO.data.results.filter((overstaying) => overstaying.flag_type === 'restricted_region');
                const offlineCount = responseRO.data.results.filter((overstaying) => overstaying.flag_type === 'location_off');
                const batteryCount = responseRO.data.results.filter((overstaying) => overstaying.flag_type === 'low_battery');
                setFlagCount(responseRO.data.count);
                setFlagCountOverstaying(overstayingCount.length);
                setFlagCountOffline(offlineCount.length);
                setFlagCountLowBattery(batteryCount.length)
                // setFlagCountRestrictedRegion(restrictedCount.length);
                setFilteredData(responseRO.data.results);
                setIsLoading(false);

            } catch (error) {
                console.error('Error fetching tasks data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [franchiseSelected]);



    useEffect(() => {
        let filtered = flagsData;

        if (selectedFilter !== 'All' && selectedFilter !== 'date' && selectedFilter !== 'month' && selectedFilter !== 'year') {
            filtered = filtered.filter(flag => flag.flag_type === selectedFilter);
        }

        if (selectedDate) {
            filtered = filtered.filter(flag => new Date(flag.created_at).toDateString() === selectedDate.toDateString());
        }

        if (selectedMonth) {
            filtered = filtered.filter(flag => new Date(flag.created_at).getMonth() === selectedMonth.getMonth() && new Date(flag.created_at).getFullYear() === selectedMonth.getFullYear());
        }

        if (selectedYear) {
            filtered = filtered.filter(flag => new Date(flag.created_at).getFullYear() === selectedYear.getFullYear());
        }

        if (searchQuery) {
            filtered = filtered.filter(flag =>
                flag.relationship_officer.user.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                flag.feedback.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredData(filtered);
    }, [flagsData, selectedFilter, selectedDate, selectedMonth, selectedYear, searchQuery]);


    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const handleShowModal = (flagType) => {
        setModalContent(flagType);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);;

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // 

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const resetFilter = () => {
        setFilteredData(flagsData);
        setSelectedFilter('All');
        setRestFilterData(false)

        setSelectedDate(null);
        setSelectedMonth(null);
        setSelectedYear(null);
    };

    const handleFilterClick = (filter) => {
        setSelectedFilter(filter);
        setRestFilterData(true)
        setSelectedDate(null);
        setSelectedMonth(null);
        setSelectedYear(null);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setShowDatePicker(false);
        setSelectedMonth(null);
        setSelectedYear(null);
        setSelectedFilter('date');
    };

    const handleMonthChange = (date) => {
        setSelectedMonth(date);
        setShowMonthPicker(false);
        setSelectedDate(null);
        setSelectedYear(null);
        setSelectedFilter('month');
    };

    const handleYearChange = (date) => {
        setSelectedYear(date);
        setShowYearPicker(false);
        setSelectedDate(null);
        setSelectedMonth(null);
        setSelectedFilter('year');
    };

    const handleSpecificDateClick = () => {
        setShowDatePicker(true);
        setShowMonthPicker(false);
        setShowYearPicker(false);
        setSelectedFilter('date');
    };

    const handleSpecificMonthClick = () => {
        setShowDatePicker(false);
        setShowMonthPicker(true);
        setShowYearPicker(false);
        setSelectedFilter('month');
    };

    const handleSpecificYearClick = () => {
        setShowDatePicker(false);
        setShowMonthPicker(false);
        setShowYearPicker(true);
        setSelectedFilter('year');
    };


    // Function to handle click on trash icon
    const handleDeleteClick = (flagsData) => {
        setSelectedFlag(flagsData);
        console.log(flagsData)
        setShowDeleteModal(true);
    };

    const handledeleteSubmit = () => {
        serverUrl
            .delete(`officers_management/flags-activity/${selectedFlag.id}/`, config)
            .then((res) => {
                // Update the tasks list or perform any other necessary actions upon successful submission
                console.log('flag activity deleted successfully:');
                // Close the edit modal
                setShowDeleteModal(false);
                // Fetch updated task data from the server
                serverUrl
                    .get(`/officers_management/flags-activity/`, config)
                    .then((res) => {
                        setFlagsData(res.data.results); // Update the tasks state with the new data
                        // Show a success message using toast
                        toast.success('flag activity deleted successfully');
                        setShowDeleteModal(false);
                    })
                    .catch((error) => {
                        console.error('Error deleting flag activity :', error);
                        // Show an error message using toast
                        toast.error('Failed to delete flag activity.');
                    });
            })

    };
    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedFilter(value);
        setShowDatePicker(value === 'date');
        setShowMonthPicker(value === 'month');
        setShowYearPicker(value === 'year');
    };

    if (isLoading) {
        return (
            <div className='loading-screen'>
                <div className='logo'>

                </div>
            </div>
        )
    }


    return (
        <>
            <SideBar />
            <Header />
            <Container fluid className="tasks">
                <div className="claimsCard all">
                    <div className='card1'>
                        <Card className="totalCard flags">
                            <div className="">
                                <Image src={TaskAll} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%", marginLeft: "15px" }} />

                                <div className='ms-3'>
                                    <h2 className='text-white'>Total Flags</h2>
                                    <span className='text-white'>{flagCount && flagCount.toLocaleString()} Flags</span>
                                </div>
                            </div>

                        </Card>


                        <Card className="assignedCard all flags">
                            <div className=''>
                                <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                                <div className='ms-3'>
                                    <h2> Overstaying</h2>
                                    <span>{flagCountOverstaying && flagCountOverstaying.toLocaleString()} Flags</span>
                                </div>
                            </div>


                        </Card>
                    </div>
                    <div className='card2'>
                        <Card className="assignedCard all flags">
                            <div className=''>
                                <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                                <div className='ms-3'>
                                    <h2>Location Off</h2>
                                    <span>{flagCountOffline && flagCountOffline.toLocaleString()} Flags</span>
                                </div>
                            </div>


                        </Card>
                        <Card className="pendingCard all flags">
                            <div className=''>
                                <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                                <div className='ms-3'>
                                    <h2>Low Battery</h2>
                                    <span>{flagCountLowBattery && flagCountLowBattery.toLocaleString()} Flags</span>
                                </div>
                            </div>
                        </Card>

                        <Card className="pendingCard all flags d-none">
                            <div className=''>
                                <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                                <div className='ms-3'>
                                    <h2>Restricted Region</h2>
                                    <span>0 Flags</span>
                                    {/* <span>{flagCountRestrictedRegion && flagCountRestrictedRegion.toLocaleString()} Flags</span> */}
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>


                <div className="d-flex justify-content-between topFiltersData">
                    <div className='selectbuttons justify-content-end' style={{ marginTop: "0" }}>
                        {resetFilterData && (
                            <button onClick={resetFilter} className='text-white'>
                                Reset Filter
                            </button>
                        )}

                    </div>

                    <div className='buttonsDisplay'>
                        <button
                            style={{ backgroundColor: selectedFilter === 'All' ? '#E82949' : '', color: selectedFilter === 'All' ? 'white' : '' }}
                            onClick={() => handleFilterClick('All')}
                        >
                            All
                        </button>
                        <button
                            style={{ backgroundColor: selectedFilter === 'overstaying' ? '#E82949' : '', color: selectedFilter === 'overstaying' ? 'white' : '' }}
                            onClick={() => handleFilterClick('overstaying')}
                        >
                            Overstaying
                        </button>
                        <button
                            style={{ backgroundColor: selectedFilter === 'low_battery' ? '#E82949' : '', color: selectedFilter === 'low_battery' ? 'white' : '' }}
                            onClick={() => handleFilterClick('low_battery')}
                        >
                            Low Battery
                        </button>
                        {/* <button
                            style={{ backgroundColor: selectedFilter === 'restricted_region' ? '#E82949' : '', color: selectedFilter === 'restricted_region' ? 'white' : '' }}
                            onClick={() => handleFilterClick('restricted_region')}
                        >
                            Restricted Region
                        </button> */}
                        <button
                            style={{ backgroundColor: selectedFilter === 'location_off' ? '#E82949' : '', color: selectedFilter === 'location_off' ? 'white' : '' }}
                            onClick={() => handleFilterClick('location_off')}
                        >
                            Location Off
                        </button>
                    </div>

                    {/* <Button className='btn btn-warning' onClick={() => setShowFilterModal(true)} style={{ height: "37px" }}>
                        <i className="bi bi-funnel-fill me-2"></i>
                        <span>Filters</span>
                    </Button> */}

                    <select className='filterDataDisplay' value={selectedFilter} onChange={handleSelectChange} style={{ height: "fit-content" }}>
                        <option value="">Select Filter</option>
                        <option value="date">Specific Date</option>
                        <option value="month">Specific Month</option>
                        <option value="year">Specific Year</option>
                    </select>
                    <div className='' style={{ height: "fit-content" }}>

                        {showDatePicker && (
                            <div className='date-picker'>
                                <DatePicker selected={selectedDate} onChange={handleDateChange} inline />
                            </div>
                        )}
                        {showMonthPicker && (
                            <div className='month-picker'>
                                <DatePicker
                                    selected={selectedMonth}
                                    onChange={handleMonthChange}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    inline
                                />
                            </div>
                        )}
                        {showYearPicker && (
                            <div className='year-picker'>
                                <DatePicker
                                    selected={selectedYear}
                                    onChange={handleYearChange}
                                    dateFormat="yyyy"
                                    showYearPicker
                                    inline
                                />
                            </div>
                        )}

                    </div>




                </div>

                {flagsData.length === 0 ? (
                    <p style={{ fontSize: '25px', marginTop: '10px' }}>
                        No flags raised at the moment
                    </p>
                ) : (

                    <div className='taskDisplay'>
                        <Form className="searchBar">
                            <FormControl
                                type="text"
                                placeholder="Search Activity"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </Form>
                        <Table>

                            <thead>
                                <tr className="table">
                                    <th>Officer Name</th>
                                    <th>Phone Number</th>
                                    <th>Flag Type</th>
                                    <th>Description</th>
                                    <th>View</th>

                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((flag) => (
                                    <tr key={flag.id}>
                                        <td>{flag.relationship_officer.user.full_name}</td>
                                        <td>{flag.relationship_officer.user.phone_number}</td>
                                        <td className='text-capitalize'>{flag.flag_type.replace(/_/g, ' ')}</td>
                                        <td>{flag.feedback}</td>
                                        <td>
                                            <i className='bi bi-eye-fill btn btn-sm btn-info' onClick={() => handleShowModal(flag)}></i>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {flagsData.length > 0 && (
                            <nav>
                                <ul className="pagination">
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => paginate(currentPage - 1)}>
                                            {'<'}
                                        </button>
                                    </li>
                                    <li className="page-item disabled">
                                        <span className="page-link">
                                            {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, flagsData.length)} of {flagsData.length}
                                        </span>
                                    </li>
                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={() => paginate(currentPage + 1)}>
                                            {'>'}
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        )}
                    </div>


                )}

                {/* modal for filtering the tasks */}
                {/* modal to view the map */}
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-capitalize'>{modalContent?.flag_type?.replace(/_/g, ' ')} Flag</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <h6><b>Flag Message</b></h6>
                        <p style={{ fontSize: "11px" }}>{modalContent.feedback}</p>
                        <h6><b>Flag Location</b></h6>

                        <MapFlags coordinates={modalContent} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                {/* Delete Confirmation Modal */}
                <Modal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Tasks</Modal.Title>
                    </Modal.Header>
                    {/* Modal content */}
                    <ModalBody>Are you sure you want to delete this data?</ModalBody>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Close
                        </Button>
                        <Button
                            className="delete"
                            variant="info"
                            onClick={handledeleteSubmit}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

            </Container >

        </>
    )
}

export default FlagsDisplay