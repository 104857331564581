import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import '../assets/scss/MapFalg.scss'

const MapFlags = ({ google, coordinates }) => {
    const defaultCenter = { lat: coordinates.latitude, lng: coordinates.longitude };

    return (
        <Map
            google={google}
            zoom={10}
            initialCenter={defaultCenter}
            className='mapFlag'
            style={{ width: '90%', height: '400px', position: 'relative' }}
            disableDefaultUI={true}
            draggable={false}
            scrollwheel={false}
            zoomControl={true}
        >
            <Marker position={{ lat: coordinates.latitude, lng: coordinates.longitude }} />
        </Map>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDxKuaBgR44-dZ-vZGGWY4YnL4NPEEuri0'
})(MapFlags);
