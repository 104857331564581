import React, { useState, useEffect } from 'react';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import MapKenyaComponent from '../components/MapKenyaComponent';
import { Container, Accordion, Card, Button, Row, Col, Form, Modal } from 'react-bootstrap'; // Import Modal from react-bootstrap
import '../assets/scss/Restricted.scss';
import { serverUrl } from '../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';


function RestrictedRegion() {
  const [selectedSubcounty, setSelectedSubcounty] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [countyData, setCountyData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [franchiseRegions, setFranchiseRegions] = useState(0);
  const [franchise, setFranchise] = useState({});

  const handleSubcountyClick = (subcounty) => {
    setSelectedSubcounty(subcounty);
  };

  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  const decodedToken = jwtDecode(access);
  const userId = decodedToken.user_id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const admin = await serverUrl.get(`/officers_management/admins/?user=${userId}`, config);
        setFranchise(admin.data.results[0].franchise)

        const franchiseRegion = await serverUrl.get(`/officers_management/franchise-region/?franchise=${admin.data.results[0].franchise}`, config);
        setFranchiseRegions(franchiseRegion.data.count)

        const response = await serverUrl.get(`/officers_management/get-sub-counties?franchise_id=${admin.data.results[0].franchise}`, config);
        setCountyData(response.data);

      } catch (error) {
        console.error('Error fetching panic data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCountyData = countyData.filter(county =>
    county.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddRegion = (county) => {
    setSelectedCounty(county);
    setShowModal(true);
  };

  const handleConfirmAddRegion = () => {
    // post the region
    const data = {
      franchise: franchise,
      region: selectedSubcounty[0].county
    }

    serverUrl.post('/officers_management/franchise-region/', data, config)
      .then((res) => {
        toast.success('Succesfully added the region')
        setShowModal(false);

      })
      .catch((error) => {
        console.log(error)
      })
  };


  useEffect (()=>{
    const fetchSubCounties = async() =>{
      try{
      const response= await serverUrl.get(`/officers_management/sub-counties`,config);
      setCountyData(response.data);
      console.log(response.data)
      }catch(error){
        console.error('Error fetching sub-counties:', error);
        console.log(error)
      }
    };
    fetchSubCounties();
  },[]);

  return (
    <>
      <SideBar />
      <Header />
      <Container fluid className='restricted'>

        <div className='d-flex justify-content-between '>
          <Card className='pendingCard'>
            <h2>Total Regions</h2>
            <span>{franchiseRegions}</span>
            <Link to='/restricted-areas' className='text-decoration-none text-dark'>
              <Button className='btn btn-sm bg-white text-dark w-10 border-0'>View Regions</Button>
            </Link>
          </Card>
          {selectedSubcounty && (
            <Button style={{ height: "5vh" }} className='btn btn-sm btn-warning text-white mb-3' onClick={() => handleAddRegion(selectedSubcounty?.county)}> {/* Pass selected county to handleAddRegion */}
              Add Region
            </Button>
          )}
        </div>

        <Row className='mt-2 restricted-row'>
          <Col md={3} style={{ height: "90vh", overflowY: "auto" }}>
            <Form.Control
              type="text"
              placeholder="Search Counties"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ marginBottom: '1rem', width: '100%', float: 'end', marginTop: "20px", border: "2px solid lightgray" }}
            />
            <Accordion style={{float: "end" }}>
              {filteredCountyData.map((county, index) => (
                <Accordion.Item eventKey={String(index)} key={index}>
                  <Accordion.Header onClick={() => handleSubcountyClick(county.subcounties)}>{county.name}</Accordion.Header>
                  <Accordion.Body className='d-block text-start'>
                    {county.subcounties.map((subcounty, subIndex) => (
                      <p
                        key={subIndex}
                      >
                        {subcounty.subcounty}
                      </p>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>

          <Col md={8}>
            <div >
              <MapKenyaComponent selectedSubcounty={selectedSubcounty} />
            </div>
          </Col>

        </Row>
      </Container>

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Add Region</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to add {selectedCounty?.name} to your region?
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-sm' variant="secondary" onClick={() => setShowModal(false)}>
            No
          </Button>
          <Button className='btn btn-sm' variant="success" onClick={handleConfirmAddRegion}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RestrictedRegion;
