import React from 'react';
import BackgroundImage from '../assets/images/4040.png';

function NotFound() {
    return (
        <div
            style={{
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                height: '100vh',  // Changed to 100vh to ensure it takes up the full viewport height
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                textAlign: 'center',
                backgroundRepeat: 'no-repeat',  // Ensures the image doesn't repeat
            }}
        >
            {/* Added some content for visibility */}
        </div>
    );
}

export default NotFound;
