import React, { useEffect, useState } from 'react';
import { Map, Marker, Polyline, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import { renderToString } from 'react-dom/server';
import { CardBody, Card, Image, Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MapComponentUser = ({ google, data }) => {
    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [polylines, setPolylines] = useState([]);
    const [initialCenter, setInitialCenter] = useState({ lat: -1.2921, lng: 36.817223 });
    const [activeMarker, setActiveMarker] = useState(null);
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [infoWindowContent, setInfoWindowContent] = useState(null);
    const [userDetails, setUserDetails] = useState({});
    const [activeTab, setActiveTab] = useState('1');
    const [isLoading, setIsloading] = useState(false)

    const [selectedDate, setSelectedDate] = useState(new Date());

    const accessToken = localStorage.getItem('authTokens');
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };

    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;

    useEffect(() => {
        setIsloading(true)
        const loadMarkers = async () => {
            if (data.length > 0) {
                try {
                    const res = await axios.get(`/officers_management/relationship-officer/${data[0].ro_id}/`, config);
                    setUserDetails(res.data);
                    console.log(res.data);
                } catch (error) {
                    console.error(error);
                }

                const newMarkers = [];
                const markerPositions = new Set();

                for (const item of data) {
                    let profileData = item.ro_profile;
                    if (item.ro_profile.length > 10) {
                        profileData = await convertImageToBase64(item.ro_profile);
                    }

                    const fromPosition = { lat: item.from_latitude, lng: item.from_longitude };
                    const toPosition = { lat: item.to_latitude, lng: item.to_longitude };

                    if (!markerPositions.has(JSON.stringify(fromPosition))) {
                        newMarkers.push({
                            position: fromPosition,
                            profile: profileData,
                            time: item.duration,
                            backgroundColor: item.color,
                            data: item,
                        });
                        markerPositions.add(JSON.stringify(fromPosition));
                    }

                    if (item.to_latitude && item.to_longitude && (item.from_latitude !== item.to_latitude || item.from_longitude !== item.to_longitude)) {
                        if (!markerPositions.has(JSON.stringify(toPosition))) {
                            newMarkers.push({
                                position: toPosition,
                                profile: profileData,
                                time: item.duration,
                                backgroundColor: item.color,
                            });
                            markerPositions.add(JSON.stringify(toPosition));
                        }
                    }
                }
                setMarkers(newMarkers);
                setIsloading(false)


                const newPolylines = data.map(item => {
                    if (item.to_latitude && item.to_longitude && (item.from_latitude !== item.to_latitude || item.from_longitude !== item.to_longitude)) {
                        return [
                            { lat: item.from_latitude, lng: item.from_longitude },
                            { lat: item.to_latitude, lng: item.to_longitude }
                        ];
                    }
                    return null;
                }).filter(polyline => polyline !== null);
                setPolylines(newPolylines);

                setInitialCenter({ lat: data[0].from_latitude, lng: data[0].from_longitude });

                if (newMarkers.length > 1) {
                    fitMapToMarkers(newMarkers);
                }
            }
        };

        loadMarkers();
    }, [data]); // Ensure this useEffect runs only when 'data' changes

    const convertImageToBase64 = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const fitMapToMarkers = (markers) => {
        if (map && markers.length > 0) {
            const bounds = new google.maps.LatLngBounds();
            markers.forEach(marker => {
                bounds.extend(marker.position);
            });
            map.fitBounds(bounds);
        }
    };

    const CustomLabelSVG = ({ backgroundColor, profile, text }) => (
        <svg
            width="60"
            height="60"
            viewBox="0 0 60 70"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0"
                y="0"
                width="60"
                height="60"
                fill={backgroundColor}
                rx="10"
            />
            {profile.length > 3 ? (
                <image href={profile} width="40" height="40" x="10" y="10" alt='profile' />
            ) : (
                <>
                    <rect x="10" y="7" width="40" height="40" fill={text} rx="35" ry="35" />
                    <text
                        x="30"
                        y="30"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        fontSize="19px"
                        fontWeight="bold"
                        fill="white"
                        fontFamily="Arial"
                    >
                        {profile}
                    </text>
                </>
            )}
            <polygon
                points="35,50 50,70 65,50"
                fill={backgroundColor}
            />
        </svg>
    );

    const onMarkerClick = (props, marker) => {
        setActiveMarker(marker);
        setShowingInfoWindow(true);
        setInfoWindowContent(props.data);
    };

    const onMapClicked = () => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false);
            setActiveMarker(null);
        }
    };
    const [showDatePicker, setShowDatePicker] = useState(false);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        console.log(date);
        // Logic to update data based on the selected date
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based
        const year = date.getFullYear();
        return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    };

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (isLoading) {
        return (
            <div className='loading-screen'>
                <div className='logo'>

                </div>
            </div>
        )
    }

    return (
        <Map
            google={google}
            className='position-relative mapHomePage'
            zoom={9}
            center={initialCenter}
            onReady={(mapProps, map) => setMap(map)}
            onClick={onMapClicked}
            style={{ height: '80vh', width: '100%', position: 'relative' }}
        >
            {markers.map((marker, index) => (
                <Marker
                    key={index}
                    position={marker.position}
                    icon={{
                        url: `data:image/svg+xml,${encodeURIComponent(renderToString(<CustomLabelSVG backgroundColor={"white"} profile={marker.profile} text={marker.backgroundColor} />))}`,
                        scaledSize: new google.maps.Size(60, 70),
                    }}
                    onClick={onMarkerClick}
                    data={marker}
                />
            ))}
            {polylines.map((polyline, index) => (
                <Polyline
                    key={index}
                    path={polyline}
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={3}
                />
            ))}
            <InfoWindow
                marker={activeMarker}
                visible={showingInfoWindow}
            >
                <div>
                    <h2>{infoWindowContent?.data.ro_name}</h2>
                    {infoWindowContent?.data.task_allocation ? (
                        <>
                            <p>Ride Duration: {infoWindowContent?.data.trip_ride_duration}</p>
                            <p>Travel Mode: {infoWindowContent?.data.trip_travel_mode}</p>
                            <p>Trip Distance: {infoWindowContent?.data.trip_ride_distance}</p>
                        </>
                    ) : (
                        <>
                            <p>Location: {infoWindowContent?.data.location}</p>
                            <p>Time: {infoWindowContent?.time}</p>
                        </>
                    )}
                </div>
            </InfoWindow>
            <Card className='topDisplayCardMap d-none'>
                <CardBody>
                    {data && userDetails && (
                        <>
                            <div>
                                <Image src={userDetails.profile_picture} />
                                <div>
                                    <p>18</p>
                                    <p>Tasks</p>
                                </div>
                                <Image src={userDetails.profile_picture} />
                                <div>
                                    <p>50.2km</p>
                                    <p>Distance</p>
                                </div>
                            </div>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ tabsdisplay: true, active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}>
                                        Today
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ tabsdisplay: true, active: activeTab === '2' })}
                                        onClick={() => { toggleTab('2'); }}>
                                        Yesterday
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ tabsdisplay: true, active: activeTab === '3' })}
                                        onClick={() => { toggleTab('3'); }}>
                                        Select Date
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent className="" activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <h6>{formatDate(today)}</h6>
                                    <div>
                                        <i className='bi bi-bell'></i>
                                        <div className='d-block'>
                                            <p>Current Task</p>
                                            <span>Debt, collection</span>
                                            <span>Location</span>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <h6>{formatDate(yesterday)}</h6>
                                    <div>
                                        <i className='bi bi-bell'></i>
                                        <div className='d-block'>
                                            <p>Current Task</p>
                                            <span>Debt, collection</span>
                                            <span>Location</span>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Button variant="primary" onClick={() => setShowDatePicker(true)}>
                                        <i className='bi bi-calendar'></i>
                                    </Button>
                                    {showDatePicker && (
                                        <DatePicker selected={selectedDate} onChange={handleDateChange} />
                                    )}
                                </TabPane>
                            </TabContent>
                        </>
                    )}
                </CardBody>
            </Card>
        </Map>
    );
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDxKuaBgR44-dZ-vZGGWY4YnL4NPEEuri0'
})(MapComponentUser);
