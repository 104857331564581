import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import { Container, Form, FormControl, Button, Table, Modal, Pagination, FormGroup } from 'react-bootstrap';
import '../assets/scss/PanicAlertReport.scss';
import { serverUrl } from '../serverUrl';
import CryptoJS from 'crypto-js';


function PanicAlertReports() {
    
    const { id } = useParams();
    const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
    const decryptData = (cipherText, secretKey) => {
      const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };
    const [alerts, setAlerts] = useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const[emergencyContactData,setEmergencyContactData] = useState(null)

    const accessToken = localStorage.getItem('authTokens');
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };

    const selectedFranchise = localStorage.getItem('selectedFranchise');
    const decryptedData = decryptData(selectedFranchise, secretKey);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await serverUrl.get(`/officers_management/panic-alerts/?franchise=${decryptedData}`, config);
                const allPanics = response.data.results;
                const unresolvedPanics = allPanics.filter(panic => !panic.resolved);
                const resolvedPanics = allPanics.filter(panic => panic.resolved);

                let filteredPanics = [];
                if (id === 'all') {
                    filteredPanics = allPanics;
                } else if (id === 'unresolved') {
                    filteredPanics = unresolvedPanics;
                } else if (id === 'resolved') {
                    filteredPanics = resolvedPanics;
                }

                // Apply search filter
                if (searchTerm) {
                    filteredPanics = filteredPanics.filter(alert =>
                        alert.user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        alert.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        alert.county.county.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                }

                setAlerts(filteredPanics);
            } catch (error) {
                console.error('Error fetching panic data:', error);
            }
        };

        fetchData();
    }, [id, searchTerm]); // Dependency array includes id and searchTerm

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await serverUrl.get(`/officers_management/panic-alert-summary/?franchise_id=${decryptedData}`, config);
                console.log(response);
                setEmergencyContactData(response.data);
                console.log(response.data.results);
            } catch (error) {
                console.error('Error fetching emergency contact data:', error);
            }
        };
    
        fetchData();
    }, []); 
    


    console.log(emergencyContactData)


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    // // Pagination logic
    // const itemsPerPage = 10;
    // const totalPages = Math.ceil(alerts.length / itemsPerPage);
    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = alerts.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleViewClick = (alert) => {
        setSelectedAlert(alert);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedAlert(null);
    };

    console.log(
        selectedAlert
    )

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Maximum of 10 tasks per page
  
    // Calculate index of the first and last item of the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
    // Get the current items to display
    const currentItems = alerts.slice(indexOfFirstItem, indexOfLastItem);
  
    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
    // Calculate total number of pages
    const totalPages = Math.ceil(alerts.length / itemsPerPage);
    return (
        <>
            <SideBar />
            <Header />
            <Container fluid className='panicalert'>

                {alerts.length === 0 ? (
                    <p style={{ fontSize: "25px", marginTop: "10px" }}>No alerts available</p>
                ) : (
                    <>

                        <div className='taskDisplay'>
                            <Form className="searchBar">
                                <FormControl
                                    type="text"
                                    placeholder="Search Alert"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Form>
                            <Table responsive>
                                <thead>
                                    <tr className='table'>
                                        <th>Date</th>
                                        <th>Field Officer</th>
                                        <th>Location</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {currentItems.map((alert) => (
                                        <tr key={alert.id}>
                                            <td>{formatDate(alert.created_at)}</td>
                                            <td>{alert.user.full_name}</td>
                                            <td>{alert.location}, {alert.county.county}</td>
                                            <td>
                                                <span style={{ backgroundColor: alert.resolved ? '#2ecc71' : '#e74c3c', borderRadius: "10px", padding: "10px", color: "white", fontSize: "13px" }}>{alert.resolved ? 'Resolved' : 'Unresolved'}</span>
                                            </td>
                                            <td>
                                                <Button className='btn btn-sm btn-info text-white' onClick={() => handleViewClick(alert)}>
                                                    <i className='bi bi-eye text-white'></i> View
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {alerts.length > 0 && (
              <nav>
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage - 1)}>
                      {'<'}
                    </button>
                  </li>
                  <li className="page-item disabled">
                    <span className="page-link">
                      {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, alerts.length)} of {alerts.length}
                    </span>
                  </li>
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage + 1)}>
                      {'>'}
                    </button>
                  </li>
                </ul>
              </nav>
            )}

                            {/* <Pagination className="mt-3 d-none">
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            {Array.from({ length: totalPages }, (_, index) => (
                                <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                        </Pagination> */}
                        </div>

        
                    </>
                )}

                {selectedAlert && (
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Panic Alert Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormGroup>
                                <Form.Label>Date</Form.Label>
                                <input
                                    className="form-control"
                                    name="full_name"
                                    value={formatDate(selectedAlert.created_at)}
                                    readOnly
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Field Officer</Form.Label>
                                <input
                                    className="form-control"
                                    name="full_name"
                                    value={selectedAlert.user.full_name}
                                    readOnly
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Location</Form.Label>
                                <input
                                    className="form-control"
                                    name="full_name"
                                    value={`${selectedAlert.location}, ${selectedAlert.county.county}`}
                                    readOnly
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Status</Form.Label>
                                <div
                                    className="form-control"
                                    style={{
                                        color: selectedAlert.resolved ? '#2ecc71' : '#e74c3c',
                                        padding: '4px',
                                        borderRadius: '4px',
                                        // color: 'white'
                                    }}
                                    readOnly
                                >
                                    {selectedAlert.resolved ? 'Resolved' : 'Unresolved'}
                                </div>
                            </FormGroup>
                            <h4>Emergency Contacts</h4>
                            <FormGroup>
                                <Form.Label>Relationship</Form.Label>
                                <input
                                    className="form-control"
                                    name="full_name"
                                    // value={emergencyContactData.relationship }
                                    readOnly
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Full Name</Form.Label>
                                <input
                                    className="form-control"
                                    name="full_name"
                                    value={selectedAlert.description}
                                    readOnly
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Phone Number</Form.Label>
                                <input
                                    className="form-control"
                                    name="full_name"
                                    value={selectedAlert.description}
                                    readOnly
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Email</Form.Label>
                                <input
                                    className="form-control"
                                    name="email"
                                    value={selectedAlert.description}
                                    readOnly
                                />
                            </FormGroup>
                            {/* Add other alert details here */}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Container>
        </>
    );
}

export default PanicAlertReports;
