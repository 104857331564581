  import React, { useState } from 'react';
  import '../assets/scss/Login.scss';
  import { Col, Container, Form, FormControl, Image, Row, Button, Spinner, Modal } from 'react-bootstrap';
  import { Link, useNavigate } from 'react-router-dom';
  import { jwtDecode } from "jwt-decode";
  import { serverUrl } from '../serverUrl';
  import { toast } from 'react-toastify';
  import Logo from '../assets/images/usalama_logo.png'
  import Unauthorized from '../assets/images/unauthorized.png'
  import 'react-toastify/dist/ReactToastify.css';
  import CryptoJS from 'crypto-js';


  function Login() {

    const [showPassword, setShowPassword] = useState(false);
    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)
    let [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';

    const [formData, setFormData] = useState({
      phoneNumber: "",
      password: "",
    });
    const yellowHappyFace = '\u{1F600}';

    const togglePasswordVisibility = () => {
      // console.log("visibility")
      setShowPassword(!showPassword);
    };


    const handleFormInput = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const { phoneNumber, password } = formData;
      const loginData = { phone_number: phoneNumber, password };
  
      const res = await serverUrl.post('/user/login/', loginData);
      const { data } = res.data;
      const { tokens, id } = data;
  
      setAuthTokens(tokens);
      setUser(jwtDecode(tokens.access));
      localStorage.setItem('authTokens', JSON.stringify(tokens));
  
      const config = { headers: { Authorization: `Bearer ${tokens.access}` } };
      const franchiseRes = await serverUrl.get(`/officers_management/admins/?user=${id}`, config);
      const franchiseData = franchiseRes.data;
  
      if (franchiseData.count === 1) {
        const franchiseId = franchiseData.results[0].franchise.id.toString();
        const encryptedFranchise = CryptoJS.AES.encrypt(franchiseId, secretKey).toString();
        localStorage.setItem('selectedFranchise', encryptedFranchise);
        toast.success(`Welcome to the tracking system ${yellowHappyFace}`);
        navigate('/dashboard');
      } else if (franchiseData.count === 0) {
        handleShow();
        localStorage.removeItem('authTokens');
      } else {
        navigate('/select-franchise');
      }
    } catch (error) {
      console.error(error);
      handleError(error);
    } finally {
      setLoading(false);
    }
  };
  

    const handleError = (error) => {
      if (!error.response || !error.response.status) {
        toast.error("An error occurred. Please try again later.");
      } else if (error.response.status === 401) {
        toast.error("Sorry, the entered credentials are not correct.");
      } else if (error.response.status === 400) {
        toast.error(`${error.response.data.data.error_type}: ${error.response.data.data.error}`);
      } else {
        toast.error('An error occurred. Please try again.');
      }
    };


    return (
      <div className='signUpPage'>
        <Container fluid>
          <Row>
            <Col md={6} className='sideDisplay'>
              {/* <Image src={#} style={{ width: "35%" }} /> */}
              <p>

                "Welcome to our tracking system. Please log in to access GPS-based location tracking, route planning, expense logging, task planning, safety features, and administrative tools for enhanced supervision and efficiency."
              </p>
            </Col>

          <Col md={6} className='signUp'>
            <div className='form-header'>
              <Image src={Logo} style={{ width: "20%" }} />
              <h3>
                Usalama Field
              </h3>
              <p>Login into your account</p>
            </div>



              <Form className='FormSignUp' >
                <label>Phone Number</label>
                <FormControl
                  type='text'
                  name='phoneNumber'
                  placeholder='Enter Phone Number'
                  value={formData.phoneNumber}
                  onChange={handleFormInput}
                  required
                />


                <label>Password</label>
                <div className="password-field d-flex">
                  <FormControl
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Enter Password'
                    name='password'
                    value={formData.password}
                    onChange={handleFormInput}
                    required
                  />
                  <span className='visibility' style={{ cursor: "pointer" }} onClick={togglePasswordVisibility}>
                    {showPassword ? <i className="bi bi-eye-slash-fill"></i> : <i className="bi bi-eye-fill"></i>}
                  </span>
                </div>

                <Link className='text-dark mb-3'>
                  <span className='text-dark fw-bold float-end d-flex forgot' style={{ position: "relative", right: "100px" }}>Forgot password</span>

                </Link>

                <br></br>
                {loading ?

                  <Button className='btn btn-sm btn-danger text-white text-upperase w-75 ms-2' ><Spinner /></Button>

                  :
                  <Button className='btn btn-sm btn-danger text-white text-upperase w-75 ms-2' type='submit' onClick={handleLogin}>Login</Button>

                }

              </Form>
            </Col>
          </Row>


          {/* show the modal for not admin people */}
          <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Unauthorized</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ position: "relative", textAlign: "center" }}>
                <Image src={Unauthorized} style={{ width: "15%" }} />

              </div>
              <p className='text-center'>You are unauthorized to access this site</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="dark" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }

  export default Login;
