import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import { Container, Form, FormControl, Button, Table, Card } from 'react-bootstrap';
import '../assets/scss/PanicAlertReport.scss';
import { serverUrl } from '../serverUrl';

function WalletTransactions() {
  const { id } = useParams();
  const [alerts, setAlerts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  const [walletBalance, setWalletBalance] = useState('')
  const [selectedWallet, setSelectedWallet] = useState({})


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await serverUrl.get(`/officers_management/wallet-transactions/?wallet=${id}`, config);
        const allPanics = response.data.results;
        serverUrl.get(`/officers_management/wallet-account-balance?wallet=${id}`, config)
          .then((res) => {
            setWalletBalance(res.data.results[0])
          })
          .catch((error) => {
            console.log(error)
          })

        //   the wallet selected
        serverUrl.get(`/officers_management/wallet/${id}/`, config)
          .then((res) => {
            setSelectedWallet(res.data)
          })
          .catch((error) => {
            console.log(error)
          })

        let filteredPanics = [];
        filteredPanics = allPanics;


        // Apply search filter
        console.log(filteredPanics)
        if (searchTerm) {
          filteredPanics = filteredPanics.filter(alert =>
            alert.recepient_phone_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
            alert.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
            alert.transcation_type.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }

        setAlerts(filteredPanics);
      } catch (error) {
        console.error('Error fetching panic data:', error);
      }
    };

    fetchData();
  }, [id, searchTerm]); // Dependency array includes id and searchTerm

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  // Pagination logic
  const itemsPerPage = 10;
  const totalPages = Math.ceil(alerts.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = alerts.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // show balance
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  //   show account
  const [showBalance, setShowBalance] = useState(false);

  const handleToggleBalanace = () => {
    setShowBalance((prevShowBalance) => !prevShowBalance);
  };

  return (
    <>
      <SideBar />
      <Header />
      <Container fluid className='panicalert'>

        {/* accout details */}

        <div className='account_details'>
          <div className='d-flex'>
            <Card>
              <Card.Body>
                <p className='Balance text-uppercase fs-5 fw-bold'>Account Balance</p>
                <div className="input-icons">
                  <i style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
                    {showPassword ? <i className="bi bi-eye icon"></i>
                      : <i className="bi bi-eye-slash icon"></i>

                    }
                  </i>
                  <input
                    className="input-field"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={`Ksh. ${walletBalance.wallet_balance?.toLocaleString()}`}
                  />

                </div>

              </Card.Body>
            </Card>

          </div>
          <div className='d-flex'>
            <Card>
              <Card.Body>
                <p className='text-warning text-uppercase fs-5 fw-bold'>Account Number: </p>
                <div className="input-icons">
                  <i style={{ cursor: "pointer" }} onClick={handleToggleBalanace}>
                    {showBalance ? <i className="bi bi-eye icon"></i>
                      : <i className="bi bi-eye-slash icon"></i>

                    }
                  </i>
                  <input
                    className="input-field"
                    type={showBalance ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={selectedWallet.account_number}
                  />

                </div>


              </Card.Body>
            </Card>
          </div>
        </div>

        <div className='d-flex justify-content-between topFiltersData'>
          <Form className="searchBar mt-2">
            <FormControl
              type="text"
              placeholder="Search Transaction"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form>
        </div>

        {alerts.length === 0 ? (
          <p style={{ fontSize: "25px", marginTop: "10px" }}>No transactions available</p>
        ) : (
          <>
            <Table responsive>
              <thead>
                <tr className='table'>
                  <th>Date</th>
                  <th>Recepient Name</th>
                  <th>Reason</th>
                  <th>Transaction Type</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Balance</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((alert) => (
                  <tr key={alert.id}>
                    <td>{formatDate(alert.created_at)}</td>
                    <td>{alert.recepient_name}</td>
                    <td>{alert.reason}</td>
                    <td>{alert.transcation_type}</td> 
                    <td>Ksh. {alert.amount}</td> 
                    <td className={`${alert.status === 'pending' ? 'text-warning':'text-success'}`}>{alert.status}</td>
                    <td>Ksh. {alert.current_balance}</td> 


                    <td>
                      <Button className='btn btn-sm btn-primary text-white'>
                        <i className='bi bi-eye'></i>
                        View

                      </Button>


                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div>
              
              {Array.from({ length: totalPages }, (_, index) => (
                <Button key={index} onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </Button>
              ))}
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default WalletTransactions;
