import React, { useState, useEffect } from 'react';
import { useBuyAirtime } from '../hooks/useBuyAirtime';
import { Button, Table, Pagination, Form, Modal, Tabs, Tab } from 'react-bootstrap';
import '../assets/scss/AirtimeTrasnactions.scss'
import { BarChart } from '@mui/x-charts/BarChart';
import Chart from 'react-apexcharts';



function AirtimeTransactions() {
    const { airtimeTransactions, airtimeTransactionsCount } = useBuyAirtime();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [filterYear, setFilterYear] = useState('');
    const [filterMonth, setFilterMonth] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [key, setKey] = useState('table');

    useEffect(() => {
        if (airtimeTransactions) {
            filterAndSearch();
        }
    }, [searchTerm, filterYear, filterMonth, filterDate, airtimeTransactions]);

    const filterAndSearch = () => {
        let transactions = airtimeTransactions || [];
        transactions.forEach(transaction => {
            const createdAtDate = new Date(transaction.created_at);
            const year = createdAtDate.getFullYear();
        });

        if (filterYear) {
            transactions = transactions.filter(transaction =>
                new Date(transaction.created_at).getFullYear().toString() === filterYear
            );
        }

        if (filterMonth) {
            transactions = transactions.filter(transaction => {
                const date = new Date(transaction.created_at);
                return date.getFullYear().toString() === filterYear && (date.getMonth() + 1).toString() === filterMonth;
            });
        }

        if (filterDate) {
            transactions = transactions.filter(transaction =>
                new Date(transaction.created_at).toISOString().split('T')[0] === filterDate
            );
        }

        if (searchTerm) {
            transactions = transactions.filter(transaction => {
                const term = searchTerm.toLowerCase();
                return (
                    (transaction.airtime_beneficiary_name && transaction.airtime_beneficiary_name.toLowerCase().includes(term)) ||
                    (transaction.airtime_beneficiary && transaction.airtime_beneficiary.toLowerCase().includes(term)) ||
                    (transaction.admin && transaction.admin.user && transaction.admin.user.full_name.toLowerCase().includes(term)) ||
                    (transaction.status && transaction.status.toLowerCase().includes(term))
                );
            });
        }


        setFilteredTransactions(transactions);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(filteredTransactions.length / itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        return (
            <Pagination>
                {pageNumbers.map(number => (
                    <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                        {number}
                    </Pagination.Item>
                ))}
            </Pagination>
        );
    };

    const indexOfLastTransaction = currentPage * itemsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
    const currentTransactions = filteredTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

    const aggregateDataByMonth = (year) => {
        const months = Array.from({ length: 12 }, (_, i) => ({
            month: new Date(0, i).toLocaleString('en', { month: 'long' }),
            total: 0
        }));

        currentTransactions.map((transaction) => {
            const date = new Date(transaction.created_at);
            if (date.getFullYear() === year) {
                const monthIndex = date.getMonth();
                // convvert the amount to interger
                const amount = parseInt(transaction.amount);

                if (typeof amount === 'number') {
                    months[monthIndex].total += amount;
                } else {
                    console.warn('Transaction amount is not a number:', transaction.amount);
                }
            }
        });

        return months;
    };



    const barData = filterYear ? aggregateDataByMonth(filterYear) : aggregateDataByMonth(2024);


    const chartOptions = {
        chart: {
            type: 'bar',
        },
        xaxis: {
            categories: barData.map(item => item.month),
        },
        title: {
            text: `Airtime Transactions`,
            align: 'center'
        },
        dataLabels: {
            enabled: true,
        },
        plotOptions: {
            bar: {
                borderRadius: 20, 

            },
        },
        colors: ['#E82949'],
        grid: {
            show: false, 
            padding: {
              top: 0,
              right: 10,
              bottom: 0,
              left: 15
            },
          }
    };

    const chartSeries = [
        {
            name: 'Total Amount',
            data: barData.map(item => item.total),
        }
    ];
    const startYear = 2020;
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);



    return (
        <div className='airtimeTransactions'>
            <div className='taskDisplay'>
                <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab eventKey="table" title="Table View">
                        {airtimeTransactions && airtimeTransactions.length > 0 && (
                            <div className='d-flex justify-content-between mb-0 mt-2'>
                                <Form className='d-flex justify-content-between'>
                                    <Form.Group controlId="search">
                                        <Form.Control
                                            type="text"
                                            placeholder="Search for transaction"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form>
                                {filterYear || filterMonth || filterDate ?

                                    <Button className='btn btn-sm bg-danger border-danger text-white' style={{ borderRadius: "20px", height: "6vh" }} onClick={() => { setFilterYear(''); setFilterMonth(''); setFilterDate(''); filterAndSearch(); }}>
                                        Reset Filter
                                    </Button>
                                    :
                                    <Button className='btn btn-sm bg-transparent border-danger text-danger' style={{ borderRadius: "20px", height: "6vh" }} onClick={() => setShowModal(true)}>
                                        Filter Transactions
                                    </Button>
                                }

                            </div>
                        )}
                        <Table responsive>
                            <thead>
                                <tr className="table">
                                    <th>Amount</th>
                                    <th>Airtime Beneficiary Phone</th>
                                    <th>Airtime Beneficiary Name</th>
                                    <th>Admin</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentTransactions && currentTransactions.length === 0 ?
                                    <tr>
                                        <td colSpan="5" className="text-center">No airtime transactions</td>
                                    </tr>
                                    :
                                    currentTransactions.map((wallet) => (
                                        <tr key={wallet.id}>
                                            <td>Ksh. {wallet.amount && wallet.amount.toLocaleString('en-us')}</td>
                                            <td>{wallet.airtime_beneficiary.startsWith('+254') ? wallet.airtime_beneficiary.replace('+254', '0') : wallet.airtime_beneficiary}</td>
                                            <td>{wallet.airtime_beneficiary_name}</td>
                                            <td>{wallet.admin.user.full_name}</td>
                                            <td>
                                                <span className='p-2 bg-success text-white' style={{ borderRadius: "20px", fontSize: "12px" }}>Success</span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        {renderPagination()}
                    </Tab>
                    <Tab eventKey="graph" title="Graphical View">
                        <Form.Group controlId="filterYear" className='mb-2 text-start' style={{ width: "10%" }}>
                            <Form.Control
                                as="select"
                                placeholder='Filter by year'
                                value={filterYear}
                                onChange={(e) => setFilterYear(e.target.value)}
                            >
                                {airtimeTransactions && [...new Set(airtimeTransactions.map(transaction => new Date(transaction.created_at).getFullYear()))]
                                    .map(year => <option key={year} value={year}>{year}</option>)}
                            </Form.Control>
                        </Form.Group>
                        {/* <BarChart
                            series={[
                                {
                                    data: barData.map(({ month, total }) => ({ total })),
                                },
                            ]}
                            height={290}
                            xAxis={[{ data: barData.map(({ month }) => month), scaleType: 'band' }]}
                            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                        /> */}

                        <Chart
                            options={chartOptions}
                            series={chartSeries}
                            type="bar"
                            height={350}
                        />

                    </Tab>
                </Tabs>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Filter Transactions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="filterYear">
                            <Form.Label>Filter by Year</Form.Label>
                            <Form.Control
                                as="select"
                                placeholder='Filter by year'
                                value={filterYear}
                                onChange={(e) => setFilterYear(e.target.value)}
                            >
                                <option value="">Select Year</option>
                                {years.map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="filterMonth">
                            <Form.Label>Filter by Month</Form.Label>
                            <Form.Control
                                as="select"
                                placeholder='Filter by month'
                                value={filterMonth}
                                onChange={(e) => setFilterMonth(e.target.value)}
                                disabled={!filterYear}
                            >
                                <option value="">Select Month</option>
                                {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (

                                    <option key={month} value={month}>{new Date(0, month - 1).toLocaleString('en', { month: 'long' })}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="filterDate">
                            <Form.Label>Filter by Date</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder='Filter by date'
                                value={filterDate}
                                onChange={(e) => setFilterDate(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='btn btn-sm text-white' onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    {/* <Button variant="info" className='btn btn-sm text-white' onClick={() => { filterAndSearch(); setShowModal(false); }}>
                        Apply Filters
                    </Button> */}

                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AirtimeTransactions;
