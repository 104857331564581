// import logo from './logo.svg';
import './App.css';
import WebRoutes from './routes/WebRoutes';
import { ToastContainer, toast } from 'react-toastify';

function App() {
  return (
    <div className="App">
      <WebRoutes />
      <ToastContainer />


    </div>
  );
}

export default App;
