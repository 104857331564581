import React, { useEffect, useState } from 'react';
import { Map, Marker, GoogleApiWrapper, InfoWindow, Polyline } from 'google-maps-react';
import { serverUrl } from '../serverUrl';
import { CardBody, Image, Card, Button } from 'react-bootstrap';
import { renderToString } from 'react-dom/server';


const MapComponent = ({ google, data }) => {
    const [mapCenter, setMapCenter] = useState({ lat: -1.2921, lng: 36.8219 });
    const [zoomLevel, setZoomLevel] = useState(13);
    const [markers, setMarkers] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [selectedMarkerData, setSelectedMarkerData] = useState(null);
    const [isLoading, setIsloading] = useState(false)

    useEffect(() => {

        setMapCenter({ lat: -1.2921, lng: 36.8219 });
        setZoomLevel(8);
    }, []);

    const convertImageToBase64 = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    useEffect(() => {
        if (data.length > 0) {
            const displayMarkers = async () => {
                const newMarkers = await Promise.all(data.map(async (item) => {
                    let profileData = item.ro_profile;
                    let ro_activities = item.activities
                    if (item.ro_profile.length > 10) {
                        profileData = await convertImageToBase64(item.ro_profile);
                    }
                    return {
                        position: { lat: item.latitude, lng: item.longitude },
                        profile: profileData,
                        time: item.duration,
                        ro_activity: ro_activities,
                        backgroundColor: item.color,
                        location_id: item.id,
                        data: item
                    };
                }));
                setIsloading(false)

                setMarkers(newMarkers);
            };

            displayMarkers();

            const validMarkers = data.map(item => {
                const lat = parseFloat(item.latitude);
                const lng = parseFloat(item.longitude);
                return {
                    position: { lat, lng },
                    data: item
                };
            }).filter(marker => !isNaN(marker.position.lat) && !isNaN(marker.position.lng));


            // Center map based on average latitude and longitude
            const avgLat = validMarkers.reduce((sum, marker) => sum + marker.position.lat, 0) / validMarkers.length;
            const avgLng = validMarkers.reduce((sum, marker) => sum + marker.position.lng, 0) / validMarkers.length;

            setMapCenter({ lat: avgLat, lng: avgLng });
            setZoomLevel(validMarkers.length === 1 ? 12 : 9);
        }
    }, [data, google.maps.LatLngBounds]);




    const CustomLabelSVG = ({ text, backgroundColor, profile }) => (
        <svg width="60" height="60" viewBox="0 0 60 70" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="60" height="60" fill={backgroundColor} rx="10" />
            {profile.length > 3 ? (
                <image href={profile} width="40" height="40" x="10" y="10" alt='profile' />
            ) : (
                <>
                    <rect x="10" y="7" width="40" height="40" fill={text} rx="35" ry="35" />
                    <text
                        x="30"
                        y="30"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        fontSize="19px"
                        fontWeight="bold"
                        fill="white"
                        fontFamily="Arial"
                    >
                        {profile}
                    </text>
                </>
            )}
            <polygon
                points="35,50 50,70 65,50"
                fill={backgroundColor}
            />
        </svg>
    );


    const onMarkerClick = (props, marker, e, markerData) => {
        setActiveMarker(marker);
        setSelectedMarkerData(markerData);
        setShowInfoWindow(true);

        if (markerData.ro_notification === true) {
            // change the notifications to seen
            const accessToken = localStorage.getItem('authTokens');
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;
            const config = {
                headers: { Authorization: `Bearer ${access}` },
            };
            serverUrl.get(`/officers_management/flags-activity/?relationship_officer=${markerData.ro_id}`, config)
                .then(response => {
                    const data = { read_status: true };
                    // Loop through the results and update each with the read_status to true
                    response.data.results.forEach(element => {
                        serverUrl.patch(`/officers_management/flags-activity/${element.id}/`, data, config)
                            .then(response => { })
                            .catch(error => { console.log(error) });
                    });
                })
                .catch(error => { console.log(error); });
        }
    };


    const onCloseInfoWindow = () => {
        setShowInfoWindow(false);
        setActiveMarker(null);
    };

    const onMapClicked = () => {
        if (showInfoWindow) {
            setShowInfoWindow(false);
            setActiveMarker(null);
        }
    };

    const formatString = (str) => {
        // Split the string by underscore
        const words = str.split('_');

        // Capitalize the first letter of each word and join them with a space
        const formattedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return formattedString;
    };
    const formatCount = (count) => {
        return count ? parseInt(count).toLocaleString() : 0;
    };

    const renderInfoWindowContent = () => {
        if (selectedMarkerData) {
            return (
                <>
                    {/* display the field officer data */}
                    <Card className='border-0'>
                        <CardBody>
                            {/* display the details of the field officer */}
                            <div className='d-flex' style={{ fontFamily: "'Axiforma', sans-serif !important" }}>
                                {/* display initials if no image */}
                                <div>
                                    {selectedMarkerData.ro_profile.length > 3 ? (
                                        <Image className='infoWindowImage' src={selectedMarkerData.ro_profile} />
                                    ) : (
                                        <div className='infoWindowImage' style={{ backgroundColor: selectedMarkerData.color }}>
                                            <p className='infoWindowImageText' style={{ fontFamily: "'Axiforma', sans-serif !important" }}>{selectedMarkerData.ro_profile}</p>
                                        </div>
                                    )}
                                    {selectedMarkerData.ro_offline ? (
                                        <>
                                            <p className='offlineStatus'></p>
                                            <span className='btn bg-secondary text-white displayOfflineOnline' style={{ borderRadius: "15px", fontSize: "10px" }}>Offline</span>
                                        </>
                                    ) : (
                                        <>
                                            <p className='onlineStaus'></p>
                                            <span className='p-1 bg-success text-white displayOfflineOnline' style={{ borderRadius: "15px", fontSize: "10px" }}>Online</span>
                                        </>

                                    )}
                                </div>

                                <div className='d-block text-start' style={{ fontFamily: "'Axiforma', sans-serif !important" }}>
                                    <h5 style={{ fontFamily: "'Axiforma', sans-serif !important" }}>{selectedMarkerData.ro_name}</h5>
                                    <p style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }} className='text-info' >
                                        <i className="bi bi-telephone-fill me-1 fw-bold"></i>{selectedMarkerData.ro_phone}
                                    </p>
                                    {selectedMarkerData.ro_location ?
                                        < p style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }} className='text-primary'>
                                            <i className="bi bi-geo-alt-fill me-1"></i>{selectedMarkerData.ro_location}
                                        </p> :
                                        <p style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }} className='text-primary'>
                                            <i className="bi bi-geo-alt-fill me-1"></i>Not Available
                                        </p>
                                    }

                                    <p style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }} className='text-primary'>
                                        <i class="bi bi-battery-full me-1"></i>{selectedMarkerData.battery_percentage} %
                                    </p>

                                    <div className='d-flex' style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }} >
                                        <span style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }} className='text-dark fw-bold me-2'>Status</span>
                                        <Button
                                            style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }}
                                            className={`btn border-0 btn-sm text-white safetySpan ${selectedMarkerData.ro_safety_status === 'Safe' ? 'bg-success' : 'btn-danger'}`}
                                        >
                                            {selectedMarkerData.ro_safety_status}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {/* check the pending notifications */}
                            {selectedMarkerData.ro_notification ?
                                <>
                                    <p className='text-dark text-start' style={{ marginTop: "10px", marginBottom: "0px", fontFamily: "'Axiforma', sans-serif !important" }}><b>Unread Notification(s)</b></p>
                                    <div className='d-flex'>
                                        {selectedMarkerData.pending_notifications.Overstaying > 0 && (
                                            <p style={{ marginTop: "5px", marginBottom: "0px", color: "#8e44ad", fontFamily: "'Axiforma', sans-serif !important" }} className='me-1'>
                                                Overstaying <span style={{ borderRadius: "2px", marginBottom: "0px", backgroundColor: "#8e44ad" }} className='p-1 text-white'>
                                                    {formatCount(selectedMarkerData.pending_notifications.Overstaying)}
                                                </span>
                                            </p>
                                        )}
                                        {selectedMarkerData.pending_notifications.offline_status > 0 && (
                                            <p style={{ marginTop: "5px", marginBottom: "0px", fontFamily: "'Axiforma', sans-serif !important" }} className='text-warning me-1'>
                                                Offline <span style={{ borderRadius: "2px", marginBottom: "0px" }} className='p-1 bg-warning text-white'>
                                                    {formatCount(selectedMarkerData.pending_notifications.offline_status)}
                                                </span>
                                            </p>
                                        )}
                                        {selectedMarkerData.pending_notifications.restricted_region > 0 && (
                                            <></>
                                            // <p style={{ marginTop: "5px", marginBottom: "0px", color: "#27ae60",fontFamily: "'Axiforma', sans-serif !important" }} className=''>
                                            //     Restricted <span style={{ borderRadius: "2px", marginBottom: "0px", backgroundColor: "#27ae60" }} className='p-1  text-white'>
                                            //         {formatCount(selectedMarkerData.pending_notifications.restricted_region)}
                                            //     </span>
                                            // </p>
                                        )}
                                    </div>
                                    <p className='text-start' style={{ marginTop: "0", marginBottom: "0px", fontFamily: "'Axiforma', sans-serif !important" }}> <a href='/#/flags'>View unread notification(s)</a>  </p>

                                </>
                                :
                                <></>
                            }

                            {/* current task status */}
                            {selectedMarkerData.task.task_status ? (
                                <div className='d-block text-dark text-start' style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }}>
                                    <h5 className='text-decoration-underline mt-1' style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }}>Current Task</h5>

                                    <p className='text-dark' style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }}><b>Task Type</b>: <span>{formatString(selectedMarkerData.task.task)}</span></p>
                                    <p style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }}><b>Task Location</b>: <span>{selectedMarkerData.task.location}</span></p>
                                    {selectedMarkerData.task.task === 'debt_collection' ? (
                                        <p style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }}><b>Task Estimated Amount</b>: <span>KSH {parseInt(selectedMarkerData.task.amount)?.toLocaleString()}</span></p>
                                    ) : null}
                                </div>
                            ) :
                                // <h5 className='text-start' style={{ fontSize: "13px" }}>No ongoing task at the moment</h5>
                                <></>

                            }

                            <div className='d-block mt-2'>
                                <h5 className='text-start' style={{ fontSize: "13px", fontFamily: "'Axiforma', sans-serif !important" }}>Task Status:{selectedMarkerData.task.task_status ? <></> : <>No ongoing task</>}</h5>
                                <div className='d-block text-start' style={{ marginBottom: "5px", fontFamily: "'Axiforma', sans-serif !important" }}>
                                    <p className='text-danger mb-1'>Pending <span style={{ borderRadius: "2px", fontSize: "8px", fontFamily: "'Axiforma', sans-serif !important" }} className='p-1 bg-danger text-white'>{selectedMarkerData.task.pending_tasks}</span></p>
                                    <p className='text-success mb-1'>Completed <span style={{ borderRadius: "2px", fontSize: "8px", fontFamily: "'Axiforma', sans-serif !important" }} className='p-1 bg-success text-white'>{selectedMarkerData.task.completed_tasks}</span></p>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <h5 style={{ fontSize: "13px", fontFamily: "'Axiforma', sans-serif !important" }}>Last updated:</h5>
                                <p className='ms-1' style={{ fontFamily: "'Axiforma', sans-serif !important" }}> {selectedMarkerData.formatted_timestamp}</p>
                            </div>
                            <div className='d-flex mb-0'>
                                <h5 style={{ fontSize: "13px", fontFamily: "'Axiforma', sans-serif !important" }}>Location duration:</h5>
                                <p style={{ backgroundColor: "#ffbd3c", color: "white", borderRadius: "8px", paddingInline: "10px", marginLeft: "10px", fontFamily: "'Axiforma', sans-serif !important" }}>{selectedMarkerData.duration}</p>
                            </div>


                        </CardBody>
                    </Card >
                </>
            );
        }
        return null;
    };
    let displayBackgroundColor = 'white'

    const markerBackgroundColors = data.map(loop => {
        if (loop && loop.ro_notification === true) {
            return '#E82949';
        } else if (loop && loop.ro_offline === true) {
            return 'lightgray';
        } else if (loop && loop.ro_offline === false) {
            return 'white';

        }
    });
    const markersNew = [
        {
            position: { lat: -1.2852894, lng: 36.7337991 },
            backgroundColor: 'red',
            profile: 'profile1',
            ro_activity: [
                { latitude: '-1.2852894', longitude: '36.7337991' },
                { latitude: '-1.2921', longitude: '36.8219' }
            ]
        },
        {
            position: { lat: -1.252837, lng: 36.666679 },
            backgroundColor: 'blue',
            profile: 'profile2',
            ro_activity: [
                { latitude: '-1.252837', longitude: '36.666679' },
                { latitude: '-1.2600', longitude: '36.6800' }
            ]
        },
        {
            position: { lat: -0.3290351, lng: 36.666679 },
            backgroundColor: 'green',
            profile: 'profile3',
            ro_activity: [
                { latitude: '-0.3290351', longitude: '36.666679' },
                { latitude: '-0.3400', longitude: '36.6900' }
            ]
        }
    ];
    const renderMarker = (marker, index) => {
        if (marker.ro_activity) {
            const activityPositions = marker.ro_activity.map((activity) => ({
                lat: parseFloat(activity.latitude),
                lng: parseFloat(activity.longitude),
            }));

            if (activityPositions.length === 0) return null;

            const firstActivityPosition = activityPositions[0];
            const isAtStartPosition =
                marker.position.lat === firstActivityPosition.lat &&
                marker.position.lng === firstActivityPosition.lng;

            console.log(`Activity positions for marker ${index}`, activityPositions);
            console.log(`Is at start position for marker ${index}`, isAtStartPosition);

            return (
                <React.Fragment key={index}>
                    {!isAtStartPosition && (
                        <>
                            <Marker
                                key={`start-marker-${index}`}
                                position={firstActivityPosition}
                                icon={null} // Use the default Google Maps marker
                            />
                            {console.log(`Rendering start marker for marker ${index}`, firstActivityPosition)}
                        </>
                    )}

                    <Marker
                        key={`current-marker-${index}`}
                        position={marker.position}
                        icon={{
                            url: `data:image/svg+xml,${encodeURIComponent(
                                renderToString(
                                    <CustomLabelSVG
                                        backgroundColor={marker.backgroundColor}
                                        profile={marker.profile}
                                        text={marker.backgroundColor}
                                    />
                                )
                            )}`,
                            scaledSize: new window.google.maps.Size(60, 70),
                        }}
                        onClick={(props, marker, e) => onMarkerClick(props, marker, e, marker.data)}
                    />
                    {console.log(`Rendering current marker for marker ${index}`, marker.position)}

                    {!isAtStartPosition && (
                        <Polyline
                            key={`polyline-${index}`}
                            path={[...activityPositions, marker.position]}
                            strokeColor="#FF0000"
                            strokeOpacity={0.8}
                            strokeWeight={2}
                        />
                    )}
                </React.Fragment>
            );
        } else {
            return (
                <Marker
                    key={index}
                    position={marker.position}
                    icon={{
                        url: `data:image/svg+xml,${encodeURIComponent(
                            renderToString(
                                <CustomLabelSVG
                                    backgroundColor={marker.backgroundColor}
                                    profile={marker.profile}
                                    text={marker.backgroundColor}
                                />
                            )
                        )}`,
                        scaledSize: new window.google.maps.Size(60, 70),
                    }}
                    onClick={(props, marker, e) => onMarkerClick(props, marker, e, marker.data)}
                />
            );
        }
    };

    if (isLoading) {
        return (
            <div className='loading-screen'>
                <div className='logo'>

                </div>
            </div>
        )
    }
    return (
        <div style={{ height: '100vh', width: '100%' }}>
            {!isLoading && (
                <Map
                    google={google}
                    center={mapCenter}
                    className='position-relative mapHomePage'
                    zoom={zoomLevel}
                    onClick={onMapClicked}
                    style={{ height: '80vh', width: '100%', position: 'relative' }}
                >
                    {/* {markersNew.map((marker, index) => renderMarker(marker, index))} */}
               
                    {markers.map((marker, index) => {
                        const displayBackgroundColor = markerBackgroundColors[index];
                        return (
                            <Marker
                                key={index}
                                position={marker.position}
                                icon={{
                                    url: `data:image/svg+xml,${encodeURIComponent(renderToString(<CustomLabelSVG backgroundColor={displayBackgroundColor} profile={marker.profile} text={marker.backgroundColor} />))}`,
                                    scaledSize: new window.google.maps.Size(60, 70),
                                }}
                                onClick={(props, marker, e) => onMarkerClick(props, marker, e, markers[index].data)}
                            />
                        );

                        // if (marker.ro_activity) {
                        //     // Loop through the activity and get the positions
                        //     const activityPositions = marker.ro_activity.map((activity) => ({
                        //         lat: activity.latitude,
                        //         lng: activity.longitude
                        //     }));
                        //     // Ensure activityPositions is not empty
                        //     if (activityPositions.length === 0) return null;

                        //     // Get the first activity position
                        //     const firstActivityPosition = activityPositions[0];

                        //     // Determine if the current marker position is the same as the first activity position
                        //     const isAtStartPosition =
                        //         marker.position.lat === firstActivityPosition.lat &&
                        //         marker.position.lng === firstActivityPosition.lng;

                        //     // Render the polylines for the directions taken
                        //     const polylines = (
                        //         <Polyline
                        //             key={`polyline-${index}`}
                        //             path={[...activityPositions, marker.position]}
                        //             strokeColor="#FF0000"
                        //             strokeOpacity={0.8}
                        //             strokeWeight={2}
                        //         />
                        //     );
                        //     return (
                        //         <React.Fragment key={index}>
                        //             {!isAtStartPosition && (
                        //                 <>
                        //                     <Marker
                        //                         key={`start-marker-${index}`}
                        //                         position={firstActivityPosition}
                        //                     />
                        //                 </>
                        //             )}

                        //             {isAtStartPosition ? (
                        //                 <>
                        //                     <Marker
                        //                         key={`current-marker-${index}`}
                        //                         position={marker.position}
                        //                         icon={{
                        //                             url: `data:image/svg+xml,${encodeURIComponent(renderToString(<CustomLabelSVG backgroundColor={marker.backgroundColor} profile={marker.profile} text={marker.backgroundColor} />))}`,
                        //                             scaledSize: new window.google.maps.Size(60, 70),
                        //                         }}
                        //                         onClick={(props, marker, e) => onMarkerClick(props, marker, e, marker.data)}
                        //                     />
                        //                     {console.log(isAtStartPosition)}

                        //                 </>
                        //             ) : (
                        //                 <>
                        //                     {polylines}
                        //                     <Marker
                        //                         key={`current-marker-${index}`}
                        //                         position={marker.position}
                        //                         icon={{
                        //                             url: `data:image/svg+xml,${encodeURIComponent(renderToString(<CustomLabelSVG backgroundColor={marker.backgroundColor} profile={marker.profile} text={marker.backgroundColor} />))}`,
                        //                             scaledSize: new window.google.maps.Size(60, 70),
                        //                         }}
                        //                         onClick={(props, marker, e) => onMarkerClick(props, marker, e, marker.data)}
                        //                     />
                        //                 </>
                        //             )}
                        //         </React.Fragment>
                        //     );
                        // } else {
                        //     return (
                        //         <Marker
                        //             key={index}
                        //             position={marker.position}
                        //             icon={{
                        //                 url: `data:image/svg+xml,${encodeURIComponent(renderToString(<CustomLabelSVG backgroundColor={marker.backgroundColor} profile={marker.profile} text={marker.backgroundColor} />))}`,
                        //                 scaledSize: new window.google.maps.Size(60, 70),
                        //             }}
                        //             onClick={(props, marker, e) => onMarkerClick(props, marker, e, marker.data)}
                        //         />
                        //     );
                        // }

                      
                        

                          
                          
                          









                        //   previous correct working marker

                        // return (
                        //     <Marker
                        //         key={index}
                        //         position={marker.position}
                        //         icon={{
                        //             url: `data:image/svg+xml,${encodeURIComponent(renderToString(<CustomLabelSVG backgroundColor={displayBackgroundColor} profile={marker.profile} text={marker.backgroundColor} />))}`,
                        //             scaledSize: new window.google.maps.Size(60, 70),
                        //         }}
                        //         onClick={(props, marker, e) => onMarkerClick(props, marker, e, markers[index].data)}
                        //     />
                        // );





                    })} 






                    <InfoWindow
                        marker={activeMarker}
                        visible={showInfoWindow}
                        onClose={onCloseInfoWindow}
                    >
                        <div>
                            {renderInfoWindowContent()}
                        </div>
                    </InfoWindow>
                </Map>
            )}
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDxKuaBgR44-dZ-vZGGWY4YnL4NPEEuri0',
})(MapComponent);



