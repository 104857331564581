import React, { useState, useEffect } from 'react';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import { serverUrl } from '../serverUrl';
import { Col, Container, Row, Button, FormControl, Image, FormSelect, Form, Card, Modal, Offcanvas, } from 'react-bootstrap';
import '../assets/scss/Dashboard.scss';
import '../assets/scss/LoaderDisplay.scss';
import { jwtDecode } from 'jwt-decode';
import MapComponent from '../components/MapComponent';
import MapComponentUser from '../components/MapComponentUser';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';


function Dashboard() {
  const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
  const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedDateOption, setSelectedDateOption] = useState(null);
  const [dateDuration, setDateDuration] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectTask, setSelectTask] = useState(null);
  const [roData, setROData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [officers, setOfficers] = useState([]);
  const [selectedOfficer, setSelectedOfficer] = useState(null);
  const [isLoading, setIsloading] = useState(false)
  const [showOfficersList, setShowOfficersList] = useState(false);
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const handleFilterModalOpen = () => {
    setShowFilterModal(true);
  };

  const handleFilterModalClose = () => {
    setShowFilterModal(false);
  };

  const handleDateOptionSelect = (option) => {
    setSelectedDateOption(option);
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedDate(null);
  };

  const handledurationDate = (date) => {
    setDateDuration(date);
  };

  const handleselectTask = (task) => {
    setSelectTask(task);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  const handleYearSelect = (option) => {
    setSelectedYear(option);
  };

  const handleMonthSelect = (option) => {
    setSelectedMonth(option);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };

  const decodedToken = jwtDecode(accessToken);
  const userId = decodedToken.user_id;
  const [franchiseSelected, setFranchise] = useState('');

  useEffect(() => {
    const selectedFranchise = localStorage.getItem('selectedFranchise');
    if (selectedFranchise) {
      const decryptedData = decryptData(selectedFranchise, secretKey);
      setFranchise(decryptedData);
    }
  }, []);


  const handleOfficerSelect = (index) => {
    const updatedOfficers = officers.map((officer) => {
      if (officer.id === index) {
        return { ...officer, selected: true };
      } else {
        return { ...officer, selected: false };
      }
    });


    setOfficers(updatedOfficers);

    const selectedOfficer = updatedOfficers.find((officer) => officer.selected);
    setSelectedOfficer(selectedOfficer || null);
    setShowOfficersList(false);

  };

  const handleCheckboxChange = (event, index) => {
    event.stopPropagation();
    // handleOfficerSelect(index);

  };
  const [optionList, setOptionList] = useState([]);
  const [relationshipList, setRelationshipList] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      try {
        const selectedFranchise = localStorage.getItem('selectedFranchise');
        const decryptedData = decryptData(selectedFranchise, secretKey);

        const response = await serverUrl.get(`/officers_management/relationship-officer/?franchise=${decryptedData}`, config);
        setROData(response.data.results);
        const optionsFieldOfficer = [
          { value: "all", label: "All Field Officers" },
          ...response.data.results.map(fieldOfficer => ({
            value: fieldOfficer.id,
            label: fieldOfficer.user.full_name
          }))
        ];
        setRelationshipList(optionsFieldOfficer);


        const responseSubCountiies = await serverUrl.get(`/officers_management/get-franchise-sub-counties?franchise_id=${decryptedData}`, config);
        const options = responseSubCountiies.data[0].results.map(subcounty => ({
          value: subcounty.id,
          label: subcounty.name
        }));
        setOptionList(options);

        // get the map data 
        if (!selectedOfficer) {
          const mapResponse = await serverUrl.get(`/officers_management/ro-location?franchise_id=${decryptedData}`, config);
          setMapData(mapResponse.data);
        }
        else if (selectedOfficer) {
          const mapResponse = await serverUrl.get(`/officers_management/ro-location?relationship_officer=${selectedOfficer.id}`, config);
          setMapData(mapResponse.data);
        }
        setIsloading(false)


      } catch (error) {
        console.error('Error fetching data:', error);
        setIsloading(false)

      }
    };

    fetchData();
  }, [selectedOfficer]);



  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    const filteredOfficers = roData.filter((officer) =>
      officer.user.full_name.toLowerCase().includes(searchQuery)
    );
    setOfficers(filteredOfficers);
  };

  useEffect(() => {
    setOfficers(roData);
  }, [roData]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
  };

  const [selectedFields, setSelectedFields] = useState([]);

  // const handleOfficerChange = (selectedOption) => {
  //   setSelectedFields(selectedOption);

  //   const updatedOfficers = officers.map((officer) => {
  //     if (officer.id === selectedOption.value) {
  //       return { ...officer, selected: true };
  //     } else {
  //       return { ...officer, selected: false };
  //     }
  //   })

  //   setOfficers(updatedOfficers);

  //   const selectedOfficer = updatedOfficers.find((officer) => officer.selected);
  //   setSelectedOfficer(selectedOfficer || null);
  // };

  const handleOfficerChange = (selectedOption) => {
    // Set selected fields
    setSelectedFields(selectedOption);

    // Check if the selected option is the "All Field Officers" option
    if (selectedOption.value === "all") {
      // Update officers to mark none as selected
      const updatedOfficers = officers.map((officer) => ({
        ...officer,
        selected: false,
      }));

      setOfficers(updatedOfficers);
      setSelectedOfficer(null); // Set selected officer to null
    } else {
      // Update officers based on the selected option
      const updatedOfficers = officers.map((officer) => {
        if (officer.id === selectedOption.value) {
          return { ...officer, selected: true };
        } else {
          return { ...officer, selected: false };
        }
      });

      setOfficers(updatedOfficers);

      // Find the selected officer and set it
      const selectedOfficer = updatedOfficers.find((officer) => officer.selected);
      setSelectedOfficer(selectedOfficer || null);
    }
  };


  const handleFilter = () => {
    // get the latitude and longitude of that location

    serverUrl.get(`/officers_management/ro-location?location=${selectedOptions.value}`, config)
      .then((res) => {
        console.log(res.data)
        setMapData(res.data);
        setShowFilterModal(false);
        setSelectedOptions([])
        toast.success(`Succesfully filtered the field officers in location ${selectedOptions.label}`)
      })
      .catch((error) => {
        console.log(error)
      })

  };
  if (isLoading) {
    return (
      <div className='loading-screen'>
        <div className='logo'>

        </div>
      </div>
    )
  }

  return (
    <>

      <SideBar />
      <Header />
      <Container fluid className="dashboardHome">
        <div className="topmobilehomeDisplay">
          {/* <Button className="btn btn-sm border-danger bg-transparent" onClick={() => { setShowOfficersList(true) }}>
            Relationship Officer
          </Button> */}
          <Button
            className="btn btn-sm border-danger bg-danger text-white"
            style={{ fontSize: "13px" }}
            onClick={handleFilterModalOpen}
          >
            Filter
          </Button>
        </div>



        <Row>
          <Col md={12} className='mapcomponent'>
            <div className="d-flex justify-content-between">
              <h2 className="text-start" style={{ fontSize: '18px', alignContent: "center" }}>
                {selectedOfficer ? `${selectedOfficer.user.full_name} Activity History` : 'Officers current locations'}
              </h2>

              <div className='d-flex'>
                <Select
                  options={relationshipList}
                  placeholder="Select Field Officer"
                  value={selectedFields}
                  onChange={handleOfficerChange}
                  className='me-3 text-start'
                  style={{ fontSize: "16px",width:"12vw" }}
                />



                <Button onClick={() => setShowFilterModal(true)} className="filterDashboardButton">
                  <i className="bi bi-funnel-fill me-2"></i>
                  <span>Filters</span>
                </Button>
              </div>

            </div>
            <Card className="border-0 mt-2 mb-3">
              {selectedOfficer ?
                <MapComponentUser data={mapData} />
                :
                <MapComponent data={mapData} />
              }
            </Card>
          </Col>
        </Row>


        {/* modal for filtering the field offices */}

        <Modal
          show={showFilterModal}
          onHide={() => setShowFilterModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Find Field Oficers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Location filter */}
            <Form.Group controlId="formDate">
              <Form.Label>Filter By Location</Form.Label>
              <Select
                options={optionList}
                placeholder="Select Location"
                value={selectedOptions}
                onChange={handleSelectChange}
                style={{ fontSize: "10px" }}
              />

            </Form.Group>
            <br></br>


            {/* Date filter */}



          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="dark"
              onClick={() => setShowFilterModal(false)}
            >
              Close
            </Button>
            <Button variant="warning" onClick={handleFilter}>
              Filter
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default Dashboard;
