import React from 'react'
import { Container, Card, Button, Form, FormControl,Table } from 'react-bootstrap'
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import '../assets/scss/LeaderBoard.scss'
function LeaderBoard() {
    return (

        <>
            <SideBar />
            <Header />
            <Container fluid className='leaderboard'>
                <div className='claimsCard'>
                    <div className='totalassigned'>
                    <Card className='totalCard'>
                        <h2>Most productive Ro</h2>
                        <span>Faith Otieno</span>
                        
                    </Card>

                    <Card className='assignedCard'>
                        <h2>Least Productive RO</h2>
                        <span>Abel Makau Otieno</span>
                    </Card>
                    </div>

                    <Card className='pendingCard'>
                        <h2>Average Time Target</h2>
                        <span>10hrs 29mins</span>
                    </Card>

                </div>

                <div className='d-flex justify-content-between topFiltersData'>
                    <Form className="searchBar">
                        <FormControl type="text" placeholder="Search Task" />
                    </Form>

                </div>

                    <Table responsive>
                        <thead>
                            <tr className='table'>
                                <th>Date</th>
                                <th>Task Type</th>
                                <th>Amount</th>
                                <th>Location</th>
                                <th>Status</th>
                                <th>Field Officer</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {claims.map((claim) => (
                                <tr>
                                    <td>{claim.claim_date}</td>
                                    <td>{claim.trip.task.task_type}</td>
                                    <td>{claim.estimated_amount}</td>
                                    <td>{claim.trip.task.location}</td>
                                    <td>{claim.claim_status}</td>
                                    <td>{claim.trip.task.relationship_officer.user.full_name}</td>
                                    <td>
                                        <i className='bi bi-eye text-info'></i>
                                        <i className='bi bi-pen text-warning'></i>
                                        <i className='bi bi-trash text-danger'></i>
                                    </td>

                                </tr>
                            ))} */}

                        </tbody>



                    </Table>
                

            </Container>
        </>
    )
}

export default LeaderBoard