import React, { useEffect, useState } from 'react';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Button, Card, Image, CardBody } from 'react-bootstrap'

const MapComponentPanic = ({ google, data }) => {
    const [mapCenter, setMapCenter] = useState({ lat: -1.2921, lng: 36.817223 });
    const [selectedUser, setSelectedUser] = useState(null);
    const [blinkingMarker, setBlinkingMarker] = useState(true);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState(null);
    const [selectedMarkerData, setSelectedMarkerData] = useState(null);
    const [mapBounds, setMapBounds] = useState(null);


    useEffect(() => {
        if (data) {
            setMapCenter({ lat: data.latitude, lng: data.longitude });
        }
    }, [data]);

    useEffect(() => {
        const interval = setInterval(() => {
            setBlinkingMarker(prevState => !prevState);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const handleMarkerClick = (user) => {
        setSelectedUser(user);
    };

    const CustomMarkerSVG = ({ text, backgroundColor }) => {
        return (


            <svg width="60" height="60" viewBox="0 0 60 70" xmlns="http://www.w3.org/2000/svg">
                <rect x="0" y="0" width="60" height="60" fill={backgroundColor} rx="10" />
                {text.length > 3 ? (
                    <image href={text} width="40" height="40" x="10" y="10" alt='profile' />
                ) : (
                    <>
                        <rect x="10" y="7" width="40" height="40" fill={text} rx="35" ry="35" />
                        <text
                            x="30"
                            y="30"
                            dominantBaseline="middle"
                            textAnchor="middle"
                            fontSize="19px"
                            fontWeight="bold"
                            fill="white"
                            fontFamily="Arial"
                        >
                            {text}
                        </text>
                    </>
                )}
                <polygon
                    points="35,50 50,70 65,50"
                    fill={backgroundColor}
                />
            </svg>
        );
    };
    const getInitials = (fullName) => {
        const names = fullName.split(' ');
        const initials = names.map(name => name.charAt(0)).join('');
        return initials.toUpperCase();
    };
    const onMarkerClick = (props, marker, e, markerData) => {
        setActiveMarker(marker);
        setSelectedMarkerData(markerData);
        setShowInfoWindow(true);
    };

    const onCloseInfoWindow = () => {
        setShowInfoWindow(false);
        setActiveMarker(null);
    };

    const onMapClicked = () => {
        if (showInfoWindow) {
            setShowInfoWindow(false);
            setActiveMarker(null);
        }
    };
    const formatString = (str) => {
        // Split the string by underscore
        const words = str.split('_');

        // Capitalize the first letter of each word and join them with a space
        const formattedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return formattedString;
    };

    const renderInfoWindowContent = () => {
        if (selectedMarkerData) {
            return (
                <>
                    {/* display the field officer data */}
                    <Card className='border-0'>
                        <CardBody>
                            {/* display the details of the field officer */}
                            <div className='d-flex'>
                                {/* display initials if no image */}
                                {selectedMarkerData.ro_profile.length > 3 ?
                                    <Image className='infoWindowImage' src={selectedMarkerData.ro_profile} />
                                    :
                                    <div className='infoWindowImage' style={{ backgroundColor: selectedMarkerData.color }}>
                                        <p className='infoWindowImageText'>{selectedMarkerData.ro_profile}</p>
                                    </div>
                                }
                                <div className='d-block text-start'>
                                    <h5>{selectedMarkerData.ro_name}</h5>
                                    <p style={{ marginBottom: "5px" }} className=' text-info'><i className="bi bi-telephone-fill me-1 fw-bold"></i>{selectedMarkerData.ro_phone}</p>
                                    <p style={{ marginBottom: "5px" }} className='text-primary'><i className="bi bi-geo-alt-fill me-1"></i>{selectedMarkerData.ro_location}</p>

                                    <div className='d-flex'>
                                        <span className='text-dark fw-bold me-2 mt-1'>Status</span>
                                        <Button className={`btn border-0 btn-sm text-white safetySpan ${selectedMarkerData.ro_safety_status === 'Safe' ? 'bg-success' : 'btn-danger'}`}>{selectedMarkerData.ro_safety_status}</Button>
                                    </div>
                                </div>
                            </div>
                            {/* current task status */}
                            {selectedMarkerData.task.task_status ?
                                <div className='d-block text-dark text-start'>
                                    <h5 className='text-decoration-underline mt-1'>Current Task</h5>

                                    <p className='text-dark'><b>Task Type</b>: <span>{formatString(selectedMarkerData.task.task)}</span></p>
                                    <p><b>Task Location</b>: <span>{selectedMarkerData.task.location}</span></p>
                                    {selectedMarkerData.task.task === 'debt_collection' ?
                                        <p><b>Task Estimated Amount</b> : <span>KSH {parseInt(selectedMarkerData.task.amount)?.toLocaleString()}</span></p>
                                        :
                                        <></>
                                    }
                                </div>
                                :
                                <>
                                </>}

                            <div className='d-block mt-2'>
                                <h5 className='text-start' style={{ fontSize: "13px" }}>Task Status:</h5>
                                <div className=' d-flex ms-2'>
                                    <p className='text-danger'>Pending <span style={{ borderRadius: "2px" }} className='p-1 bg-danger text-white '>{selectedMarkerData.task.pending_tasks}</span></p>
                                    <p className='text-success ms-2'>Completed <span style={{ borderRadius: "2px" }} className='p-1 bg-success text-white'>{selectedMarkerData.task.completed_tasks}</span></p>
                                </div>
                            </div>

                            <div className='d-flex'>
                                <h5 style={{ fontSize: "13px" }}>Location Duration:</h5>
                                <p style={{ backgroundColor: "#ffbd3c", color: "white", borderRadius: "8px", paddingInline: "10px", marginLeft: "10px" }}>{selectedMarkerData.duration}</p>
                            </div>

                        </CardBody>
                    </Card>
                </>
            );
        }
        return null;
    };

    const onMapReady = (mapProps, map) => {
        if (mapBounds) {
            map.fitBounds(mapBounds);
        }
    };

    return (
        <Map
            google={google}
            className="position-relative mapHomePage"
            zoom={9}
            initialCenter={mapCenter}
            style={{ height: '80vh', width: '100%', position: 'relative' }}
            onClick={onMapClicked}
            onReady={onMapReady}

        >
            {data && (
                <Marker
                    position={{ lat: data.latitude, lng: data.longitude }}
                    onClick={() => handleMarkerClick(data)}
                    icon={{
                        url: `data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(
                            <CustomMarkerSVG text={getInitials(data.user.full_name)} backgroundColor={"white"} />
                        ))}`,
                    }}
                />
            )}

            {selectedUser && (
                <InfoWindow
                    position={{ lat: selectedUser.latitude, lng: selectedUser.longitude }}
                    onClose={() => setSelectedUser(null)}
                >
                    <div>
                        <h2>User Details</h2>
                        <p><strong>Name:</strong> {selectedUser.user.full_name}</p>
                        <p><strong>Email:</strong> {selectedUser.user.email}</p>
                        {/* Add more user details here */}
                    </div>
                </InfoWindow>
            )}
        </Map>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDxKuaBgR44-dZ-vZGGWY4YnL4NPEEuri0'
})(MapComponentPanic);
