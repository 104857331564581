import React, { useEffect, useState } from 'react';
import { Map, Marker, GoogleApiWrapper, Polyline } from 'google-maps-react';

const MapComponentClaim = ({ google, data }) => {
    const [mapCenter, setMapCenter] = useState({ lat: -1.2921, lng: 36.817223 });
    const [markers, setMarkers] = useState([]);
    const [polylinePath, setPolylinePath] = useState([]);

    useEffect(() => {
        const displayMarkers = () => {
            if (!Array.isArray(data)) {
                data = [data];
            }
            const newMarkers = data.map((item) => [
                {
                    position: { lat: item.trip.from_latitude, lng: item.trip.from_longitude },
                    label: 'From',
                    data: item
                },
                {
                    position: { lat: item.trip.to_latitude, lng: item.trip.to_longitude },
                    label: 'To',
                    data: item
                }
            ]).flat();
            setMarkers(newMarkers);

            const newPolylinePath = data.map(item => [
                { lat: item.trip.from_latitude, lng: item.trip.from_longitude },
                { lat: item.trip.to_latitude, lng: item.trip.to_longitude }
            ]).flat();
            setPolylinePath(newPolylinePath);

            if (newMarkers.length > 0) {
                setMapCenter(newMarkers[0].position);
            }
        };

        displayMarkers();
    }, [data]);

    return (
        <Map
            google={google}
            className='position-relative mapHomePage'
            zoom={15}
            initialCenter={mapCenter}
            center={mapCenter}
            style={{ height: '80vh', width: '100%', position: 'relative' }}
        >
            {markers.map((marker, index) => (
                <Marker
                    key={index}
                    position={marker.position}
                />
            ))}

            <Polyline
                path={polylinePath}
                strokeColor="#0000FF"
                strokeOpacity={2.8}
                strokeWeight={2}
            />
        </Map>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDxKuaBgR44-dZ-vZGGWY4YnL4NPEEuri0'
})(MapComponentClaim);
