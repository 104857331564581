import React, { useState, useEffect } from 'react';
import {
  Container,
  Form,
  FormControl,
  Button,
  Table,
  Modal,
  FormGroup,
  ModalBody,
  Spinner,
  Dropdown,
} from 'react-bootstrap';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import '../assets/scss/Accounts.scss';
import '../assets/scss/EmergencyContact.scss';
import { serverUrl } from '../serverUrl';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import CryptoJS from 'crypto-js';


function EmergencyContacts() {
  const [isLoading, setIsloading] = useState(false)
  const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
  const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };
  const [emergencyContact, setEmergencyContact] = useState([]);
  const [emergencyOfficers, setEmergencyOfficer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDescending, setIsDescending] = useState(true);
  const [selectedEmergencyContact, setSelectedEmergencyContact] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAccountModal, setAccountModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showOfficerModal, setOfficerModal] = useState(false);
  const [showAddOfficerModal, setAddOfficerModal] = useState(false);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [originalEmergencyContacts, setOriginalEmergencyContacts] = useState(
    []
  );
  const [roData, setRoData] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const handleopenmodal = (emergencyContact) => {
    setSelectedEmergencyContact(emergencyContact);
    setShowDetailsModal(true);
  };

  const handleEditmodal = (emergencyContact) => {
    setSelectedEmergencyContact(emergencyContact);
    setShowEditModal(true);
  };
  const [showEditModal, setShowEditModal] = useState(false);

  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };

  //getting user id
  const decodedToken = jwtDecode(access);
  const userId = decodedToken.user_id;

  const [adminData, setAdminData] = useState({});
  // display the selected franchise
  const selectedFranchise = localStorage.getItem('selectedFranchise');
  const decryptedData = decryptData(selectedFranchise, secretKey);


  useEffect(() => {
    setIsloading(true)
    const fetchEmergencyContactData = async () => {
      try {
        const response = await serverUrl.get(
          `/officers_management/emergency-contact/?franchise_id=${decryptedData}`,
          config
        );
        const emergencyContact = response.data.results;
        setEmergencyContact(emergencyContact);
        setOriginalEmergencyContacts(emergencyContact);
        setIsloading(false)

        // get the list of the relationship
        const responseOfficer = await serverUrl.get(`/officers_management/relationship-officer/?franchise=${decryptedData}`, config);
        setEmergencyOfficer(responseOfficer.data.results);
      } catch (error) {
        console.error('Error Fetching Emergency Contact Data:', error);
        setIsloading(false)

      }
    };

   
    fetchEmergencyContactData();
  }, []);

  console.log(emergencyOfficers)

  const handleEditSubmit = () => {
    const data = {
      name: selectedEmergencyContact.name,
      phone_number: selectedEmergencyContact.phone_number,
      relationship: selectedEmergencyContact.relationship,
      email: selectedEmergencyContact.email,
      relationshipOfficer: selectedEmergencyContact.relationshipOfficer,
    };

    serverUrl
      .patch(
        `/officers_management/emergency-contact/${selectedEmergencyContact.id}/`,
        data,
        config
      )
      .then((res) => {
        console.log('Emergency Contact Edited Successfully:', res.data);
        setShowEditModal(false);

        serverUrl
          .get(`/officers_management/emergency-contact/?franchise_id=${decryptedData}`, config)
          .then((res) => {
            setEmergencyContact(res.data.results);
            toast.success('Emergency Contact edited successfully');
          })
          .catch((error) => {
            console.error('Error fetching updated Emergency Contact:', error);
            // Show an error message using toast
            toast.error(
              'Failed to Edit Emergency Contact. Please try again later.'
            );
          });
      })
      .catch((error) => {
        console.error('Error editing Emergency Contact:', error);
        // Show an error message using toast
        toast.error(
          'Failed to edit Emergency Contact. Please try again later.'
        );
      });
  };

  const [formData, setFormData] = useState({
    roName: '',
    roPhoneNumber: '',
    roEmail: '',
    roDebtCollection: '',
    roVettedBusinesses: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [filterData, setFilterData] = useState({
    search: '',
    roName: '',
    roPhoneNumber: '',
    roEmail: '',
  });

  const applyFilters = () => {
    const { search, roName, roPhoneNumber, roEmail } = filterData;
    const filtered = roData.filter((account) => {
      return (
        (search === '' ||
          account.user.full_name.toLowerCase().includes(search.toLowerCase()) ||
          account.user.phone_number
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          account.user.email.toLowerCase().includes(search.toLowerCase())) &&
        (roName === '' ||
          account.user.full_name
            .toLowerCase()
            .includes(roName.toLowerCase())) &&
        (roPhoneNumber === '' ||
          account.user.phone_number.includes(roPhoneNumber)) &&
        (roEmail === '' ||
          account.user.email.toLowerCase().includes(roEmail.toLowerCase()))
      );
    });
    setFilteredAccounts(filtered);
    setShowFilterModal(false);
  };

  const [officer, setOfficer] = useState([]);
  const [officerData, setOfficerData] = useState({
    EmergencyFullName: '',
    EmergencyRelation: '',
    EmergencyPhone: '',
    EmergencyEmail: '',
    EmergencyRelationOfficer: '',
  });

  const handleUserDetails = (e) => {
    e.preventDefault();
    const data = {
      relationshipOfficer: officerData.EmergencyRelationOfficer,
      relationship: officerData.EmergencyRelation,
      name: officerData.EmergencyFullName,
      phone_number: officerData.EmergencyPhone,
      email: officerData.EmergencyEmail,
    }
    // post the emergency contact
    serverUrl.post(`officers_management/emergency-contact/`, data, config)
      .then((res) => {
        serverUrl.get(`/officers_management/emergency-contact/?franchise_id=${decryptedData}`, config)
          .then((res) => {
            setEmergencyContact(res.data.results);

          })
          .catch((error) => {
            console.log(error)
          })
          
        toast.success('Succesfully added the emergency contact')
        setOfficerModal(false)
      })
      .catch((error) => {
        console.log(error)
      })

  };

  const handleAddOfficer = (e) => {
    e.preventDefault();
    handleUserDetails(e);
  };

  const handleOfficerChange = (e) => {
    const { name, value } = e.target;

    // Update officerData state
    if (name in officerData) {
      setOfficerData({
        ...officerData,
        [name]: value,
      });
    }


  };



  // Function to handle click on trash icon
  const handleDeleteClick = (emergencyContact) => {
    setSelectedEmergencyContact(emergencyContact);
    setShowDeleteModal(true);
  };

  const handledeleteSubmit = () => {
    serverUrl
      .delete(
        `/officers_management/emergency-contact/${selectedEmergencyContact.id}/`,
        config
      )
      .then((res) => {
        setEmergencyContact(res.data.results);
        setShowDeleteModal(false);

        serverUrl
          .get(`/officers_management/emergency-contact/?franchise_id=${decryptedData}`, config)
          .then((res) => {
            setEmergencyContact(res.data.results);
            toast.success('Emergency Contact deleted successfully');
            // window.location.reload();
            setShowDeleteModal(false);
          })
          .catch((error) => {
            console.error('Error Emergency Contact data:', error);
            // Show an error message using toast
            toast.error('Failed to Emergency Contact data.');
            setShowDeleteModal(false);
          });
      })
      .catch((error) => {
        console.error('Error deleting Emergency Contact:', error);
        // Show an error message using toast
        toast.error(
          'Failed to delete Emergency Contact. Please try again later.'
        );
        setShowDeleteModal(false);
      });
  };

  const toggleOrder = () => {
    setIsDescending(!isDescending);
    setRoData([...roData].reverse());
  };

  const flattenObject = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? `${prefix}.` : '';
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        Object.assign(acc, flattenObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchQuery(searchQuery);
    if (searchQuery === '') {
      setEmergencyContact(originalEmergencyContacts);
    } else {
      const filteredAccounts = originalEmergencyContacts.filter((emergencyContact) => {
        const flatTask = flattenObject(emergencyContact);
        return Object.values(flatTask).some(
          (value) =>
            typeof value === 'string' &&
            value.toLowerCase().includes(searchQuery)
        );
      });
      setEmergencyContact(filteredAccounts);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Maximum of 10 tasks per page

  // Calculate index of the first and last item of the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current items to display
  let currentItems = [];
  if (emergencyContact && emergencyContact.length > 0) {
    currentItems = emergencyContact.slice(indexOfFirstItem, indexOfLastItem);
  }

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  // Calculate total number of pages
  const totalPages = emergencyContact
    ? Math.ceil(emergencyContact.length / itemsPerPage)
    : 0;


  const [selectedName, setSelectedName] = useState('');
  const [setSelectedrelationship, selectedrelationship] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');


  const handleFilter = () => {

    // console.log("selectedTaskType:" , selectedTaskType.task_type)
    // Create a copy of the original tasks array to apply filters on
    let filteredTasks = [...originalEmergencyContacts];

    // Apply filters based on selected criteria

    // Filter by Task Status

    if (selectedName !== '') {
      filteredTasks = filteredTasks.filter(emergencyContact => emergencyContact.name === selectedName);
    }

    // Filter by phone number
    if (selectedPhone !== '') {
      filteredTasks = filteredTasks.filter(emergencyContact => emergencyContact.phone_number === selectedPhone);
    }

    if (selectedrelationship !== '') {
      filteredTasks = filteredTasks.filter(emergencyContact => emergencyContact.relationship === selectedrelationship);
    }
    if (selectedEmail !== '') {
      filteredTasks = filteredTasks.filter(roData => roData.email === selectedEmail);
    }



    // Set the filtered tasks as the new tasks to display
    setEmergencyContact(filteredTasks);
    setShowFilterModal(false);
  };

  if (isLoading) {
    return (
      <div className='loading-screen'>
        <div className='logo'>

        </div>
      </div>
    )
  }

  return (
    <>
      <SideBar />
      <Header />
      <Container fluid className="accounts">
        <Button
          className="btn  btn-warning btn-sm justify-content-start d-block addemergency"
          onClick={() => setOfficerModal(true)}
        >
          <i className="bi bi-plus"></i>Add Emergency Contact
        </Button>
        <div className="d-flex justify-content-between topFiltersData">
          <Form className="searchBar">

          </Form>
          <div className="d-flex justify-content-between filterTopsData">


            <Button className="lastappointment emergency-contact" onClick={toggleOrder}>
              <i className="bi bi-arrow-up"></i>
              <i className="bi bi-arrow-down me-2"></i>
              <span>Last Appointment Date</span>
            </Button>
          </div>
        </div>

        {emergencyContact && emergencyContact.length === 0 ? (
          <p style={{ fontSize: '25px', marginTop: '10px' }}>
            No Emergency Contacts  available
          </p>
        ) : (
          <div className='taskDisplay'>
            <Form className="searchBar">
              <FormControl
                type="text"
                placeholder="Search Emergency Contact"
                onChange={handleSearch}
              />
            </Form>

            <Table responsive >

              <thead>
                <tr className="table">
                  <th>Field Officer</th>
                  <th>Full Name</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th>Relationship</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((contactData) => (
                  <tr>
                    <td>{contactData.relationshipOfficer.user.full_name}</td>
                    <td>{contactData.name}</td>
                    <td>0{contactData.phone_number}</td>
                    <td>{contactData.email}</td>
                    <td>{contactData.relationship}</td>
                    <td className="actions">
                      <Dropdown>
                        <Dropdown.Toggle variant="link" className="btn btn-sm text-dark p-0">
                          <i className="bi bi-three-dots-vertical"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleopenmodal(contactData)}>
                            <Button style={{ width: "10vw" }} className='btn btn-sm btn-warning'> <i className="bi bi-eye me-2"></i> View</Button>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleEditmodal(contactData)}>
                            <Button style={{ width: "10vw" }} className='btn btn-sm btn-info'><i className="bi bi-pen me-2"></i> Edit</Button>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeleteClick(contactData)}>
                            <Button style={{ width: "10vw" }} className='btn btn-sm btn-danger'><i className="bi bi-trash me-2"></i> Delete</Button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {emergencyContact && emergencyContact.length > 0 && (
              <nav>
                <ul className="pagination">
                  <li
                    className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(currentPage - 1)}
                    >
                      {'<'}
                    </button>
                  </li>
                  <li className="page-item disabled">
                    <span className="page-link">
                      {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}
                      -
                      {Math.min(
                        currentPage * itemsPerPage,
                        emergencyContact.length
                      )}{' '}
                      of {emergencyContact.length}
                    </span>
                  </li>
                  <li
                    className={`page-item ${currentPage === totalPages ? 'disabled' : ''
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(currentPage + 1)}
                    >
                      {'>'}
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </div>


        )}



        {/* Delete Confirmation Modal */}
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Tasks</Modal.Title>
          </Modal.Header>
          {/* Modal content */}
          <ModalBody>Are you sure you want to delete this data?</ModalBody>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Close
            </Button>
            <Button
              className="delete"
              variant="info"
              onClick={handledeleteSubmit}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Details Modal */}

        {selectedEmergencyContact && (
          <Modal
            show={showDetailsModal}
            onHide={() => setShowDetailsModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Emergency Contact Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form className="formTask">
                <FormGroup>
                  <Form.Label>Full Name</Form.Label>
                  <FormControl
                    name="status"
                    value={selectedEmergencyContact.name || ''}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>Relationship</Form.Label>
                  <FormControl
                    name="relationship"
                    value={selectedEmergencyContact.relationship || ''}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>Phone Number</Form.Label>
                  <FormControl
                    name="phone_number"
                    value={selectedEmergencyContact.phone_number != null ? '0' + selectedEmergencyContact.phone_number : 'null'}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>Email</Form.Label>
                  <FormControl
                    name="email"
                    value={selectedEmergencyContact.email || ''}
                    readOnly
                  />
                </FormGroup>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDetailsModal(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {/* Edit Modal */}

        {selectedEmergencyContact && (
          <Modal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Emergency Contact Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                // Enable the onSubmit handler if needed
                onSubmit={handleEditSubmit}
                className="formTask"
              >
                <FormGroup>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="emergency_name"
                    value={selectedEmergencyContact.name || ''}
                    onChange={(e) =>
                      setSelectedEmergencyContact({
                        ...selectedEmergencyContact,
                        name: e.target.value,
                      })
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Relationship</Form.Label>
                  <Form.Control
                    as="select"
                    name="emergency_relationship"
                    value={selectedEmergencyContact.relationship || ''}
                    onChange={(e) =>
                      setSelectedEmergencyContact({
                        ...selectedEmergencyContact,
                        relationship: e.target.value,
                      })
                    }
                  >
                    <option value="#">Choose Relationship</option>
                    <option value="spouse">Spouse</option>
                    <option value="parent">Parent</option>
                    <option value="sibling">Sibling</option>
                    <option value="friend">Friend</option>
                    <option value="colleague">Colleague</option>
                  </Form.Control>
                </FormGroup>

                <FormGroup>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    name="emergency_phone_number"
                    value={"0" + selectedEmergencyContact.phone_number || ''}
                    onChange={(e) =>
                      setSelectedEmergencyContact({
                        ...selectedEmergencyContact,
                        phone_number: e.target.value,
                      })
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="emergency_email"
                    value={selectedEmergencyContact.email || ''}
                    onChange={(e) =>
                      setSelectedEmergencyContact({
                        ...selectedEmergencyContact,
                        email: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="dark"
                onClick={() => setShowEditModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="warning"
                type="submit"
                onClick={handleEditSubmit}
              >
                Edit
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {/* filter modal */}
        <Modal
          show={showFilterModal}
          onHide={() => setShowFilterModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter Emergency Contacts</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group controlId="formStatus">
              <Form.Label>Filter By Name</Form.Label>
              <Form.Control
                type='text'
                value={selectedName}
                onChange={(e) => setSelectedName(e.target.value)}
                placeholder='Enter Name'
              >
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formTaskType">
              <Form.Label>Filter By Phone Number</Form.Label>
              <Form.Control
                type='text'
                value={selectedPhone}
                onChange={(e) => setSelectedPhone(e.target.value)}
                placeholder="Enter Phone Number"
              >
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formTaskType">
              <Form.Label>Filter By Relationship</Form.Label>
              <Form.Control
                type='text'
                value={selectedrelationship}
                onChange={(e) => setSelectedrelationship(e.target.value)}
                placeholder="Enter Relationship"
              >
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formTaskType">
              <Form.Label>Filter By Email</Form.Label>
              <Form.Control
                type='text'
                value={selectedEmail}
                onChange={(e) => setSelectedEmail(e.target.value)}
                placeholder="Enter Email"
              >
              </Form.Control>
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowFilterModal(false)}
            >
              Close
            </Button>
            <Button variant="info" onClick={handleFilter}>
              Filter
            </Button>
          </Modal.Footer>
        </Modal>





        {/* Adding Officer Modal */}
        <Modal
          show={showOfficerModal}
          onHide={() => setOfficerModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Emergency Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleUserDetails} className="formTask">

              <FormGroup>
                <Form.Label>Field Officer</Form.Label>
                <Form.Control
                  as="select"
                  name="EmergencyRelationOfficer"
                  onChange={handleOfficerChange}
                  value={officerData.EmergencyRelationOfficer}
                >
                  <option value="" selected disabled>Choose Field Officer</option>
                  {emergencyOfficers.map((officer) => (
                    <option value={officer.id}>{officer.user.full_name}</option>
                  ))}

                </Form.Control>
                {formErrors.EmergencyRelation && <span className="text-danger">{formErrors.EmergencyRelation}</span>}
              </FormGroup>

              <FormGroup>
                <Form.Label>Full Name</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  name="EmergencyFullName"
                  value={officerData.EmergencyFullName}
                  onChange={handleOfficerChange}
                  placeholder="Enter Your Full Name"
                />
                {formErrors.EmergencyFullName && <span className="text-danger">{formErrors.EmergencyFullName}</span>}
              </FormGroup>

              <FormGroup>
                <Form.Label>Relationship</Form.Label>
                <Form.Control
                  as="select"
                  name="EmergencyRelation"
                  value={officerData.EmergencyRelation}
                  onChange={handleOfficerChange}
                >
                  <option value="">Choose Relationship</option>
                  <option value="spouse">Spouse</option>
                  <option value="parent">Parent</option>
                  <option value="sibling">Sibling</option>
                  <option value="friend">Friend</option>
                  <option value="colleague">Colleague</option>
                </Form.Control>
                {formErrors.EmergencyRelation && <span className="text-danger">{formErrors.EmergencyRelation}</span>}
              </FormGroup>


              <FormGroup>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  name="EmergencyPhone"
                  value={officerData.EmergencyPhone}
                  onChange={handleOfficerChange}
                  placeholder="Enter Phone Number"
                />
                {formErrors.EmergencyPhone && <span className="text-danger">{formErrors.EmergencyPhone}</span>}
              </FormGroup>
              <FormGroup>
                <Form.Label>Email</Form.Label>
                <FormControl
                  type="email"
                  name="EmergencyEmail"
                  value={officerData.EmergencyEmail}
                  onChange={handleOfficerChange}
                  placeholder="Enter Email"
                />
                {formErrors.EmergencyEmail && <span className="text-danger">{formErrors.EmergencyEmail}</span>}
              </FormGroup>
              {/* <button type="submit" className="btn btn-primary">Submit</button> */}
            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={() => setShowFilterModal(false)}>
              Cancel
            </Button>


            {loading ?

              <Button ><Spinner /></Button>

              :
              <Button variant="warning" type='submit' onClick={handleAddOfficer}>Add</Button>

            }
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default EmergencyContacts;
