import React, { useState, useEffect } from 'react';
import { Container, FormControl, Card, Button, Row, Col, Form, Image } from 'react-bootstrap';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import '../assets/scss/Panic.scss';
import { serverUrl } from '../serverUrl';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode'; // Note: Ensure the correct import
import MapComponentPanic from '../components/MapComponentPanic';
import { Link } from 'react-router-dom';
import TaskAllNew from '../assets/images/warning (1).png'
import TaskAll from '../assets/images/warning (2).png'
import CryptoJS from 'crypto-js';



function Panic() {
    const[isLoading,setIsloading] = useState(false)

    const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
    const decryptData = (cipherText, secretKey) => {
      const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };
    const accessToken = localStorage.getItem('authTokens');
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    const [panics, setPanics] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalAlerts, setTotalAlerts] = useState(0);
    const [resolvedAlerts, setResolvedAlerts] = useState(0);
    const [selectedPanicId, setSelectedPanicId] = useState(null);
    const [selectedPanicCoordinates, setSelectedPanicCoordinates] = useState(null);
  // display the selected franchise
  const selectedFranchise = localStorage.getItem('selectedFranchise');
  const decryptedData = decryptData(selectedFranchise, secretKey);
    useEffect(() => {
        setIsloading(true)
        const fetchData = async () => {
            try {
                const response = await serverUrl.get(`/officers_management/panic-alerts/?franchise=${decryptedData}`, config);
                const allPanics = response.data.results;
                const unresolvedPanics = allPanics.filter(panic => !panic.resolved);
                const resolvedPanics = allPanics.filter(panic => panic.resolved);

                setPanics(unresolvedPanics);
                setTotalAlerts(allPanics.length);
                setResolvedAlerts(resolvedPanics.length);
                setIsloading(false)
            } catch (error) {
                console.error('Error fetching panic data:', error);
                setIsloading(false)
            }
        };

        fetchData();
    }, []);

    const filteredPanics = panics.filter(panic =>
        panic.user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        panic.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        panic.county.county.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handlePanicClick = (panic) => {
        setSelectedPanicCoordinates(panic)
        setSelectedPanicId(panic.id);
    };

    if (isLoading) {
        return (
          <div className='loading-screen'>
            <div className='logo'>
    
            </div>
          </div>
        )
      }


    return (
        <>
            <SideBar />
            <Header />
            <Container fluid className='panic'>
                <div className='claimsCard'>
                    <div className='totalpending'>
                        <Card className='totalCard'>
                            <div className="">
                                <Image src={TaskAll} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%", marginLeft: "15px" }} />


                                <div className='ms-3'>
                                    <h2 className='text-white'>Total Alerts Reported</h2>
                                    <span className='text-white'>{totalAlerts}</span>
                                    <Link to='/panic-alerts/all' className='text-decoration-none text-dark reported'>
                                        <Button className='btn btn-sm bg-white text-dark w-10 border-0 reported'>View Alerts</Button>
                                    </Link>
                                </div>
                            </div>
                        </Card>

                        <Card className='pendingCard'>
                            <div className=''>
                                <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                                <div className='ms-3'>
                                    <h2>Total Alerts Unresolved</h2>
                                    <span>{panics.length}</span>
                                    <Link to='/panic-alerts/unresolved' className='text-decoration-none text-dark'>
                                        <Button className='btn btn-sm bg-white text-dark w-10 border-0'>View Alerts</Button>
                                    </Link>
                                </div>
                            </div>


                        </Card>
                    </div>

                    <Card className='assignedCard'>

                        <div className=''>
                            <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                            <div className='ms-3'>
                                <h2>Total Alerts Resolved</h2>
                                <span>{resolvedAlerts}</span>
                                <Link to='/panic-alerts/resolved' className='text-decoration-none text-dark'>
                                    <Button className='btn btn-sm bg-white text-dark w-10 border-0'>View Alerts</Button>
                                </Link>

                            </div>
                        </div>


                    </Card>
                </div>

                <Row>
                    <Col md={3} className='unresolvedDisplay'>
                        {panics.length !== 0 ? (
                            <div>
                                <Form className="searchBar">
                                    <FormControl
                                        type="text"
                                        placeholder="Search Officer Alert"
                                        value={searchTerm}
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </Form>
                                <h3>Unresolved Alerts</h3>
                                <div className='unresolvedAlerts'>
                                    {filteredPanics.map((panic) => (
                                        <div
                                            key={panic.id}
                                            className='unresolvedOficer'
                                            onClick={() => handlePanicClick(panic)}
                                            style={{ backgroundColor: selectedPanicId === panic.id ? '#E8294940' : '' }}
                                        >
                                            <Image src={panic.user.profile_picture} style={{ borderRadius: "50%" }} />
                                            <div className='officerUnresolved'>
                                                <h6>{panic.user.full_name}</h6>
                                                <p>{panic.location}, {panic.county.county}</p>
                                                {(() => {
                                                    const createdAt = new Date(panic.created_at);
                                                    const currentTime = new Date();
                                                    const timeDifference = currentTime - createdAt;
                                                    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                                                    const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                                                    if (hoursDifference === 0) {
                                                        return <p>{minutesDifference} minutes ago</p>;
                                                    } else {
                                                        return <p>{hoursDifference} hours and {minutesDifference} minutes ago</p>;
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className='panicUnresolvedNone'>
                                <p>We are good to go, no alerts raised so far</p>
                            </div>
                        )}
                    </Col>
                    <Col md={8} className='map'>
                        <MapComponentPanic data={selectedPanicCoordinates} />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Panic;
