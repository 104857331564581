import React, { useEffect, useState } from 'react'
import { Container, Card, Button, Table, Modal, Form, FormControl, FormSelect, Image } from 'react-bootstrap'
import SideBar from '../components/SideBar'
import Header from '../components/Header'
import '../assets/scss/Wallet.scss';
import { Link } from 'react-router-dom';
import { serverUrl } from '../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import WalletImage from '../assets/images/wallet-new (1).png'
import WalletNew from '../assets/images/wallet-new (2).png'

function Wallet() {
  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  const decodedToken = jwtDecode(access);
  const userId = decodedToken.user_id;
  const [roTotal, setRoTal] = useState(0)
  const [paymentAmount, setPaymentAmount] = useState(0)
  const [franchiseWallets, setFranchiseWallets] = useState([])
  const [franchiseROWallets, setFranchiseROWallets] = useState([])
  const [walletStatus, setWalletStatus] = useState({})
  const [franchiseFound, setFranchiseFound] = useState('')
  const [franchiseFoundSubscription, setFranchiseFoundSubscription] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const admin = await serverUrl.get(`/officers_management/admins/?user=${userId}`, config);
        const wallet = await serverUrl.get(`/officers_management/wallet/?franchise=${admin.data.results[0].franchise}`, config);

        const roCount = await serverUrl.get(`/officers_management/relationship-officer-summary/?franchise_id=${admin.data.results[0].franchise}`, config);
        setRoTal(roCount.data.length)
        const totalAmount = 1500 * parseInt(roCount.data.length)
        const response = await serverUrl.get(`/officers_management/subscription-summary?franchise_id=${admin.data.results[0].franchise}`, config);
        setWalletStatus(response.data.results[0].summary[0])
        setPaymentAmount(totalAmount)
        setFranchiseFound(admin.data.results[0].franchise)

        const franchiseWallet = await serverUrl.get(`/officers_management/wallet/?franchise=${admin.data.results[0].franchise}&collection_type=franchise`, config);

        const availablewallets = await Promise.all(franchiseWallet.data.results.map(async (wallet) => {
          try {
            const res = await serverUrl.get(`/officers_management/wallet-account-balance?wallet=${wallet.id}`, config);
            return {
              ...wallet,
              balance: res.data.results[0]?.wallet_balance || 0
            };
          } catch (error) {
            console.error(`Error fetching balance for wallet ${wallet.id}:`, error);
            return {
              ...wallet,
              balance: 0
            };
          }
        }));

        setFranchiseWallets(availablewallets);

        const franchiseRoWallet = await serverUrl.get(`/officers_management/wallet/?franchise=${admin.data.results[0].franchise}&collection_type=relationship_officer`, config);
        setFranchiseROWallets(franchiseRoWallet.data.results)

        const franchiseWalletSubscription = await serverUrl.get(`/officers_management/wallet/?franchise=${admin.data.results[0].franchise}&account_type=subscription&collection_type=franchise`, config);
        setFranchiseFoundSubscription(franchiseWalletSubscription.data.results[0])
      } catch (error) {
        console.error('Error fetching panic data:', error);
      }
    };

    fetchData();
  }, [userId]);

  const [walletCreate, setWalletCreate] = useState(false)


  const handleWalletCreate = () => {
    setWalletCreate(true)

  }
  const handleWalletClose = () => {
    setWalletCreate(false)

  }
  const [formData, setFormData] = useState({
    name: "",

  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));


  };

  function handleAddAccount(e) {
    e.preventDefault();
    // create the new wallet account
    const data = {
      name: formData.name,
      franchise: franchiseFound,
      account_type: 'collection',
      collection_type: 'franchise'
    }
    serverUrl.post(`/officers_management/wallet/`, data, config)
      .then((res) => {
        toast.success('Succesfully added a wallet account')
        serverUrl.get(`/officers_management/wallet/?franchise=${franchiseFound}&collection_type=franchise`, config)
          .then((res) => (
            setFranchiseWallets(res.data.results)
          ))
          .catch((error) => {
            console.log(error)
          })
        setWalletCreate(false)


      })
      .catch((error) => {
        console.log(error)
      })


  }

  const [roTopUp, setRoTopUp] = useState(false)


  const handleROTOPUP = () => {
    setRoTopUp(true)

  }
  const handleROTOPUPClose = () => {
    setRoTopUp(false)

  }

  const [formDatarRO, setFormDataRO] = useState({
    name: "",
    account_type: "",
    payment_type: "",
    amount: "",
    wallet: "",
    phone: "",
  });

  const [paymentType, setPaymentType] = useState('')
  const handleChangeRo = (event) => {
    const { name, value } = event.target;
    setFormDataRO((prevFormDataRO) => ({
      ...prevFormDataRO,
      [name]: value,
    }));
    if (name === "payment_type") {
      setPaymentType(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }


  };

  function handleAddRoAccount(e) {
    e.preventDefault();
    // create the transaction for new wallet

    // the inbound transaction
    const indata = {
      wallet: parseInt(formDatarRO.account_type),
      amount: parseInt(formDatarRO.amount),
      transcation_type: 'inbound',
      status: "success",
      franchise: parseInt(franchiseFound),
      payment_type: "wallet",
      reason: "Franchise Deposit"
    }


    const outdata = {
      wallet: parseInt(formDatarRO.wallet),
      amount: parseInt(formDatarRO.amount),
      transcation_type: 'outbound',
      status: "success",
      franchise: parseInt(franchiseFound),
      payment_type: "wallet",
      reaseon: "Field Officer account deposit"
    }


    serverUrl.post(`/officers_management/wallet-transactions/`, indata, config)
      .then((res) => {
        serverUrl.post(`/officers_management/wallet-transactions/`, outdata, config)
          .then((res) => (
            toast.success('Succesfully topped up the Field Officer  account'),
            handleROTOPUPClose()

          ))
          .catch((error) => {
            console.log(error)
          })
        setWalletCreate(false)


      })
      .catch((error) => {
        console.log(error)
      })


  }


  const [formDataDeposit, setFormDataDeposit] = useState({
    payment_type: "",
    amount: "",
    wallet: "",
    phone: "",
    deposit_wallet: "",
  });

  const [paymentTypeDeposit, setPaymentTypeDeposit] = useState('');

  const handleChangeRoDeposit = (event) => {
    const { name, value } = event.target;

    if (name === "payment_type") {
      setPaymentTypeDeposit(value);
    }

    setFormDataDeposit((prevFormDeposit) => ({
      ...prevFormDeposit,
      [name]: value,
    }));
  };


  

  const [walletDeposit, setWalletDeposit] = useState(false)


  const handleWalletDeposit = () => {
    setWalletDeposit(true)

  }
  const handleWalletDepositClose = () => {
    setWalletDeposit(false)

  }

  
  const [airtimeModal, setAirimeModal] = useState(false)


  const handleOpenAirtime = () => {
    setAirimeModal(true)

  }
  const handleCloseAirtime = () => {
    setAirimeModal(false)

  }

  function handleDeposit(e) {
    e.preventDefault();


    let phone_number
    if (formDataDeposit.phone.startsWith('0')) {
      phone_number = '254' + formDataDeposit.phone.slice(1);
    }
    // transfer wallet to wallet for this
    if (formDataDeposit.payment_type === 'wallet') {
      // inbound transaction save it

      serverUrl.get(`/officers_management/wallet-account-balance?wallet=${formDataDeposit.deposit_wallet}`, config)
        .then((res) => {
          const current_balance = parseInt(res.data.results[0].wallet_balance) + parseInt(formDataDeposit.amount)
          const data = {
            franchise: franchiseFound,
            wallet: formDataDeposit.deposit_wallet,
            current_balance: parseInt(res.data.results[0].wallet_balance) + parseInt(formDataDeposit.amount),
            transcation_type: "inbound",
            amount: formDataDeposit.amount,
            status: "success",
            reason: "Succesfully Transfered"

          }

          // post the data
          serverUrl.post(`/officers_management/wallet-transactions/`, data, config)
            .then((res) => {
              toast.success('Succesfully done the transactions')
              setWalletDeposit(false)
            })
            .catch((error) => {
              console.log(error)
            })

        })
        .catch((error) => {
          console.log(error)
        })

      // outbound transaction save it
      serverUrl.get(`/officers_management/wallet-account-balance?wallet=${formDataDeposit.wallet}`, config)
        .then((res) => {
          const current_balance = parseInt(res.data.results[0].wallet_balance) - parseInt(formDataDeposit.amount)
          const formDataDepositWallet = new FormData();
          formDataDepositWallet.append('franchise', franchiseFound);
          formDataDepositWallet.append('wallet', formDataDeposit.wallet);
          formDataDepositWallet.append('current_balance', current_balance);
          formDataDepositWallet.append('amount', formDataDeposit.amount);
          formDataDepositWallet.append('transcation_type', 'outbound');
          formDataDepositWallet.append('status', 'success');
          formDataDepositWallet.append('reason', 'Succesfully Transfered');


          // post the data
          serverUrl.post(`/officers_management/wallet-transactions/`, formDataDepositWallet, config)
            .then((res) => {
            })
            .catch((error) => {
              console.log(error)
            })

        })
        .catch((error) => {
          console.log(error)
        })


    }

    else if (formDataDeposit.payment_type === 'mpesa') {
      serverUrl.get(`/officers_management/wallet-account-balance?wallet=${formDataDeposit.deposit_wallet}`, config)
        .then((res) => {
          const current_balance = parseInt(res.data.results[0].wallet_balance)
          const formDataDepositWallet = new FormData();
          formDataDepositWallet.append('franchise_id', franchiseFound);
          formDataDepositWallet.append('wallet_id', formDataDeposit.deposit_wallet);
          formDataDepositWallet.append('phone_number', phone_number);
          formDataDepositWallet.append('current_balance', current_balance);
          formDataDepositWallet.append('amount', formDataDeposit.amount);

          // post the data
          serverUrl.post(`/officers_management/deposit-wallet/`, formDataDepositWallet, config)
            .then((res) => {
              toast.success('Request is succesful kindly wait for you to input your pin')
              setWalletDeposit(false)
            })
            .catch((error) => {
              console.log(error)
            })

        })
        .catch((error) => {
          console.log(error)
        })

    }


  }


  return (
    <>
      <SideBar />
      <Header />
      <Container fluid className='wallet'>
        <div className='claimsCard'>
          <div className='totalpending'>
            <div className='cards1'>
              <Card className='totalCard'>
                <div className=''>
                  <Image src={WalletNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                  <div className='ms-3'>

                    <h2 className='text-white'>Total Field Officers</h2>
                    <span className='text-white'>{roTotal} Field Officer(s)</span>
                  </div>
                </div>

              </Card>

              {/* <Card className='subscription'>
                <div className=''>
                  <Image src={WalletImage} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                  <div className='ms-3'>
                    <h2>Buy Airtime</h2>
                    <span>Ksh. {paymentAmount && paymentAmount.toLocaleString('en-US')}</span>
                      <Button  style={{ marginTop: "0px", left: "75px" }} className='btn btn-sm bg-white text-dark border-0 airtime'>Buy Airtime</Button>

                  </div>
                </div>

              </Card> */}


              <Card className='subscription 2'>
                <div className=''>
                  <Image src={WalletImage} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                  <div className='ms-3'>
                    <h2>Current Subscription</h2>
                    <span>Ksh. {paymentAmount && paymentAmount.toLocaleString('en-US')}/ Month -- <b className='text-danger text-capitalize'>{walletStatus.subscription_status}</b></span>
                    <Link className='text-decoration-none text-dark' to={`/subscription/${franchiseFoundSubscription.id}`}>
                      <Button style={{ marginTop: "0px", left: "120px" }} className='btn btn-sm bg-white w-auto text-dark border-0'>View</Button>

                    </Link>
                  </div>
                </div>

              </Card>
            </div>

            <div className='cards2'>


              <Card className='pendingCard'>
                <div className=''>
                  <Image src={WalletImage} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                  <div className='ms-3'>
                    <h2>Field Officer Account Top Up</h2>
                    <span>{paymentAmount && paymentAmount.toLocaleString('en-US')} Officers</span>

                    <Button onClick={handleROTOPUP} className='btn btn-sm bg-white text-dark w-10 border-0'>Topup</Button>
                  </div>
                </div>

              </Card>
              <Card className='assignedCard'>
                <div className=''>
                  <Image src={WalletImage} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                  <div className='ms-3'>
                    <h2>Topup My Account</h2>
                    <span>Ksh. {paymentAmount && paymentAmount.toLocaleString('en-US')}</span>

                    <Button onClick={handleWalletDeposit} className='btn btn-sm bg-white text-dark w-10 border-0'>Deposit</Button>
                  </div>
                </div>

              </Card>
            </div>

          </div>


        </div>



        <div className='d-flex justify-content-between mb-2'>

          <h5>My Wallet Accounts</h5>
          <Button style={{ height: "7vh" }} className='btn btn-sm btn-warning add' onClick={() => handleWalletCreate()}>
            Add Wallet
          </Button>

        </div>

        <div className='taskDisplay'>

          <Table responsive>
            <thead>
              <tr className="table">
                <th>Name</th>
                <th>Account Number</th>
                <th>Account Balance</th>
                <th>Account Type</th>
                <th>Wallet Transactions</th>
              </tr>
            </thead>
            <tbody>
              {franchiseWallets.map((wallet) => (
                <tr key={wallet.id}>
                  <td>{wallet.name}</td>
                  <td>{wallet.account_number}</td>
                  <td>Ksh. {wallet.balance && wallet.balance.toLocaleString('en-us')}</td>
                  <td className='text-capitalize'>{wallet.account_type}</td>

                  <td>
                    <Link className='text-primary' to={`/wallet-transactions/${wallet.id}`}>
                      Transactions
                    </Link>
                  </td>

                </tr>
              ))}
            </tbody>
          </Table>

        </div>
        {/* modal to add wallet */}
        <Modal show={walletCreate} onHide={handleWalletClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add a wallet account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleAddAccount}>
              <div className="input-icons">
                <i className="fa fa-hashtag icon"></i>
                <FormControl
                  className="input-field"
                  type="text"
                  placeholder="Wallet Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <Modal.Footer>
                <Button variant="primary" type='submit'>
                  Save
                </Button>
                <Button onClick={handleWalletClose} variant='secondary'>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>

        </Modal>

        {/* modal to top up ro */}
        <Modal show={roTopUp} onHide={handleROTOPUPClose}>
          <Modal.Header closeButton>
            <Modal.Title>Top up Field Officer account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleAddRoAccount}>
              <label>Field Officer  Account</label>
              <FormSelect
                className="formProperty"
                name='account_type'
                value={formDatarRO.account_type}
                onChange={handleChangeRo}
                required
              >
                <option disabled value=''>
                  Select Field Officer Account
                </option>
                {franchiseROWallets.map((ro) => (
                  <option value={ro.id}>{ro.name}</option>
                ))}

              </FormSelect>
              <br></br>
              <label>Enter Amount</label>

              <div className="input-icons">
                <i className="fa fa-hashtag icon"></i>
                <FormControl
                  className="input-field"
                  type="text"
                  placeholder="Enter amount"
                  name="amount"
                  value={formDatarRO.amount}
                  onChange={handleChangeRo}
                  required
                />
              </div>
              <br></br>

              <label>Top up method</label>
              <FormSelect
                className="formProperty"
                name='payment_type'
                value={formData.payment_type}
                onChange={handleChangeRo}
                required
              >
                <option disabled selected value=''>
                  Select Top up method
                </option>
                <option value='wallet'>
                  Wallet
                </option>
                <option value='mpesa'>
                  Mpesa
                </option>
              </FormSelect>
              <br></br>

              {paymentType === 'wallet' ?
                <>
                  <label>From wallet</label>
                  <FormSelect
                    className="formProperty"
                    name='wallet'
                    value={formDatarRO.wallet}
                    onChange={handleChangeRo}
                    required
                  >
                    <option disabled value=''>
                      Select Wallet Account
                    </option>
                    {franchiseWallets.map((wallet) => (
                      <option key={wallet.id} value={wallet.id} disabled={wallet.balance === 0}>
                        {wallet.name}
                        {wallet.balance === 0 && (
                          <span style={{ color: 'red', marginLeft: '10px' }}> - insufficient funds</span>
                        )}
                      </option>
                    ))}
                  </FormSelect>
                </>


                : paymentType === 'mpesa' ?

                  <>
                    <label>Enter Phone number</label>
                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <FormControl
                        className="input-field"
                        type="text"
                        placeholder="Enter Phone Number"
                        name="name"
                        value={formDatarRO.phone}
                        onChange={handleChangeRo}
                        required
                      />
                    </div>
                  </>
                  :
                  <></>

              }






              <Modal.Footer>
                <Button className='btn btn-sm btn-warning' variant="primary" type='submit'>
                  Save
                </Button>
                <Button className='btn btn-sm' onClick={handleROTOPUPClose} variant='secondary'>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>

        </Modal>

        {/* modal to deposit to wallet */}
        <Modal show={walletDeposit} onHide={handleWalletDepositClose}>
          <Modal.Header closeButton>
            <Modal.Title>Deposit to wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form onSubmit={handleDeposit}>
              <label>Deposit to wallet</label>
              <FormSelect
                className="formProperty"
                name="deposit_wallet"
                value={formDataDeposit.deposit_wallet}
                onChange={handleChangeRoDeposit}
                required
              >
                <option disabled value="">
                  Select Wallet
                </option>
                {franchiseWallets.map((wallet) => (
                  <option key={wallet.id} value={wallet.id}>
                    {wallet.name}

                  </option>
                ))}

              </FormSelect>
              <br />

              <label>Payment Method</label>
              <FormSelect
                className="formProperty"
                name="payment_type"
                value={formDataDeposit.payment_type}
                onChange={handleChangeRoDeposit}
                required
              >
                <option disabled value="">
                  Select deposit method
                </option>
                <option value="wallet">
                  Wallet
                </option>
                <option value="mpesa">
                  Mpesa
                </option>
              </FormSelect>
              <br />

              {paymentTypeDeposit === 'wallet' ? (
                <>
                  <label>From Wallet</label>
                  <FormSelect
                    className="formProperty"
                    name="wallet"
                    value={formDataDeposit.wallet}
                    onChange={handleChangeRoDeposit}
                    required
                  >
                    {franchiseWallets.every(wallet => wallet.balance === 0) ? (
                      <option disabled value="">
                        No wallet with sufficient funds
                      </option>
                    ) : (
                      <>
                        <option disabled value="">
                          Select Wallet Account
                        </option>
                        {franchiseWallets
                          .filter(wallet => wallet.balance > 0)
                          .map(wallet => (
                            <option key={wallet.id} value={wallet.id}>
                              {wallet.name}
                            </option>
                          ))}
                      </>
                    )}
                  </FormSelect>
                  <br />
                </>
              ) : (
                <>
                  <label>Phone Number</label>
                  <div className="input-icons">
                    <i className="fa fa-hashtag icon"></i>
                    <FormControl
                      className="input-field"
                      type="number"
                      placeholder="Enter phone number"
                      name="phone"
                      value={formDataDeposit.phone}
                      onChange={handleChangeRoDeposit}
                      required
                    />
                  </div>
                  <br />
                </>
              )}

              <label>Enter Amount</label>
              <div className="input-icons">
                <i className="fa fa-hashtag icon"></i>
                <FormControl
                  className="input-field"
                  type="number"
                  placeholder="Enter amount"
                  name="amount"
                  value={formDataDeposit.amount}
                  onChange={handleChangeRoDeposit}
                  required
                />
              </div>

            </Form>


          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-warning" variant="primary" onClick={handleDeposit}>
              Save
            </Button>
            <Button className="btn btn-sm" onClick={handleWalletDepositClose} variant="secondary">
              Close
            </Button>
          </Modal.Footer>

        </Modal>


        {/* modal for buy airtime */}
        <Modal show={airtimeModal} onHide={handleCloseAirtime}>
          <Modal.Header closeButton>
            <Modal.Title>Buy Airtime</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form onSubmit={handleDeposit}>
              <label>Deposit to wallet</label>
              <FormSelect
                className="formProperty"
                name="deposit_wallet"
                value={formDataDeposit.deposit_wallet}
                onChange={handleChangeRoDeposit}
                required
              >
                <option disabled value="">
                  Select Wallet
                </option>
                {franchiseWallets.map((wallet) => (
                  <option key={wallet.id} value={wallet.id}>
                    {wallet.name}

                  </option>
                ))}

              </FormSelect>
              <br />

              <label>Payment Method</label>
              <FormSelect
                className="formProperty"
                name="payment_type"
                value={formDataDeposit.payment_type}
                onChange={handleChangeRoDeposit}
                required
              >
                <option disabled value="">
                  Select deposit method
                </option>
                <option value="wallet">
                  Wallet
                </option>
                <option value="mpesa">
                  Mpesa
                </option>
              </FormSelect>
              <br />

              {paymentTypeDeposit === 'wallet' ? (
                <>
                  <label>From Wallet</label>
                  <FormSelect
                    className="formProperty"
                    name="wallet"
                    value={formDataDeposit.wallet}
                    onChange={handleChangeRoDeposit}
                    required
                  >
                    {franchiseWallets.every(wallet => wallet.balance === 0) ? (
                      <option disabled value="">
                        No wallet with sufficient funds
                      </option>
                    ) : (
                      <>
                        <option disabled value="">
                          Select Wallet Account
                        </option>
                        {franchiseWallets
                          .filter(wallet => wallet.balance > 0)
                          .map(wallet => (
                            <option key={wallet.id} value={wallet.id}>
                              {wallet.name}
                            </option>
                          ))}
                      </>
                    )}
                  </FormSelect>
                  <br />
                </>
              ) : (
                <>
                  <label>Phone Number</label>
                  <div className="input-icons">
                    <i className="fa fa-hashtag icon"></i>
                    <FormControl
                      className="input-field"
                      type="number"
                      placeholder="Enter phone number"
                      name="phone"
                      value={formDataDeposit.phone}
                      onChange={handleChangeRoDeposit}
                      required
                    />
                  </div>
                  <br />
                </>
              )}

              <label>Enter Amount</label>
              <div className="input-icons">
                <i className="fa fa-hashtag icon"></i>
                <FormControl
                  className="input-field"
                  type="number"
                  placeholder="Enter amount"
                  name="amount"
                  value={formDataDeposit.amount}
                  onChange={handleChangeRoDeposit}
                  required
                />
              </div>

            </Form>


          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-sm btn-warning" variant="primary" onClick={handleDeposit}>
              Save
            </Button>
            <Button className="btn btn-sm" onClick={handleWalletDepositClose} variant="secondary">
              Close
            </Button>
          </Modal.Footer>

        </Modal>

      </Container>

    </>
  )
}

export default Wallet