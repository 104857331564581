import React, { useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import '../assets/scss/MapComponent.scss';

const MapComponentLocation = ({ google, onLocationSelected }) => {
  const [address, setAddress] = useState('');
  const [position, setPosition] = useState({ lat: -1.2921, lng: 36.8219 }); // Default position (Nairobi, Kenya)

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    setAddress(address);
    setPosition(latLng);
    onLocationSelected(address); // Pass the selected address to the callback
  };

  const updatePositionAndAddress = async (lat, lng) => {
    const latLng = { lat, lng };
    setPosition(latLng);

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const addressComponents = results[0].address_components;
        let formattedAddress = '';

        for (const component of addressComponents) {
          if (component.types.includes('locality')) {
            formattedAddress = component.long_name;
            break;
          } else if (component.types.includes('sublocality')) {
            formattedAddress = component.long_name;
            break;
          } else if (component.types.includes('neighborhood')) {
            formattedAddress = component.long_name;
            break;
          }
        }

        if (!formattedAddress) {
          formattedAddress = results[0].formatted_address;
        }

        setAddress(formattedAddress);
        onLocationSelected(formattedAddress); // Pass the selected address to the callback
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  };

  const onMarkerDragEnd = async (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    updatePositionAndAddress(lat, lng);
  };

  const onMapClick = async (mapProps, map, clickEvent) => {
    const lat = clickEvent.latLng.lat();
    const lng = clickEvent.latLng.lng();
    updatePositionAndAddress(lat, lng);
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{ componentRestrictions: { country: 'ke' } }} // Restrict to Kenya
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Location...',
                className: 'location-search-input mapLocationInput',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, { className, style })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      <Map
        google={google}
        zoom={14}
        center={position}
        className='position-absolute mapLocationdesign'
        onClick={onMapClick}
      >
        <Marker
          position={position}
          draggable={true}
          onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
        />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDxKuaBgR44-dZ-vZGGWY4YnL4NPEEuri0'
})(MapComponentLocation);
