import axios from "axios";

export let baseUrl;

// baseUrl = "http://127.0.0.1:8000/api";
baseUrl = "https://usalamafieldtracker.app/api";


export const serverUrl = axios.create({
  baseURL: baseUrl,
});
