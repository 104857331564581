import React, { useEffect, useState } from 'react';
import franchiseImage from '../assets/images/franchiseImage.jpg';
import { serverUrl } from '../serverUrl';
import { jwtDecode } from 'jwt-decode';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
const yellowHappyFace = '\u{1F600}';

const SelectFranchise = () => {
    const [selectedFranchise, setSelectedFranchise] = useState('');
    const [franchises, setFranchises] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem('authTokens');
        let access = null;
        let userId = null;

        if (accessToken) {
            try {
                const parsedTokens = JSON.parse(accessToken);
                access = parsedTokens?.access;
                const decodedToken = jwtDecode(access);
                userId = decodedToken?.user_id;
            } catch (error) {
                console.error('Error parsing or decoding token:', error);
            }
        }

        const config = {
            headers: { Authorization: `Bearer ${access}` },
        };

        const fetchFranchises = async () => {
            try {
                const response = await serverUrl.get(`/officers_management/admins/?user=${userId}`, config);
                const franchiseList = response.data.results.map(result => result.franchise);
                setFranchises(franchiseList);
            } catch (error) {
                console.error('Error fetching franchises:', error);
            }
        };

        if (userId) {
            fetchFranchises();
        }
    }, []);

    const handleSelectChange = (event) => {
        const selected = event.target.value;
        setSelectedFranchise(selected);

        if (secretKey) {
            const encryptedFranchise = CryptoJS.AES.encrypt(selected, secretKey).toString();
            localStorage.setItem('selectedFranchise', encryptedFranchise);
            navigate('/dashboard');
            toast.success(`Franchise selected successfully! Enjoy the system ${yellowHappyFace}`);
        } else {
            console.error('Secret key is not defined');
        }
    };

    return (
        <div
            style={{
                backgroundImage: `url(${franchiseImage})`,
                backgroundSize: 'cover',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1,
                }}
            ></div>
            <div
                style={{
                    position: 'relative',
                    zIndex: 2,
                    color: 'white',
                    textAlign: 'center',
                }}
            >
                <h1>Welcome to the Usalama Field Officer Tracking System!</h1>
                <p className='w-75 m-auto'>Our user-friendly platform helps you monitor and manage field officer locations in real-time. Get started by selecting your franchise and explore our features to ensure your team is always where they need to be. Welcome aboard!</p>
                <p>Select your franchise to get started.</p>
                <select
                    value={selectedFranchise}
                    onChange={handleSelectChange}
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '20px',
                        border: 'none',
                        borderRadius: '10px',
                        width: 'fit-content',
                        marginTop: '20px',
                    }}
                >
                    <option value="" disabled>Select a franchise</option>
                    {franchises.map((franchise, index) => (
                        <option key={index} value={franchise.id}>{franchise.name}</option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default SelectFranchise;
