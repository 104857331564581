import React, { useEffect, useRef, useState, useCallback } from 'react';
import SideBar from '../components/SideBar';
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  Nav,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import '../assets/scss/Profile.scss';
import cameraFill from '../assets/images/camera2.png';
import Header from '../components/Header';
import { serverUrl } from '../serverUrl';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import Cropper from 'react-easy-crop';
import debounce from 'lodash.debounce';
import telephone from '../assets/images/telephone.png';
import Mail from '../assets/images/mail.png';
import User from '../assets/images/user.png';

function Profile() {
  const [show, setShow] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const fileInputRef = useRef(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [userDetails, setUserDetails] = useState({});

  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };

  const decodedToken = jwtDecode(access);
  const userId = decodedToken.user_id;

  useEffect(() => {
    setIsloading(true);
    const fetchData = async () => {
      try {
        const response = await 
        serverUrl
        .get(
          `/user/edit-user/${userId}`,
          config
        );
        setUserDetails(response.data);

        setIsloading(false);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setIsloading(false);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (e) => {
e.preventDefault()
    const data = {
      full_name : userDetails.full_name,
      email : userDetails.email,
      phone_number : userDetails.phone_number,
    }


    try{
      serverUrl.patch(`/user/edit-user/${userId}`,data,config)
      setShow(false);
      setIsloading(false);

      const response = serverUrl.get(`/user/edit-user/${userId}`,config);
      setUserDetails(response);
      toast.success('User details edited successfully');
      

    }catch(error){
      console.error('Error editing task:', error);
      toast.error('Failed to edit user details. Please try again later.');
    }
  }

  const handleChange = (e) => {
    const { name , value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };



  // console.log(userDetails)
  const handleImageClick = () => {
    console.log('Camera button clicked');
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = useCallback(
    debounce(async (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
      const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImg);
      uploadCroppedImage(croppedImg);
    }, 500),
    [imageSrc]
  );

  const handleCropChange = (crop) => {
    setCrop(crop);
  };

  const handleZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const uploadCroppedImage = async (croppedImageBlob) => {
    try {
      const formData = new FormData();
      formData.append('profile_picture', croppedImageBlob, 'croppedImage.jpg');

      const response = await serverUrl.put(
        `/user/edit-user/${userId}`,
        formData,
        config
      );
      if (response.status === 200) {
        const updatedProfilePictureUrl = response.data.profile_picture;
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          profile_picture: updatedProfilePictureUrl,
        }));
        toast.success('Profile picture updated successfully!');
      }
    } catch (error) {
      console.error('Error uploading cropped image:', error);
      toast.error('Failed to update profile picture.');
    }
  };
  // active tab
  const [activeTab, setActiveTab] = useState('profile');

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="logo"></div>
      </div>
    );
  }

  return (
    <>
      <SideBar />
      <Header />
      <div className='skew-div'></div>
      <Container className="Profile">
        <Row className='profile-tabs'>
          <Col md={4} className="tabs-container">
            <Nav variant="pills" defaultActiveKey="link-0">
              <Nav.Item onClick={() => setActiveTab('profile')}>
                <Nav.Link  eventKey='link-0' className='franchise-btn'>Profile</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item onClick={() => setActiveTab('franchise')}>
                <Nav.Link eventKey="link-1" className='franchise-btn'>Franchise</Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>
          <Col md={8}>
            {activeTab === 'profile' ? 
            <div className='user-details'>
              <div className='profile-image'>
              <img src={userDetails.profile_picture} alt='Profile' className='profile-pic' style={{ borderRadius: '50%' }} />
              <i class="bi bi-camera camera-icon" onClick={handleImageClick} style={{ cursor: 'pointer', zIndex: 10, position: "absolute" }}></i>
              {/* <img
                className='camera'
                src={cameraFill}
                alt='camera'
                onClick={handleImageClick}
                style={{ cursor: 'pointer', zIndex: 10, position: "absolute" }}
              /> */}
                {imageSrc && (
                      <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={handleCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={handleZoomChange}
                      />
                    )}

                    <input
                      type='file'
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      accept='image/*'
                      onChange={handleFileChange}
                    />


              </div>
              <div className='details'>
               <div className='user'>
               <img src={User} alt='user'/>              
               <p>{userDetails.full_name}</p>
               </div>
              <div className='phone'>
                <img src={telephone} alt='phone'/>
                <p>{userDetails.phone_number}</p>
              </div>
              <div className='email'>
                <img src={Mail} alt='phone'/>
                <p>{userDetails.email}</p>
              </div>
              <div className='edit-btn'>
                    <Button onClick={()=>{setShow(true)}}>
                      Edit
                      <i className="bi bi-pen"></i>
                    </Button>
              </div>

            </div>
              </div>
              
            : 
            <p>franchise</p>
            }
            {/* <div className="details"></div> */}
          </Col>
        </Row>
      </Container>

     {userDetails &&
     
     <Modal show={show} onHide={()=>{setShow(false)}}>
     <Modal.Header closeButton>
       <Modal.Title>Edit User Details</Modal.Title>
     </Modal.Header>
     <Form>
     <Modal.Body style={{ overflowY: "scroll", maxHeight: "70vh" }}>
      
         <Form.Group>
           <Form.Label>Full Name</Form.Label>
           <Form.Control
             type='text'
             name='full_name'
             value={userDetails.full_name}
             onChange={handleChange}
             // isInvalid={!!formErrors.full_name}
           />
           <FormControl.Feedback type='invalid'>
             {/* {formErrors.full_name} */}
           </FormControl.Feedback>
         </Form.Group>
         <Form.Group>
           <Form.Label>Email</Form.Label>
           <Form.Control
             type='email'
             name='email'
             value={userDetails.email}
             onChange={handleChange}
             // isInvalid={!!formErrors.email}
           />
           <FormControl.Feedback type='invalid'>
             {/* {formErrors.email} */}
           </FormControl.Feedback>
         </Form.Group>
         <Form.Group>
           <Form.Label>Phone Number</Form.Label>
           <Form.Control
             type='number'
             name='phone_number'
             value={userDetails.phone_number}
             onChange={handleChange}
             // isInvalid={!!formErrors.phone_number}
           />
           <FormControl.Feedback type='invalid'>
             {/* {formErrors.phone_number} */}
           </FormControl.Feedback>
         </Form.Group>
         
       
     </Modal.Body>
     <Modal.Footer>
     <Button variant="secondary" onClick={()=>{setShow(false)}} >
           Close
         </Button>
         <Button style={{backgroundColor:"#DC3545", border:"none"}} variant="primary" type="submit" onClick={handleEdit}>
           Save Changes
         </Button>
     </Modal.Footer>
     </Form>
   </Modal>
     
     }

    </>
  );
}
export default Profile;
