import React, { useState, useEffect } from 'react'
import { Container, Card, Button, Form, FormControl, Table, Modal, FormGroup, ModalBody, Image, ModalHeader, FormSelect, ModalFooter } from 'react-bootstrap'
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import '../assets/scss/Claims.scss'
import { serverUrl } from '../serverUrl'
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import MapComponentClaim from '../components/MapComponentClaim';
import TaskAll from '../assets/images/claim (2).png'
import TaskAllNew from '../assets/images/claim.png'
import CryptoJS from 'crypto-js';
import '../assets/scss/LoaderDisplay.scss';

function Claims() {
  const [isLoading, setIsloading] = useState(false)
  const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
  const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };
  const [searchQuery, setSearchQuery] = useState('');
  const [claimCount, setClaimCount] = useState(0);
  const [totalClaimSum, setTotalClaimSum] = useState(0);
  const [totalClaimPending, setTotalClaimPending] = useState(0);
  const [originalClaims, setOriginalClaims] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [selectedClaims, setSelectedClaims] = useState({})
  const [isDescending, setIsDescending] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showClaim, setShowClaim] = useState(false);
  const [selectedClaimStatus, setSelectedClaimStatus] = useState(false);
  const [claims, setClaims] = useState([]); // Filtered claims to display
  const [selectedClaim, setSelectedClaim] = useState('');



  const toggleOrder = () => {
    setIsDescending(!isDescending);
    const reversed = setClaims([...claims].reverse());
  };

  const handleopenmodal = (claim) => {
    setShowDetailsModal(true)
    setSelectedClaims(claim)
  }

  const [showEditModal, setShowEditModal] = useState(false)
  const handleEditmodal = (claim) => {
    setShowEditModal(true)
    setSelectedClaims(claim)
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleDeleteModal = (claim) => {
    setShowDeleteModal(true);
    setSelectedClaims(claim);
  };



  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  // const [claims, setClaims] = useState([])
  // display the selected franchise
  const selectedFranchise = localStorage.getItem('selectedFranchise');
  const decryptedData = decryptData(selectedFranchise, secretKey);

  useEffect(() => {
    setIsloading(true)
    const fetchData = async () => {
      try {


        const response = await serverUrl.get(`/officers_management/claims/?franchise_id=${decryptedData}`, config);
        const tasks = response.data.results;
        const sortedClaims = tasks.sort((a, b) => {
          return new Date(b.task_date) - new Date(a.task_date);
        });
        const totalEstimatedAmount = tasks.reduce((total, task) => total + task.estimated_amount, 0);
        const completedTasks = tasks.filter(task => task.claim_status === 'completed');
        const totalCompletedEstimatedAmount = completedTasks.reduce((total, task) => total + task.estimated_amount, 0);

        setTotalClaimSum(totalEstimatedAmount)
        setTotalClaimPending(totalCompletedEstimatedAmount)


        setClaimCount(response.data.count)
        setClaims(sortedClaims);
        setOriginalClaims(sortedClaims);
        setIsloading(false)

      } catch (error) {
        console.error('Error fetching tasks data:', error);
        setIsloading(false)
      }
    };

    fetchData();
  }, []);
  // console.log(claims)

  const flattenObject = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? `${prefix}.` : '';
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        Object.assign(acc, flattenObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchQuery(searchQuery);
    if (searchQuery === '') {
      setClaims(originalClaims);
    } else {
      const filteredTasks = originalClaims.filter((task) => {
        const flatTask = flattenObject(task);
        return Object.values(flatTask).some(
          (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery)
        );
      });
      setClaims(filteredTasks);
    }
  };



  const handleEditSubmit = () => {
    if (!selectedClaims) return;

    const data = {
      task_date: selectedClaims.task_date,
      task_type: selectedClaims.task_type,
      estimated_amount: selectedClaims.estimated_amount,
      customer_location: selectedClaims.customer_location,
      task_status: selectedClaims.task_status,
      from_latitude: selectedClaims.from_latitude,
      from_location: selectedClaims.from_location,
      to_longitude: selectedClaims.to_longitude,
      to_location: selectedClaims.to_location,
      ride_duration: selectedClaims.ride_duration,
      ride_distance: selectedClaims.ride_distance,
    };

    const accessToken = localStorage.getItem('authTokens');
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
      headers: { Authorization: `Bearer ${access}` },
    };

    serverUrl.patch(`/officers_management/claims/${selectedClaims.id}/`, data, config)
      .then((res) => {
        console.log('Claim edited successfully:', res.data);
        setShowEditModal(false);
        serverUrl.get(`/officers_management/claims/?franchise_id=${decryptedData}`, config)
          .then((res) => {
            setClaims(res.data.results)
          })
        toast.success('Claim edited successfully');
      })
      .catch((error) => {
        console.error('Error editing claim:', error);
        toast.error('Failed to edit claim. Please try again later.');
      });
  };

  const handledeleteSubmit = () => {
    serverUrl
      .delete(`/officers_management/claims/${selectedClaims.id}/`, config)
      .then((res) => {
        // Update the tasks list or perform any other necessary actions upon successful submission
        console.log('Task deleted successfully:');
        // Close the edit modal
        setShowDeleteModal(false);
        // Fetch updated task data from the server
        serverUrl
          .get(`/officers_management/claims/?franchise_id=${decryptedData}`, config)
          .then((res) => {
            setClaims(res.data.results); // Update the tasks state with the new data
            // Show a success message using toast
            toast.success('Task deleted successfully');
            setShowDeleteModal(false);
          })
          .catch((error) => {
            console.error('Error deleting task data:', error);
            // Show an error message using toast
            toast.error('Failed to delete task data.');
          });
      })
      .catch((error) => {
        console.error('Error editing task:', error);
        // Show an error message using toast
        toast.error('Failed to edit task. Please try again later.');
      });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Maximum of 10 tasks per page

  // Calculate index of the first and last item of the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current items to display
  const currentItems = claims.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  const totalPages = Math.ceil(claims.length / itemsPerPage);

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedClaimType, setSelectedClaimType] = useState('');
  const [selectedFilterType, setSelectedFilterType] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const years = Array.from({ length: 25 }, (_, i) => 2000 + i); // List of years from 2000 to 2024
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]; // List of month names




  const handleFilter = () => {

    // Create a copy of the original tasks array to apply filters on
    let filteredClaims = [...originalClaims];

    // Apply filters based on selected criteria

    // Filter by Task Status

    if (selectedStatus !== '') {
      filteredClaims = filteredClaims.filter(claim => claim.claim_status === selectedStatus);
    }

    // Filter by Task Type
    if (selectedClaimType !== '') {
      filteredClaims = filteredClaims.filter(claim => claim.trip.task.task_type === selectedClaimType);
    }


    console.log(selectedClaimType)
    console.log(filteredClaims)

    // Filter by Date
    if (selectedFilterType === 'year' && selectedYear !== '') {
      filteredClaims = filteredClaims.filter(claim => new Date(claim.claim_date).getFullYear() === parseInt(selectedYear));
    } else if (selectedFilterType === 'month' && selectedMonth !== '') {
      filteredClaims = filteredClaims.filter(claim => new Date(claim.claim_date).getMonth() === parseInt(selectedMonth) - 1);
    }

    else if (selectedFilterType === 'specificDate' && selectedDate !== '') {
      filteredClaims = filteredClaims.filter(claim => claim.claim_date === selectedDate);
    }

    // Set the filtered tasks as the new tasks to display
    setClaims(filteredClaims);

    setShowFilterModal(false);
  };
  const [selectedCategory, setSelectedCategory] = useState('')
  const [amountClaim, setAmountSet] = useState('')

  const handleSaveClaim = () => {
    const data = {

    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    // const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString(undefined, options);
  };



  const formatString = (str) => {
    // Split the string by underscore
    const words = str.split('_');

    // Capitalize the first letter of each word and join them with a space
    const formattedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return formattedString;
  };

  const formatDistance = (distance) => {
    if (!distance) return "";
    let formattedDistance = distance;

    if (distance >= 1000) {
      formattedDistance = (distance / 1000).toFixed(2);
    } else {
      formattedDistance = (distance / 1000).toFixed(2);
    }

    return `${formattedDistance} Kilometers`;
  };

  if (isLoading) {
    return (
      <div className='loading-screen'>
        <div className='logo'>

        </div>
      </div>
    )
  }


  return (
    <>
      <SideBar />
      <Header />
      <Container fluid className='claims'>
        <div className='claimsCard'>
          <div className='claims-card1'>
            <Card className="totalCard">

              <div className="">
                <Image src={TaskAll} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%", marginLeft: "15px" }} />


                <div className='ms-3'>
                  <h2 className='text-white'>Total Claims Made</h2>
                  <span className='text-white'>{claimCount}</span>

                </div>
              </div>

            </Card>

            <Card className="assignedCard">
              <div className=''>
                <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                <div className='ms-3'>
                  <h2>Total Claims Amount</h2>
                  <span>Ksh.{totalClaimSum && totalClaimSum.toLocaleString('en-US')}</span>
                </div>
              </div>
            </Card>
          </div>

          <div className='cards-claims2'>
            <Card className="assignedCard approved">
              <div className=''>
                <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                <div className='ms-3'>
                  <h2>Amounts Approved</h2>
                  <span>Ksh.{totalClaimPending && totalClaimPending.toLocaleString('en-US')}</span>
                </div>
              </div>
            </Card>

            <Card className="assignedCard pending">
              <div className=''>
                <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

                <div className='ms-3'>
                  <h2>Pending Amounts</h2>
                  <span>
                    Ksh.{(parseInt(totalClaimSum) - parseInt(totalClaimPending)).toLocaleString()}
                  </span>

                </div>
              </div>
            </Card>
          </div>

        </div>

        <div className="d-flex justify-content-between topFiltersData">


          <div className="d-flex justify-content-between w-100 filterTopsData">

            <Button onClick={() => setShowClaim(true)} className='btn btn-sm d-none bg-transparent border-danger text-danger' style={{ borderRadius: "20px", height: "4vh" }}  >Add Claim</Button>


            <div>
              {selectedStatus || selectedClaimType || selectedYear || selectedDate || selectedMonth || selectedFilterType ?

                <Button className='btn btn-sm bg-danger border-danger text-white' style={{ borderRadius: "20px", height: "4vh" }} onClick={() => { setSelectedClaim(''); setSelectedStatus(''); setSelectedFilterType(''); setSelectedDate(''); setSelectedMonth(''); setSelectedFilterType(''); setClaims(originalClaims); }}>

                  Reset Filter
                </Button>

                :
                <Button className='btn btn-sm bg-transparent border-danger text-danger' style={{ borderRadius: "20px", height: "4vh" }} onClick={() => setShowFilterModal(true)}>
                  <i className="bi bi-funnel-fill me-2"></i>
                  Filter
                </Button>
              }


              <Button className="lastappdate">
                <i className="bi bi-arrow-up"></i>
                <i className="bi bi-arrow-down me-2"></i>
                <span onClick={toggleOrder}>Last Appointment Date</span>
              </Button>
            </div>


          </div>

        </div>


        {claims.length === 0 ?
          <p style={{ fontSize: "25px", marginTop: "10px" }}>No claims available</p>
          :

          <div className='taskDisplay'>
            <Form className="searchBar">
              <FormControl
                type="text"
                placeholder="Search Claim"
                onChange={handleSearch}
              />
            </Form>
            <Table responsive>

              <thead>
                <tr className='table'>
                  <th>Date</th>
                  <th>Claim Type</th>
                  <th>Mode Of Transport</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Field Officer</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {currentItems.map((claim) => (
                  <tr key={claim.id}>
                    <td>{claim.claim_date}</td>
                    {/* <td>{formatString(claim.trip.task.task_type)}</td> */}

                    <td className='text-capitalize'>{claim.claim_type}</td>

                    {claim.claim_type === 'accomodation' ? (
                      <td className='text-capitalize'>Accommodation</td>
                    ) : (
                      claim.trip ? (
                        <td className='text-capitalize'>{claim.claim_type === 'transport' ? claim.trip.travel_mode : claim.claim_type}</td>
                      ) : (
                        <td className='text-capitalize'>{claim.claim_type}</td>
                      )
                    )}


                    <td>KSH {Number(claim.estimated_amount).toLocaleString()}</td>
                    <td>

                      <span style={{ borderRadius: "20px" }} className={`btn text-white w-100 btn-sm ${claim.claim_status === 'completed' ? 'bg-success' : claim.claim_status === 'pending' ? 'bg-danger' : 'bg-warning'}`}>{claim.claim_status}</span>
                    </td>


                    <td>
                      {claim.relationship_officer?.user?.full_name}
                    </td>

                    <td className="actions">
                      <Button
                        className="btn btn-sm btn-info text-white me-2"
                        onClick={() => handleopenmodal(claim)}
                      >
                        <i className="bi bi-eye"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {claims.length > 0 && (
              <nav>
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage - 1)}>
                      {'<'}
                    </button>
                  </li>
                  <li className="page-item disabled">
                    <span className="page-link">
                      {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, claims.length)} of {claims.length}
                    </span>
                  </li>
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage + 1)}>
                      {'>'}
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </div>

        }


        {/* Details Modal */}

        <Modal
          show={showDetailsModal}
          onHide={() => setShowDetailsModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <>
              {selectedClaims?.relationship_officer?.user?.full_name ? `${selectedClaims.relationship_officer.user.full_name}'s Claims` : 'Claims'}
            </>
          </Modal.Header>

          <Modal.Body style={{ height: "70vh", overflowY: "scroll" }}>

            <Form className="formTask">
              {selectedClaims.claim_description && (
                <FormGroup>
                  <Form.Label>Claim Description</Form.Label>
                  <FormControl
                    name="claimType"
                    value={selectedClaims.claim_description}
                    readOnly
                  />
                </FormGroup>
              )}


              {selectedClaims.trip ? (
                <>
                  <FormGroup>
                    <Form.Label>Ride Duration</Form.Label>
                    <FormControl
                      name="taskStatus"
                      value={selectedClaims.trip?.ride_duration || ""}
                      readOnly
                    />
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>Ride Distance</Form.Label>
                    <FormControl
                      name="taskStatus"
                      value={selectedClaims.trip ? formatDistance(selectedClaims.trip.ride_distance) : ""}
                      readOnly
                    />
                  </FormGroup>
                  <FormGroup className='mt-2'>
                    <Form.Label>From and To Location</Form.Label>
                    <MapComponentClaim className='mt-2 mb-3' data={selectedClaims} />
                  </FormGroup>

                </>
              ) : (
                <>
                  <FormGroup>
                    <Form.Label>Estimated Amount</Form.Label>
                    <FormControl
                      name="estimatedAmount"
                      value={Number(selectedClaims.estimated_amount).toLocaleString()}
                      readOnly
                    />
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>Claim Type</Form.Label>
                    <FormControl
                      name="claimType"
                      value={selectedClaims.claim_type}
                      readOnly
                    />
                  </FormGroup>
                </>
              )}
            </Form>


          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDetailsModal(false)

              }
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>



        {/* eddit modal */}
        {selectedClaims && (

          <Modal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Edit {selectedClaims?.trip?.task && (
                  <>
                    {selectedClaims.trip.task.relationship_officer.user.full_name}'s Claims
                  </>
                )}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form className="formTask">
                <FormGroup>
                  <Form.Label>Date of Task</Form.Label>
                  <input
                    type='date'
                    className="form-control"
                    name="date"
                    value={selectedClaims.claim_date}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, claim_date: e.target.value })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Task Type</Form.Label>
                  <Form.Control
                    as="select"
                    name="taskType"
                    value={selectedClaims.claim_type}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, claim_type: e.target.value })}
                  >
                    {/* <option value="">Select Task Type</option> */}
                    <option value="transport">Transport</option>
                    <option value="Accommodation">Accommodation</option>
                  </Form.Control>
                </FormGroup>


                <FormGroup>
                  <Form.Label>Amount</Form.Label>
                  <FormControl
                    name="estimated_amount"
                    value={selectedClaims.estimated_amount}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, estimated_amount: e.target.value })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Customer Location</Form.Label>
                  <FormControl
                    name="customerLocation"
                    value={selectedClaims.trip?.task.customer_location || ""}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, trip: { ...selectedClaims.trip, task: { ...selectedClaims.trip.task, customer_location: e.target.value } } })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Task Status</Form.Label>
                  <FormControl
                    name="taskStatus"
                    value={selectedClaims.claim_status}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, claim_status: e.target.value })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>From Latitude</Form.Label>
                  <FormControl
                    name="from_latitude"
                    value={selectedClaims.trip?.from_latitude || ""}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, trip: { ...selectedClaims.trip, from_latitude: e.target.value } })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>To Latitude</Form.Label>
                  <FormControl
                    name="to_latitude"
                    value={selectedClaims.trip?.to_latitude || ""}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, trip: { ...selectedClaims.trip, to_latitude: e.target.value } })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>From Longitude</Form.Label>
                  <FormControl
                    name="from_longitude"
                    value={selectedClaims.trip?.from_longitude || ""}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, trip: { ...selectedClaims.trip, from_longitude: e.target.value } })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>To Longitude</Form.Label>
                  <FormControl
                    name="to_longitude"
                    value={selectedClaims.trip?.to_longitude || ""}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, trip: { ...selectedClaims.trip, to_longitude: e.target.value } })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>From Location</Form.Label>
                  <FormControl
                    name="from_location"
                    value={selectedClaims.trip?.from_location || ''}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, trip: { ...selectedClaims.trip, from_location: e.target.value } })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>To Location</Form.Label>
                  <FormControl
                    name="to_location"
                    value={selectedClaims.trip?.to_location || ""}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, trip: { ...selectedClaims.trip, to_location: e.target.value } })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Ride Duration</Form.Label>
                  <FormControl
                    name="ride_duration"
                    value={selectedClaims.trip?.ride_duration || ""}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, trip: { ...selectedClaims.trip, ride_duration: e.target.value } })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Ride Distance</Form.Label>
                  <FormControl
                    name="ride_distance"
                    value={selectedClaims.trip?.ride_distance || ""}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, trip: { ...selectedClaims.trip, ride_distance: e.target.value } })}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>Relationship Officer (Optional)</Form.Label>
                  <Form.Control
                    // as="select"
                    name="relationshipOfficer"
                    value={selectedClaims.trip?.task?.relationship_officer.user?.full_name || ""}
                    onChange={(e) => setSelectedClaims({ ...selectedClaims, trip: { ...selectedClaims.trip, task: { ...selectedClaims.trip.task, relationship_officer: { user: { full_name: e.target.value } } } } })}
                  >
                    {/* <option value="">Select Relationship Officer</option>
         {FormData.map((RO) => (
           <option key={RO.id} value={RO.id}>{RO.user.full_name}</option>
         ))} */}
                  </Form.Control>
                </FormGroup>

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowEditModal(false)}
              >
                Cancel
              </Button>
              <Button
                className='edit'
                variant="primary"
                onClick={handleEditSubmit}
              >
                Edit
              </Button>
            </Modal.Footer>
          </Modal>
        )}


        {/* delete modal */}
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Tasks</Modal.Title>
          </Modal.Header>
          {/* Modal content */}
          <ModalBody>
            Are you sure you want to delete this data?
          </ModalBody>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Close
            </Button>
            <Button className='delete' variant="info" onClick={handledeleteSubmit}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* modal for filtering the claims */}

        <Modal
          show={showFilterModal}
          onHide={() => setShowFilterModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter Claims</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Status filter */}
            <Form.Group controlId="formStatus">
              <Form.Label>Filter By Claim Status</Form.Label>
              <Form.Control
                as="select"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">Select status</option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>

              </Form.Control>
            </Form.Group>

            {/* Task Type filter */}
            <Form.Group controlId="formTaskType">
              <Form.Label>Filter By Task Type</Form.Label>
              <Form.Control
                as="select"
                value={selectedClaimType}
                onChange={(e) => setSelectedClaimType(e.target.value)}
              >
                <option value="">Select task type</option>
                <option value="vetting">Vetting</option>
                <option value="debt_collection">Debt Collection</option>
              </Form.Control>
            </Form.Group>


            {/* Date filter */}
            <Form.Group controlId="formDate">
              <Form.Label>Filter By Date</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Year"
                  checked={selectedFilterType === 'year'}
                  onChange={() => setSelectedFilterType('year')}
                />
                {selectedFilterType === 'year' && (
                  <Form.Control
                    as="select"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                    {/* Render the list of years from 2000 to 2024 */}
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Control>
                )}
              </div>

              <div>
                <Form.Check
                  type="radio"
                  label="Month"
                  checked={selectedFilterType === 'month'}
                  onChange={() => setSelectedFilterType('month')}
                />
                {selectedFilterType === 'month' && (
                  <Form.Control
                    as="select"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    {/* Render the list of months */}
                    <option value="" selected disabled>Select Month </option>
                    {months.map((month, index) => (
                      <option key={index} value={index + 1}>
                        {month}
                      </option>
                    ))}
                  </Form.Control>
                )}
              </div>

              <div>
                <Form.Check
                  type="radio"
                  label="Specific Date"
                  checked={selectedFilterType === 'specificDate'}
                  onChange={() => setSelectedFilterType('specificDate')}
                />
                {selectedFilterType === 'specificDate' && (
                  <Form.Control
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                )}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="dark"
              onClick={() => setShowFilterModal(false)}
            >
              Close
            </Button>
            <Button variant="warning" onClick={handleFilter}>
              Filter
            </Button>
          </Modal.Footer>
        </Modal>


        {/* modal for adding claim */}
        <Modal
          show={showClaim}
          onHide={() => setShowClaim(false)}
          centered>
          <ModalHeader closeButton>Add claim for myself</ModalHeader>
          <ModalBody>
            <Form.Group controlId="formStatus">
              <Form.Label>Select the claim category</Form.Label>
              <Form.Control
                as="select"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">Select category</option>
                <option value="transport">Transport</option>
                <option value="accomodation">Accomodation</option>
                <option value="fuel">Fuel</option>

              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formStatus">
              <Form.Label>Claim amount</Form.Label>
              <FormControl className='' type='number' placeholder='Enter the claim amount' />

            </Form.Group>

          </ModalBody>

          <ModalFooter>
            <Button className='btn btn-sm btn-dark' onClick={() => setShowClaim(false)}>Cancel</Button>
            <Button className='btn btn-sm btn-danger bg-transparent text-danger'>Add</Button>
          </ModalFooter>

        </Modal>


      </Container>
    </>
  )
}

export default Claims