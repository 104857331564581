import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import { Container, Form, FormControl, Button, Table } from 'react-bootstrap';
import '../assets/scss/PanicAlertReport.scss';
import { serverUrl } from '../serverUrl';

function RestrictedArea() {
  const [alerts, setAlerts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await serverUrl.get(
          `/officers_management/panic-alerts/`,
          config
        );
        const allPanics = response.data.results;
        const unresolvedPanics = allPanics.filter((panic) => !panic.resolved);
        const resolvedPanics = allPanics.filter((panic) => panic.resolved);

        let filteredPanics = [];
        filteredPanics = allPanics;

        // Apply search filter
        if (searchTerm) {
          filteredPanics = filteredPanics.filter(
            (alert) =>
              alert.user.full_name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              alert.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
              alert.county.county
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
          );
        }

        setAlerts(filteredPanics);
      } catch (error) {
        console.error('Error fetching panic data:', error);
      }
    };

    fetchData();
  }, [searchTerm]);
  return <div></div>;
}

export default RestrictedArea;
