import React from 'react';
import { Routes, Route, HashRouter } from 'react-router-dom';
import Dashboard from '../screens/Dashboard';
import Tasks from '../screens/Tasks';
import Claims from '../screens/Claims';
import Feedback from '../screens/Feedback';
import Panic from '../screens/Panic';
import Leaderboard from '../screens/LeaderBoard';
import Accounts from '../screens/Accounts';
import AllClaims from '../components/AllClaims';
import Login from "../auth/Login";
import Profile from '../screens/Profile';
import PanicAlertReports from '../screens/PanicAlertReports';
import RestrictedRegion from '../screens/RestrictedRegion';
import Wallet from '../screens/Wallet';
import WalletOtp from '../screens/WalletOtp';
import WalletLogin from '../screens/WalletLogin';
import WalletTransactions from '../screens/WalletTransactions';
import Subscription from '../screens/Subscription';
import RestrictedArea from '../screens/RestrictedArea';
import Invoices from '../screens/Invoices';
import EmergencyContacts from '../screens/EmergencyContacts';
import FlagsDisplay from '../screens/FlagsDisplay';
import SelectFranchise from '../screens/SelectFranchise';
import BuyAirtime from '../screens/BuyAirtime';
import Customers from '../screens/Customers';
import NotFound from '../screens/NotFound';
import { AuthProvider } from '../context/AuthContext';
import PrivateRoute from '../auth/PrivateRoute';

function WebRoutes() {
  return (
    <AuthProvider>
      <HashRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/accounts" element={<PrivateRoute><Accounts /></PrivateRoute>} />
          <Route path="/emergencycontacts" element={<PrivateRoute><EmergencyContacts /></PrivateRoute>} />
          <Route path="/tasks" element={<PrivateRoute><Tasks /></PrivateRoute>} />
          <Route path="/claims" element={<PrivateRoute><Claims /></PrivateRoute>} />
          <Route path="/feedback" element={<PrivateRoute><Feedback /></PrivateRoute>} />
          <Route path="/panic" element={<PrivateRoute><Panic /></PrivateRoute>} />
          <Route path="/leaderboard" element={<PrivateRoute><Leaderboard /></PrivateRoute>} />
          <Route path="/all-claims" element={<PrivateRoute><AllClaims /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/panic-alerts/:id" element={<PrivateRoute><PanicAlertReports /></PrivateRoute>} />
          <Route path="/region" element={<PrivateRoute><RestrictedRegion /></PrivateRoute>} />
          <Route path="/wallet" element={<PrivateRoute><Wallet /></PrivateRoute>} />
          <Route path="/wallet-otp" element={<PrivateRoute><WalletOtp /></PrivateRoute>} />
          <Route path="/wallet-login" element={<PrivateRoute><WalletLogin /></PrivateRoute>} />
          <Route path="/restricted-areas" element={<PrivateRoute><RestrictedArea /></PrivateRoute>} />
          <Route path="/wallet-transactions/:id" element={<PrivateRoute><WalletTransactions /></PrivateRoute>} />
          <Route path="/subscription/:id" element={<PrivateRoute><Subscription /></PrivateRoute>} />
          <Route path="/flags" element={<PrivateRoute><FlagsDisplay /></PrivateRoute>} />
          <Route path="/buy-airtime" element={<PrivateRoute><BuyAirtime /></PrivateRoute>} />
          <Route path="/invoice" element={<PrivateRoute><Invoices /></PrivateRoute>} />
          <Route path="/select-franchise" element={<PrivateRoute><SelectFranchise /></PrivateRoute>} />
          <Route path="/customer" element={<PrivateRoute><Customers /></PrivateRoute>} />
          <Route path="*" element={<PrivateRoute><NotFound /></PrivateRoute>} />
        </Routes>
      </HashRouter>
    </AuthProvider>
  );
}

export default WebRoutes;
