
import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  Button,
  FormControl,
  Form,
  FormGroup,
  Table,
  Modal,
  Image,
  ModalTitle,
  Dropdown,
  ModalBody,
  FormLabel,
} from 'react-bootstrap';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import '../assets/scss/Tasks.scss';
import { serverUrl } from '../serverUrl';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import MapComponentLocation from '../components/MapComponentLocation';
import TaskAll from '../assets/images/task (5).png'
import TaskAllNew from '../assets/images/task (4).png'
import AssignedTask from '../assets/images/checklist.png'
import PendingTask from '../assets/images/file_6574643.png'
import CryptoJS from 'crypto-js';
import '../assets/scss/LoaderDisplay.scss';


function Tasks() {
  const [isLoading, setIsloading] = useState(false)

  const secretKey = '29cc70df6a85f1a5ac5bdd34f136efe26502cd351b7ed666a424c424b662bdf8';
  const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const [formErrors, setFormErrors] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [showTaskModal, setTaskModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [searchQueryPhone, setSearchQueryPhone] = useState('');
  const [isDescending, setIsDescending] = useState(true); // State to track order
  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };

  const decodedToken = jwtDecode(accessToken);
  const userId = decodedToken.user_id;

  // display the selected franchise
  const [franchiseSelected, setFranchise] = useState('');

  useEffect(() => {
    const selectedFranchise = localStorage.getItem('selectedFranchise');
    if (selectedFranchise) {
      const decryptedData = decryptData(selectedFranchise, secretKey);
      setFranchise(decryptedData);
    }
  }, []);

  const [tasks, setTasks] = useState([]);
  const [formData, setFormData] = useState({
    date: '',
    taskType: '',
    customerName: '',
    customerLocation: '',
    relationshipOfficer: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [selectedTaskTypes, setSelectedTaskTypes] = useState([]);
  const [selectedCustomers, setSelectedCustomer] = useState([]);

  const [optionList, setOptionList] = useState([]);
  const [locationMap, setLocationMap] = useState('');

  const handleLocationSelected = (location) => {
    setLocationMap(location)
  };
  const handleSelectChangeTask = (selectedOption) => {
    setSelectedTaskTypes(selectedOption);
  };

  const handleSelectChangeCustomerMulti = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };


  async function handleSubmit(e) {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    // Determine status based on the presence of a relationship officer
    const status = formData.relationshipOfficer ? 'assigned' : 'not_assigned';
    // Get customer data based on the selected one
    const customer = formData.customerName
      ? customerData.find(c => c.id === parseInt(formData.customerName))
      : null;
    // Prepare data for each selected task type
    // const tasks = selectedTaskTypes.map((task) => ({
    //   task_date: formData.date,
    //   task_type: task.value,
    //   location: customer ? customer.business_location : locationMap,
    //   task_status: status,
    //   task_completed: "pending",
    //   franchise: decryptedData,
    //   customer_name: selectedOptions ? selectedOptions.label : undefined,
    //   relationship_officer: formData.relationshipOfficer ? parseInt(formData.relationshipOfficer) : undefined,
    // }));

    const tasks = selectedTaskTypes.flatMap((task) => {
      if (task.value === 'debt_collection' && selectedCustomers.length > 1) {
        // If task_type is debt_collection and more than one customer selected
        return selectedCustomers.map((customer) => ({
          task_date: formData.date,
          task_type: task.value,
          location: locationMap,
          task_status: status,
          task_completed: 'pending',
          franchise: decryptedData,
          customer_name: customer.label || selectedCustomers?.label,
          relationship_officer: formData.relationshipOfficer ? parseInt(formData.relationshipOfficer) : undefined,
        }));
      } else {
        // For other task types or when only one customer is selected
        return [{
          task_date: formData.date,
          task_type: task.value,
          location: locationMap,
          task_status: status,
          task_completed: 'pending',
          franchise: decryptedData,
          customer_name: selectedCustomers ? selectedCustomers.label : null,
          relationship_officer: formData.relationshipOfficer ? parseInt(formData.relationshipOfficer) : undefined,
        }];
      }
    });


    try {
      // Upload each task
      await Promise.all(
        tasks.map(data => serverUrl.post('/officers_management/task/', data, config))
      );

      // Fetch updated tasks
      const res = await serverUrl.get(`/officers_management/task/?franchise_id=${decryptedData}`, config);
      const sortedTasks = res.data.results.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      // Update state and show success message
      setTasks(sortedTasks);
      toast.success('Successfully created the task');
      setTaskModal(false);
      setFormData({
        date: '',
        taskType: '',
        customerName: '',
        customerLocation: '',
        relationshipOfficer: '',
      });
    } catch (error) {
      console.log(error);
    }
  }




  const validateForm = () => {
    const errors = {};
    if (!formData.date) errors.date = 'Date of Task is required';
    // if (!formData.taskType) errors.taskType = 'Task Type is required';
    return errors;
  };


  const [roData, setROData] = useState([]);
  const [taskCount, setTaskCount] = useState([]);
  const [notassigned, setNotAssigned] = useState(0);
  const [pendingtasks, setPending] = useState(0);
  const [ongoingtasks, setOngoing] = useState(0);
  const [assignedTasks, setAssigned] = useState(0);
  const [originalTasks, setOriginalTasks] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  const handleSelectChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
  };

  const selectedFranchise = localStorage.getItem('selectedFranchise');
  const decryptedData = decryptData(selectedFranchise, secretKey);

  useEffect(() => {
    setIsloading(true)
    const fetchData = async () => {
      try {
        const selectedFranchise = localStorage.getItem('selectedFranchise');
        const decryptedData = decryptData(selectedFranchise, secretKey);

        const response = await serverUrl.get(`/officers_management/task/?franchise_id=${decryptedData}`, config);
        const tasks = response.data.results;
        // Sort tasks by date in descending order (most recent to past)
        const sortedTasks = tasks.sort((a, b) => {
          return new Date(b.task_date) - new Date(a.task_date);
        });

        setTaskCount(response.data.count);
        setTasks(sortedTasks);
        setOriginalTasks(sortedTasks);
        setIsloading(false)


        // Filter tasks with status
        const notCompletedTasks = sortedTasks.filter((task) => task.task_status === 'not_assigned');
        const pendingTasks = sortedTasks.filter((task) => task.task_completed === 'pending');
        const ongoingTasks = sortedTasks.filter((task) => task.task_completed === 'ongoing');
        const assignedTTasks = sortedTasks.filter((task) => task.task_status === 'assigned');
        setNotAssigned(notCompletedTasks.length);
        setAssigned(assignedTTasks.length);
        setPending(pendingTasks.length);
        setOngoing(ongoingTasks.length);

        // Fetch Relationship Officers data
        const responseRO = await serverUrl.get(`/officers_management/relationship-officer/?franchise=${decryptedData}`, config);
        setROData(responseRO.data.results);

        // get the customer data 
        const responseCustomer = await serverUrl.get(`/officers_management/customer/?franchise=${decryptedData}`, config);
        setCustomerData(responseCustomer.data.results)

        const responseSubCountiies = await serverUrl.get(`/officers_management/get-franchise-sub-counties?franchise_id=${decryptedData}`, config);
        const options = responseSubCountiies.data[0].results.map(subcounty => ({
          value: subcounty.id,
          label: subcounty.name
        }));
        setOptionList(options);
      } catch (error) {
        console.error('Error fetching tasks data:', error);
        setIsloading(false)

      }
    };

    fetchData();
  }, []);

  // const toggleOrder = () => {
  //   setIsDescending(!isDescending);
  //   setTasks([...tasks].reverse());
  // };
  const toggleOrder = () => {
    setIsDescending(!isDescending);
    const reversed = setTasks([...tasks].reverse());
  };

  // Function to handle click on eye icon
  const handleDetailsClick = (task) => {
    setSelectedTask(task);
    setShowDetailsModal(true);
  };

  // Function to handle click on pen icon
  const handleEditClick = (task) => {
    setSelectedTask(task);
    setShowEditModal(true);
  };

  // Function to handle click on trash icon
  const handleDeleteClick = (task) => {
    setSelectedTask(task);
    setShowDeleteModal(true);
  };

  const flattenObject = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? `${prefix}.` : '';
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        Object.assign(acc, flattenObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setSearchQuery(searchQuery);
    if (searchQuery === '') {
      setTasks(originalTasks);
    } else {
      const filteredTasks = originalTasks.filter((task) => {
        const flatTask = flattenObject(task);
        return Object.values(flatTask).some(
          (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery)
        );
      });
      setTasks(filteredTasks);
    }
  };





  const [selectedDate, setSelectedDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedTaskStatus, setSelectedTaskStatus] = useState('');
  const [selectedTaskType, setSelectedTaskType] = useState('');
  const [selectedFilterType, setSelectedFilterType] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const years = Array.from({ length: 25 }, (_, i) => 2000 + i); // List of years from 2000 to 2024
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];


  const handleFilter = () => {

    console.log("selectedTaskType:", selectedTaskType.task_type)
    // Create a copy of the original tasks array to apply filters on
    let filteredTasks = [...originalTasks];

    // Apply filters based on selected criteria

    // Filter by Task Status

    if (selectedStatus !== '') {
      filteredTasks = filteredTasks.filter(task => task.task_status === selectedStatus);
    }

    // Filter by Task Completion Status

    if (selectedTaskStatus !== '') {
      filteredTasks = filteredTasks.filter(task => task.task_completed === selectedTaskStatus);
    }

    // Filter by Task Type
    if (selectedTaskType !== '') {
      filteredTasks = filteredTasks.filter(task => task.task_type === selectedTaskType);
    }

    // Filter by Date
    if (selectedFilterType === 'year' && selectedYear !== '') {
      filteredTasks = filteredTasks.filter(task => new Date(task.task_date).getFullYear() === parseInt(selectedYear));
    } else if (selectedFilterType === 'month' && selectedMonth !== '') {
      filteredTasks = filteredTasks.filter(task => new Date(task.task_date).getMonth() === parseInt(selectedMonth) - 1);
    } else if (selectedFilterType === 'specificDate' && selectedDate !== '') {
      filteredTasks = filteredTasks.filter(task => task.task_date === selectedDate);
    }

    // Set the filtered tasks as the new tasks to display
    setTasks(filteredTasks);
    setShowFilterModal(false);
  };

  const validateEditForm = () => {
    const errors = {};

    if (!selectedTask.task_date) errors.date = 'Task date is required';
    if (!selectedTask.task_type) errors.taskType = 'Task type is required';
    if (!selectedTask.customer_name) errors.customerName = 'Customer name is required';
    if (!selectedTask.location) errors.customerLocation = 'Location is required';

    return errors;
  };


  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const errors = validateEditForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    }

    const data = {
      task_date: selectedTask.task_date,
      task_type: selectedTask.task_type,
      customer_name: selectedTask.customer_name,
      location: selectedTask.location,
      relationship_officer: selectedTask.relationship_officer,
      task_status: selectedTask.task_status,
    };
    if (selectedTask.relationship_officer) {
      data.relationship_officer = parseInt(selectedTask.relationship_officer.id)
    }
    console.log(data)

    try {
      await serverUrl.patch(`/officers_management/task/${selectedTask.id}/`, data, config);
      setShowEditModal(false);

      const res = await serverUrl.get(`/officers_management/task/?franchise_id=${decryptedData}`, config);
      const sortedTasks = res.data.results.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      setTasks(sortedTasks);

      toast.success('Task edited successfully');
    } catch (error) {
      console.error('Error editing task:', error);
      toast.error('Failed to edit task. Please try again later.');
    }
  };



  const handledeleteSubmit = () => {
    serverUrl
      .delete(`/officers_management/task/${selectedTask.id}/`, config)
      .then((res) => {
        // Update the tasks list or perform any other necessary actions upon successful submission
        // Close the edit modal
        setShowDeleteModal(false);
        // Fetch updated task data from the server
        serverUrl
          .get(`/officers_management/task/?franchise_id=${decryptedData}`, config)
          .then((res) => {
            const sortedTasks = res.data.results.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setTasks(sortedTasks);
            // Show a success message using toast
            toast.success('Task deleted successfully');
            setShowDeleteModal(false);
          })
          .catch((error) => {
            console.error('Error deleting task data:', error);
            // Show an error message using toast
            toast.error('Failed to delete task data.');
          });
      })
      .catch((error) => {
        console.error('Error editing task:', error);
        // Show an error message using toast
        toast.error('Failed to edit task. Please try again later.');
      });
  };


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Maximum of 10 tasks per page

  // Calculate index of the first and last item of the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current items to display
  const currentItems = tasks.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  const totalPages = Math.ceil(tasks.length / itemsPerPage);


  const formatString = (str) => {
    // Split the string by underscore
    const words = str.split('_');

    // Capitalize the first letter of each word and join them with a space
    const formattedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return formattedString;
  };

  const handleSelectChangeCustomer = (selectedOption) => {
    handleChange({ target: { name: 'customerName', value: selectedOption ? selectedOption.value : '' } });
  };

  const customerOptions = customerData.map((customer) => ({
    value: customer.id,
    label: customer.full_name,
  }));

  const taskTypes = [
    {
      id: 1,
      value: 'marketing',
      label: 'Marketing'
    },
    {
      id: 2,
      value: 'onboarding',
      label: 'Onboarding'
    },
    {
      id: 3,
      value: 'debt_collection',
      label: 'Debt Collection'
    }
  ]

  const taskOptions = taskTypes.map((area) => ({
    value: area.value,
    label: area.label,
  }));



  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: 'white',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#E82949'
        : isSelected
          ? '#E82949'
          : undefined,
      zIndex: 1,
      color: 'black',
    }),
    menu: base => ({
      ...base,
      zIndex: 100,
    }),
  }

  if (isLoading) {
    return (
      <div className='loading-screen'>
        <div className='logo'>

        </div>
      </div>
    )
  }

  return (
    <>
      <SideBar />
      <Header />
      <Container fluid className="tasks">
        <div className="claimsCard">
          <Card className="totalCard">

            <div className="">
              <Image src={TaskAll} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%", marginLeft: "15px" }} />


              <div className='ms-3'>
                <h2>Total Tasks</h2>
                <span className='text-white'>{taskCount} Tasks</span>
              </div>
            </div>

          </Card>



          <Card className="assignedCard">
            <div className=''>
              <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

              <div className='ms-3'>
                <h2>Assigned Tasks</h2>
                <span>{assignedTasks} Tasks</span>
              </div>
            </div>
          </Card>

          <Card className="assignedCard unsassigned">
            <div className=''>
              <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

              <div className='ms-3'>
                <h2>Unassigned Tasks</h2>
                <span>{notassigned} Tasks</span>
              </div>
            </div>
          </Card>

          <Card className="pendingCard">

            <div className=''>
              <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

              <div className='ms-3'>
                <h2>Pending Tasks</h2>
                <span>{pendingtasks} Tasks</span>
              </div>
            </div>


          </Card>

          <Card className="assignedCard ongoing">
            <div className=''>
              <Image src={TaskAllNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />

              <div className='ms-3'>
                <h2>Ongoing Tasks</h2>
                <span>{ongoingtasks} Tasks</span>
              </div>
            </div>
          </Card>


        </div>

        {/* <Link to="/all-tasks" className="text-decoration-none viewtasks mobile">
          <Button className='button-mobile view-all'>View Tasks</Button>
        </Link> */}

        <div className="d-flex justify-content-between topFiltersData">
          <Button
            className="btn btn-sm btn-warning addTask text-white"
            onClick={() => setTaskModal(true)}
          >
            <i className="bi bi-plus"></i>Add Task
          </Button>

          <div className="d-flex justify-content-between filterTopsData">
            {/* <Button onClick={() => setShowFilterModal(true)} className="">
              <i className="bi bi-funnel-fill me-2"></i>
              <span>Filters</span>
            </Button> */}

            {selectedTaskStatus || selectedTaskType || selectedYear || selectedStatus || selectedFilterType || selectedDate || selectedMonth

              ?

              <Button className='btn btn-sm bg-danger border-danger text-white' style={{ borderRadius: "20px", height: "4vh" }} onClick={() => { setSelectedTaskStatus(''); setSelectedStatus(''); setSelectedFilterType(''); setTasks(originalTasks); setSelectedMonth(''); setSelectedDate(''); setSelectedYear(''); }}>
                Reset Filters
              </Button>
              :
              <Button className='btn btn-sm bg-transparent border-danger text-danger filter-tasks' style={{ borderRadius: "20px", height: "4vh" }} onClick={() => setShowFilterModal(true)}>
                <i className="bi bi-funnel-fill me-2"></i>
                Filter
              </Button>
            }

            <Button className="lastappdate">
              <i className="bi bi-arrow-up"></i>
              <i className="bi bi-arrow-down me-2"></i>
              <span onClick={toggleOrder}>Last Appointment Date</span>
            </Button>
          </div>

        </div>

        {originalTasks.length === 0 ? (
          <p style={{ fontSize: '25px', marginTop: '10px' }}>
            No tasks available kindly add one
          </p>
        ) : (

          <div className='taskDisplay'>
            <Form className="searchBar">
              <FormControl
                type="text"
                placeholder="Search Task"
                onChange={handleSearch}
              />
            </Form>
            <Table responsive >

              <thead>
                <tr className="table">
                  <th>Date</th>
                  <th>Task Type</th>
                  <th>Customer</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Task Status</th>
                  <th>Field Officer</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((task) => (
                  <tr key={task.id}>
                    <td>{task.task_date}</td>
                    <td>{formatString(task.task_type)}</td>
                    <td>{task.customer_name ? task.customer_name : `Random Customer`}</td>
                    <td>{task.location}</td>
                    {/* <td>{task.customer_location},{task.county}</td> */}
                    {task.task_status === 'assigned' ?
                      <td >{formatString(task.task_status)}</td>

                      :
                      <td className='text-warning'>{formatString(task.task_status)}</td>


                    }
                    <td>

                      <span style={{ borderRadius: "20px" }} className={`btn text-white w-100 btn-sm ${task.task_completed === 'completed' ? 'bg-success' : task.task_completed === 'pending' ? 'bg-danger' : 'bg-warning'}`}>{task.task_completed}</span>
                    </td>
                    {task.relationship_officer ? (
                      <td className=''>{task.relationship_officer.user.full_name}</td>
                    ) : (
                      <td className='text-warning'>Not Assigned</td>
                    )}

                    <td className="actions">
                      <Dropdown>
                        <Dropdown.Toggle variant="link" className="btn btn-sm text-dark p-0">
                          <i className="bi bi-three-dots-vertical"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleDetailsClick(task)}>
                            <Button style={{ width: "10vw" }} className='btn btn-sm btn-warning'> <i className="bi bi-eye me-2"></i> View</Button>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleEditClick(task)}>
                            <Button style={{ width: "10vw" }} className='btn btn-sm btn-info'><i className="bi bi-pen me-2"></i> Edit</Button>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeleteClick(task)}>
                            <Button style={{ width: "10vw" }} className='btn btn-sm btn-danger'><i className="bi bi-trash me-2"></i> Delete</Button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {tasks.length > 0 && (
              <nav>
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage - 1)}>
                      {'<'}
                    </button>
                  </li>
                  <li className="page-item disabled">
                    <span className="page-link">
                      {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, tasks.length)} of {tasks.length}
                    </span>
                  </li>
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => paginate(currentPage + 1)}>
                      {'>'}
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        )}



        {/* Add1ng Confirmation Modal */}
        <Modal show={showTaskModal} onHide={() => setTaskModal(false)} centered className='locationModalMap'>
          <Modal.Header closeButton>
            <Modal.Title>Add Task</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflowY: "scroll", maxHeight: "70vh" }}>
            <Form onSubmit={handleSubmit} className="formTask">
              <FormGroup>
                <Form.Label>Date of Task</Form.Label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  min={getCurrentDate()}
                  required

                />
                {formErrors.date && <span className="text-danger validate">{formErrors.date}</span>}
              </FormGroup>

              <FormGroup>
                <Form.Label>Task Type</Form.Label>
                <Select
                  styles={colourStyles}

                  value={taskTypes.find(option => option.value === formData.value)}
                  onChange={handleSelectChangeTask}
                  options={taskOptions}
                  placeholder="Select Task Type"
                  isClearable
                  isMulti

                />

                {!selectedTaskTypes && <span className="text-danger validate">Task type is required</span>}
              </FormGroup>
              <FormGroup>
                <Form.Label>Field Officer (Optional)</Form.Label>
                <Form.Control
                  as="select"
                  name="relationshipOfficer"
                  value={formData.relationshipOfficer}
                  onChange={handleChange}
                >
                  <option value="">Select Field Officer</option>
                  {roData.map((RO) => (
                    <option key={RO.id} value={RO.id}>{RO.user.full_name}</option>
                  ))}
                </Form.Control>
              </FormGroup>

              {/* if the task type is marketing or onboarding show the location data */}
              {/* {formData.taskType === 'marketing' || formData.taskType === 'onboarding' ? (
                <>
                  <FormGroup>
                    <FormLabel>Location</FormLabel>
                    <MapComponentLocation onLocationSelected={handleLocationSelected} />

                  </FormGroup>

                </>
              ) : formData.taskType === 'debt_collection' ?

                <>

                  <FormGroup>
                    <Form.Label>Customer</Form.Label>
                    <Select
                      styles={colourStyles}

                      value={customerOptions.find(option => option.value === formData.id)}
                      onChange={handleSelectChange}
                      options={customerOptions}
                      placeholder="Select Customer"
                      isClearable
                    />
                    {formErrors.customerName && <span className="text-danger validate">{formErrors.customerName}</span>}
                  </FormGroup>


                  <FormGroup>
                    <FormLabel>Location</FormLabel>
                    <MapComponentLocation onLocationSelected={handleLocationSelected} />

                  </FormGroup>

                </>

                :
                <></>
              } */}

              {selectedTaskTypes.some(task => task.value === 'debt_collection') ? (
                <>
                  <FormGroup>
                    <Form.Label>Select Customer</Form.Label>
                    <Select
                      styles={colourStyles}
                      value={customerOptions.find(option => option.value === formData.id)}
                      onChange={handleSelectChangeCustomerMulti}
                      options={customerOptions}
                      placeholder="Select Customer"
                      isClearable
                      isMulti

                    />
                    {formErrors.customerName && <span className="text-danger validate">{formErrors.customerName}</span>}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Location</FormLabel>
                    <MapComponentLocation onLocationSelected={handleLocationSelected} />
                  </FormGroup>
                </>
              ) : selectedTaskTypes.some(task => task.value === 'marketing' || task.value === 'onboarding') ? (
                <>
                  <FormGroup>
                    <FormLabel>Location</FormLabel>
                    <MapComponentLocation onLocationSelected={handleLocationSelected} />
                  </FormGroup>
                </>
              ) : null}



            </Form>
          </Modal.Body >
          <Modal.Footer>
            <Button variant="dark" onClick={() => setTaskModal(false)}>
              Cancel
            </Button>
            <Button variant="warning" type='submit' onClick={handleSubmit}>
              Add
            </Button>
          </Modal.Footer>
        </Modal >

        {/* Details Modal */}
        {
          selectedTask && (
            <Modal
              show={showDetailsModal}
              onHide={() => setShowDetailsModal(false)}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className='text-capitalize'>
                  {selectedTask.customer_name ? selectedTask.customer_name : selectedTask.relationship_officer?.user.full_name ? selectedTask.relationship_officer.user.full_name : selectedTask.task_type}'s Task
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form className="formTask">
                  <FormGroup>
                    <Form.Label>Date of Task</Form.Label>
                    <input

                      className="form-control"
                      name="date"
                      value={selectedTask.task_date}
                      readOnly
                    />
                  </FormGroup>

                  <FormGroup>
                    <Form.Label>Task Type</Form.Label>
                    <Form.Control

                      name="taskType"
                      value={selectedTask.task_type}
                      readOnly
                    >

                    </Form.Control>
                  </FormGroup>
                  {selectedTask.customer_name &&
                    <FormGroup>
                      <Form.Label>Customer Name</Form.Label>
                      <FormControl
                        name="customerName"
                        value={selectedTask.customer_name}
                        readOnly
                      />
                    </FormGroup>

                  }


                  <FormGroup>
                    <Form.Label>Location</Form.Label>
                    <FormControl
                      name="customerLocation"
                      value={selectedTask.location}
                      readOnly
                    />
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>Task Status</Form.Label>
                    <FormControl
                      name="taskStatus"
                      value={formatString(selectedTask.task_status)}
                      readOnly
                    />
                  </FormGroup>

                  {selectedTask.relationship_officer && (

                    <FormGroup>
                      <Form.Label>Field Officer</Form.Label>
                      {/* the list of the ro roData */}
                      <Form.Control
                        name="relationshipOfficer"
                        value={selectedTask.relationship_officer?.user.full_name}
                        readOnly
                      >
                      </Form.Control>
                    </FormGroup>
                  )}

                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowDetailsModal(false)}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          )
        }

        {/* Edit Modal */}

        {
          selectedTask && (
            <Modal
              show={showEditModal}
              onHide={() => setShowEditModal(false)}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Task</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ overflowY: "scroll", maxHeight: "70vh" }}>
                <Form onSubmit={handleEditSubmit} className="formTask">
                  <FormGroup>
                    <Form.Label>Date of Task</Form.Label>
                    <input
                      type="date"
                      className="form-control"
                      name="task_date"
                      value={selectedTask.task_date}
                      min={getCurrentDate()}
                      onChange={(e) => setSelectedTask({ ...selectedTask, task_date: e.target.value })}

                    />
                    {formErrors.date && <span className="text-danger validate">{formErrors.date}</span>}
                  </FormGroup>

                  <FormGroup>
                    <Form.Label>Task Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="task_type"
                      value={selectedTask.task_type}
                      onChange={(e) => setSelectedTask({ ...selectedTask, task_type: e.target.value })}
                    >
                      <option value="">Select Task Type</option>
                      <option value="marketing">Marketing</option>
                      <option value="onboarding">Onboarding</option>
                      <option value="debt_collection">Debt Collection</option>
                    </Form.Control>
                    {/* {formErrors.taskType && <span className="text-danger validate">{formErrors.taskType}</span>} */}

                  </FormGroup>

                  {roData && (
                    <FormGroup>
                      <Form.Label>Field Officer</Form.Label>
                      <Form.Control
                        as="select"
                        name="relationship_officer"
                        value={selectedTask?.relationship_officer?.id || ""}
                        onChange={(e) => {
                          const selectedRO = roData.find((RO) => RO.id === parseInt(e.target.value, 10));
                          setSelectedTask({
                            ...selectedTask,
                            relationship_officer: selectedRO || {},
                          });
                        }}
                      >
                        <option value="" disabled>Select Field Officer</option>
                        {roData.map((RO) => (
                          <option key={RO.id} value={RO.id}>{RO?.user?.full_name}</option>
                        ))}
                      </Form.Control>
                    </FormGroup>
                  )}



                  {selectedTask.customer_name && (
                    <FormGroup>
                      <Form.Label>Customer Name</Form.Label>
                      <FormControl
                        type="text"
                        name="customer_name"
                        value={selectedTask.customer_name}
                        onChange={(e) => setSelectedTask({ ...selectedTask, customer_name: e.target.value })}
                      />
                      {/* {formErrors.customerName && <span className="text-danger validate">{formErrors.customerName}</span>} */}
                    </FormGroup>

                  )}



                  <FormGroup>
                    <Form.Label>Location</Form.Label>
                    <FormControl
                      type="text"
                      name="customer_location"
                      value={selectedTask.location}
                      onChange={(e) => setSelectedTask({ ...selectedTask, location: e.target.value })}
                    />
                    {formErrors.customerLocation && <span className="text-danger validate">{formErrors.customerLocation}</span>}
                  </FormGroup>


                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="dark" onClick={() => setShowEditModal(false)}>
                  Cancel
                </Button>
                <Button variant="success" onClick={handleEditSubmit}>
                  Edit
                </Button>
              </Modal.Footer>
            </Modal>

          )
        }



        {/* Delete Confirmation Modal */}
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Tasks</Modal.Title>
          </Modal.Header>
          {/* Modal content */}
          <ModalBody>
            Are you sure you want to delete this data?
          </ModalBody>
          <Modal.Footer>
            {/* <Button
              type='button'
              variant="dark"
              onClick={() => setShowFilterModal(false)}
            >
              Close
            </Button> */}
            <Button className='delete' variant="info" onClick={handledeleteSubmit}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* modal for filtering the tasks */}

        <Modal
          show={showFilterModal}
          onHide={() => setShowFilterModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter Tasks</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Status filter */}
            <Form.Group controlId="formStatus">
              <Form.Label>Filter By Assigned Tasks</Form.Label>
              <Form.Control
                as="select"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">Select status</option>
                <option value="assigned">Assigned</option>
                <option value="not_assigned">Not Assigned</option>
              </Form.Control>
            </Form.Group>

            {/* Task Status filter */}
            <Form.Group controlId="formStatus">
              <Form.Label>Filter By Task Status</Form.Label>
              <Form.Control
                as="select"
                value={selectedTaskStatus}
                onChange={(e) => setSelectedTaskStatus(e.target.value)}
              >
                <option value="">Select status</option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
                <option value="ongoing">Ongoing</option>
              </Form.Control>
            </Form.Group>

            {/* Task Type filter */}
            <Form.Group controlId="formTaskType">
              <Form.Label>Filter By Task Type</Form.Label>
              <Form.Control
                as="select"
                value={selectedTaskType}
                onChange={(e) => setSelectedTaskType(e.target.value)}
              >
                <option value="">Select task type</option>
                <option value="marketing">Marketing</option>
                <option value="onboarding">Onboarding</option>
                <option value="debt_collection">Debt Collection</option>
              </Form.Control>
            </Form.Group>

            {/* Date filter */}
            <Form.Group controlId="formDate">
              <Form.Label>Filter By Date</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Year"
                  checked={selectedFilterType === 'year'}
                  onChange={() => setSelectedFilterType('year')}
                />
                {selectedFilterType === 'year' && (
                  <Form.Control
                    as="select"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                    {/* Render the list of years from 2000 to 2024 */}
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Control>
                )}
              </div>

              <div>
                <Form.Check
                  type="radio"
                  label="Month"
                  checked={selectedFilterType === 'month'}
                  onChange={() => setSelectedFilterType('month')}
                />
                {selectedFilterType === 'month' && (
                  <Form.Control
                    as="select"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    {/* Render the list of months */}
                    <option value="" selected disabled>Select Month </option>
                    {months.map((month, index) => (
                      <option key={index} value={index + 1}>
                        {month}
                      </option>
                    ))}
                  </Form.Control>
                )}
              </div>

              <div>
                <Form.Check
                  type="radio"
                  label="Specific Date"
                  checked={selectedFilterType === 'specificDate'}
                  onChange={() => setSelectedFilterType('specificDate')}
                />
                {selectedFilterType === 'specificDate' && (
                  <Form.Control
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                )}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="dark"
              onClick={() => setShowFilterModal(false)}
            >
              Close
            </Button>
            <Button variant="warning" onClick={handleFilter}>
              Filter
            </Button>
          </Modal.Footer>
        </Modal>
      </Container >
    </>
  );
}

export default Tasks;
