import React, { useEffect, useState } from 'react';
import { Button, Container, Dropdown, Image, Nav } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import '../assets/scss/Header.scss';
import { serverUrl } from '../serverUrl';
import {jwtDecode} from 'jwt-decode';
import ArrowBack from "../assets/images/back-button.png";
import { useLocation, useNavigate,Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function Header() {
  const location = useLocation();
  const hash = window.location.hash;
  const parts = hash.substring(2).split('-');
  const currentUrl = location.pathname.substring(1);

  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };

  const decodedToken = jwtDecode(access);
  const userId = decodedToken.user_id;
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await serverUrl.get(`/user/edit-user/${userId}`, config);
        setUserDetails(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This navigates back to the previous page in the history stack
  };

  const handleLogout = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem("authTokens");
    toast.success('Successfull logged out !')
    navigate('login')
  }
  return (
    <>
      <Container fluid className='header'>
        <div className='d-flex justify-content-between header' style={{ height: 'inherit', position: "fixed" }}>
          {/* {(currentUrl === "all-tasks") && (
            <button className='backarrow' onClick={handleBackClick}>
              <img src={ArrowBack} alt='back' />
            </button>
          )} */}

          <h2 className='text-uppercase mt-2 title' style={{ fontSize: "14px", textTransform: "uppercase",position:"relative",left:"70px" }}>{currentUrl}</h2>

          <Dropdown>
            <Dropdown.Toggle variant="link" id="dropdown-basic" className='p-0'>
              <Image src={userDetails.profile_picture} roundedCircle width={40} height={40} />
            </Dropdown.Toggle>

            <Dropdown.Menu align="right" className="dropdown-menu-with-arrow">
              <div className="arrow-up"></div>
              <div className="p-2" style={{ backgroundColor: '#E82949', color: '#fff', textAlign: 'left',marginTop:"-1px" }}>
                <Image src={userDetails.profile_picture} roundedCircle width={40} height={40} className='me-2' style={{left:"0"}} />
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  <p className="m-0">{userDetails.full_name}</p>
                  <span className="m-0">{userDetails.phone_number}</span>
                </div>
              </div>
              <div className="p-2 text-start d-block" style={{ backgroundColor: '#fff' }}>
                <Nav.Link as={Link} to="/profile" className="d-block text-decoration-none text-dark text-left mb-3 w-100">
                  <i className="bi bi-person"></i> Profile
                </Nav.Link>
              
                <Nav.Link onClick={handleLogout} as={Link} to="/login" className="d-block text-decoration-none text-dark text-left mb-3 w-100">
                  <i className="bi bi-box-arrow-right"></i> Sign Out
                  </Nav.Link>
                  </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>
    </>
  );
}

export default Header;
