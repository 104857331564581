import React, { useState } from 'react';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import '../assets/scss/BuyAirtime.scss';
import { Card, Button, Modal, Image, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BUYairtime from '../assets/images/phone-ringing.png';
import WalletNew from '../assets/images/phone-ringing (2).png';
import { useBuyAirtime } from '../hooks/useBuyAirtime';
import { serverUrl } from '../serverUrl';
import { toast } from 'react-toastify';
import Select from 'react-select';
import AirtimeTransactions from '../components/AirtimeTransactions';

function BuyAirtime() {
  const {
    walletFound, adminPhone,
    walletAirtimeBalance,
    subscription,
    subscriptionStatus,
    showModal,
    handleShowModal,
    handleCloseModal,
    fieldofficerCount,
    fieldofficer,
    franchiseSelected,
    currentWallet,
    config,
    adminId,
    modalType
  } = useBuyAirtime();

  const [loading, setLoad] = useState(false)

  // the formdata 
  const [formData, setFormData] = useState({
    amount: '',
    paymentMethod: "",
    phoneNo: "",
    walletId: ""
  })

  const handleChangeRoDeposit = (event) => {
    const { name, value } = event.target;
    setFormData((prevformData) => ({
      ...prevformData,
      [name]: value,
    }));
  };



  const handleTopUpWallet = (event) => {
    const data = {
      amount: formData.amount,
      paymentMethod: formData.paymentMethod,
      phoneNo: formData.phoneNo,
      wallet: currentWallet,

    }
    setLoad(true)

    let phone_number
    if (formData.phoneNo.startsWith('0')) {
      phone_number = '254' + formData.phoneNo.slice(1);
    }

    if (formData.paymentMethod === 'mpesa') {
      serverUrl.get(`/officers_management/wallet-account-balance?wallet=${currentWallet}`, config)
        .then((res) => {
          const current_balance = parseInt(res.data.results[0].wallet_balance)
          const formDataWallet = new FormData();
          formDataWallet.append('franchise_id', franchiseSelected);
          formDataWallet.append('wallet_id', currentWallet);
          formDataWallet.append('phone_number', phone_number);
          formDataWallet.append('current_balance', current_balance);
          formDataWallet.append('amount', formData.amount);

          // post the data
          serverUrl.post(`/officers_management/deposit-wallet/`, formDataWallet, config)
            .then((res) => {
              toast.success('Request is succesful kindly wait for you to input your pin')
              handleCloseModal()
              setLoad(false)

              setFormData({
                amount: '',
                paymentMethod: "",
                phoneNo: ""
              })
            })
            .catch((error) => {
              console.log(error)
            })

        })
        .catch((error) => {
          console.log(error)
        })

    }

    else if (formData.paymentMethod === 'wallet') {
      // inbound transaction save it

      serverUrl.get(`/officers_management/wallet-account-balance?wallet=${currentWallet}`, config)
        .then((res) => {
          const current_balance = parseInt(res.data.results[0].wallet_balance) + parseInt(formData.amount)
          const data = {
            franchise: franchiseSelected,
            wallet: currentWallet,
            current_balance: parseInt(res.data.results[0].wallet_balance) + parseInt(formData.amount),
            transcation_type: "inbound",
            amount: formData.amount,
            status: "success",
            reason: "Succesfully Transfered"

          }

          // post the data
          serverUrl.post(`/officers_management/wallet-transactions/`, data, config)
            .then((res) => {
              toast.success('Succesfully done the transactions')
              handleCloseModal()


              setFormData({
                amount: '',
                paymentMethod: "",
                phoneNo: "",
                walletId: ""
              })
            })
            .catch((error) => {
              console.log(error)
            })

        })
        .catch((error) => {
          console.log(error)
        })

      // outbound transaction save it
      serverUrl.get(`/officers_management/wallet-account-balance?wallet=${formData.walletId}`, config)
        .then((res) => {
          const current_balance = parseInt(res.data.results[0].wallet_balance) - parseInt(formData.amount)
          const formDataWallet = new FormData();
          formDataWallet.append('franchise', franchiseSelected);
          formDataWallet.append('wallet', formData.walletId);
          formDataWallet.append('current_balance', current_balance);
          formDataWallet.append('amount', formData.amount);
          formDataWallet.append('transcation_type', 'outbound');
          formDataWallet.append('status', 'success');
          formDataWallet.append('reason', 'Succesfully Transfered');


          // post the data
          serverUrl.post(`/officers_management/wallet-transactions/`, formDataWallet, config)
            .then((res) => {
              setLoad(false)
              window.location.reload()

            })
            .catch((error) => {
              console.log(error)
            })

        })
        .catch((error) => {
          console.log(error)
        })



    }


  }

  // the top up airtime
  const [formDataAirtime, setFormDataAirtime] = useState({
    amount: '',
    selectedFieldOfficer: '',
    phoneNumber: "",
  })
  const [ownAmount, setOwnAmount] = useState('')

  const handleOwnAirtime = (e) => {
    let phone_number
      if (adminPhone.startsWith('0')) {
        phone_number = '+254' + adminPhone.slice(1);
      }
    const formDataPOST = new FormData()
    formDataPOST.append('wallet_id', parseInt(currentWallet))
    formDataPOST.append('admin', adminId)
    formDataPOST.append('amount', ownAmount)
    formDataPOST.append('phone_number', phone_number)

    // post the data
    serverUrl.post(`/officers_management/buy-airtime/`, formDataPOST, config)
      .then((res) => {
        setOwnAmount('')
        handleCloseModal()
        toast.success('Succesfully bought the airtime')

      })
      .catch((error) => {
        console.log(error)
      })

  }

  const handleOfficerChange = (event) => {
    const { name, value } = event.target;
    setFormDataAirtime((prevformData) => ({
      ...prevformData,
      [name]: value,
    }));
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredOfficers = fieldofficer && fieldofficer.filter(officer =>
    officer.full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [selectedOfficers, setSelectedOfficers] = useState([]);


  const handleBuyRoAirtime = (event) => {
    const formdataPost = new FormData()

    formdataPost.append('wallet_id', parseInt(currentWallet))
    formdataPost.append('amount', formDataAirtime.amount)
    formdataPost.append('admin', adminId)
    if (formDataAirtime.selectedFieldOfficer === 'all') {
      // Loop through all the relationship officers
      fieldofficer.forEach((relationshipOfficer) => {
        let phone_number
        if (relationshipOfficer.phone.startsWith('0')) {
          phone_number = '+254' + relationshipOfficer.phone.slice(1);
        }
        formdataPost.append('relationship_officer', relationshipOfficer.ro_id);
        formdataPost.append('phone_number', phone_number);

        // post the data for airtime
        serverUrl.post(`/officers_management/buy-airtime/`, formdataPost, config)
          .then((res) => {
            toast.success('Succesfuly sent the airtime')
            handleCloseModal()
            setSelectedOfficers(null)

            setFormDataAirtime({
              amount: '',
              selectedFieldOfficer: '',
              phoneNumber: "",
            })
          })
          .catch((error) => {
            console.log(error)
          })
      });
    }
    else if (formDataAirtime.selectedFieldOfficer === 'phoneNumber') {
      // Send to that phone number
      let phone_number
      if (formDataAirtime.phoneNumber.startsWith('0')) {
        phone_number = '+254' + formDataAirtime.phoneNumber.slice(1);
      }
      formdataPost.append('phone_number', phone_number)
      // post the data for airtime
      serverUrl.post(`/officers_management/buy-airtime/`, formdataPost, config)
        .then((res) => {
          toast.success('Succesfuly sent the airtime')
          handleCloseModal()
          setSelectedOfficers(null)
          setFormDataAirtime({
            amount: '',
            selectedFieldOfficer: '',
            phoneNumber: "",
          })
        })
        .catch((error) => {
          console.log(error)
        })
    } else {

      // loop through the selected officers
      selectedOfficers.forEach(officer => {

        const [officerId, officerPhone] = formDataAirtime.selectedFieldOfficer.split('|');
        let phone_number
        if (officerPhone.startsWith('0')) {
          phone_number = '+254' + officerPhone.slice(1);
        }
        formdataPost.append('relationship_officer', officerId);
        formdataPost.append('phone_number', phone_number);
        serverUrl.post(`/officers_management/buy-airtime/`, formdataPost, config)
          .then((res) => {
            toast.success('Succesfuly sent the airtime')
            handleCloseModal()
            setSelectedOfficers(null)

            setFormDataAirtime({
              amount: '',
              selectedFieldOfficer: '',
              phoneNumber: "",
            })
          })
          .catch((error) => {
            console.log(error)
          })
      })
    }
  };

  const officerOptions = [
    { value: '', label: 'Select Field Officer' },
    { value: 'all', label: 'All Field Officers' },
    { value: 'phoneNumber', label: 'Enter Phone Number' },

    ...Array.isArray(fieldofficer) ? fieldofficer.map(officer => ({
      value: `${officer.ro_id}|${officer.phone}`,
      label: officer.full_name
    })) : []
  ];


  const handleSelectChange = (selectedOptions) => {
    // Check if 'phoneNumber' is selected
    if (selectedOptions.some(option => option.value === 'phoneNumber')) {
      // Only keep the 'phoneNumber' option
      const phoneNumberOption = officerOptions.find(option => option.value === 'phoneNumber');
      setSelectedOfficers([phoneNumberOption]);
      handleOfficerChange({ target: { name: 'selectedFieldOfficer', value: 'phoneNumber' } });
    } else if (selectedOptions.some(option => option.value === 'all')) {
      // Handle the 'all' option selection
      const allOfficers = officerOptions.filter(option => option.value !== 'all' && option.value !== '' && option.value !== 'phoneNumber');
      setSelectedOfficers(allOfficers);
      handleOfficerChange({ target: { name: 'selectedFieldOfficer', value: 'all' } });
    } else {
      // Handle other selections
      setSelectedOfficers(selectedOptions);
      handleOfficerChange({ target: { name: 'selectedFieldOfficer', value: selectedOptions.map(option => option.value).join(',') } });
    }
  };

  // update on the modal render
  const renderModalContent = () => {
    switch (modalType) {
      case 'topupWallet':
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Top Up Airtime Wallet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleTopUpWallet}>
                <Form.Group controlId="formAmount">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control name='amount' type="number" placeholder="Enter amount" value={formData.amount} onChange={handleChangeRoDeposit} />
                </Form.Group>
                <Form.Group controlId="formPaymentMethod">
                  <Form.Label>Payment Method</Form.Label>
                  <Form.Select name='paymentMethod' className="formProperty" value={formData.paymentMethod} onChange={handleChangeRoDeposit}>
                    <option value='' selected disabled>Select payment method</option>
                    <option value='mpesa'>MPESA</option>
                    <option value='wallet'>Wallet</option>
                  </Form.Select>
                </Form.Group>

                {/* check the payment method and display data accordingly */}
                {formData.paymentMethod === 'mpesa' ?
                  <Form.Group controlId="formPaymentMethod">
                    <Form.Label>Enter Phone Number</Form.Label>
                    <Form.Control name='phoneNo' type="number" placeholder="Enter phone number" value={formData.phoneNo} onChange={handleChangeRoDeposit} />

                  </Form.Group>
                  : formData.paymentMethod === 'wallet' ?
                    <Form.Group controlId="formPaymentMethod">
                      <Form.Label>Select wallet  </Form.Label>
                      <Form.Select className="formProperty" value={formData.walletId}
                        name='walletId' onChange={handleChangeRoDeposit}>
                        <option value='' selected>Select Wallet</option>
                        {walletFound.map((wallet) => (
                          <option key={wallet.id} value={wallet.id} disabled={wallet.balance === 0}>
                            {wallet.name}
                            {wallet.balance === 0 && (
                              <span style={{ color: 'red', marginLeft: '10px' }}> - insufficient funds</span>
                            )}

                          </option>
                        ))}

                      </Form.Select>
                    </Form.Group>
                    :
                    <></>
                }

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              {loading ?
                <Button variant="primary">
                  <Spinner />
                </Button>
                :
                <Button variant="primary" type='submit' onClick={handleTopUpWallet}>
                  Topup
                </Button>
              }

            </Modal.Footer>
          </>
        );
      case 'fieldOfficer':
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Field Officer Buy Airtime Top Up</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form onSubmit={handleBuyRoAirtime}>
                <Form.Group controlId="formOfficerId">
                  <Form.Label>Field Officer</Form.Label>

                  <Select
                    options={officerOptions}
                    value={selectedOfficers}
                    onChange={handleSelectChange}
                    isMulti
                    isSearchable
                  />
                  {formDataAirtime.selectedFieldOfficer === "phoneNumber" && (
                    <>
                      <Form.Label className='mt-1'>Enter Phone Number</Form.Label>

                      <Form.Control
                        type="text"
                        className='mt-2'
                        placeholder="Enter phone number to get airtime"
                        value={formDataAirtime.phoneNumber}
                        name='phoneNumber'
                        onChange={handleOfficerChange}
                      />
                    </>
                  )}
                </Form.Group>
                <Form.Group controlId="formAmount">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter amount"
                    value={formDataAirtime.amount}
                    name='amount'
                    onChange={handleOfficerChange}
                  />
                </Form.Group>
              </Form>


            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="danger" type='submit' className='btn btn-sm text-white' onClick={handleBuyRoAirtime}>
                Buy Airtime
              </Button>
            </Modal.Footer>
          </>
        );
      case 'topupAccount':
        return (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Buy Airtime For Myself</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleOwnAirtime}>
                <Form.Group controlId="formAmount">
                  <Form.Label >Amount</Form.Label>
                  <Form.Control value={ownAmount} onChange={(e) => setOwnAmount(e.target.value)} type="number" placeholder="Enter amount" />
                </Form.Group>

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="danger" onClick={handleOwnAirtime} className='btn btn-sm'>
                TopUp
              </Button>
            </Modal.Footer>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Header />
      <SideBar />

      <div className='buy-airtime'>
        <div className='claimsCard'>
          <div className='totalpending'>
            <div className='cards1'>
              <Card className='totalCard'>
                <div className=''>
                  <Image src={WalletNew} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />
                  <div className='ms-3'>
                    <h2 className='text-white'>Airtime Wallet Balance</h2>
                    <span className='text-white'>Ksh.{walletAirtimeBalance && walletAirtimeBalance.toLocaleString()}</span>
                  </div>
                </div>
              </Card>

              <Card className='subscription'>
                <div className=''>
                  <Image src={BUYairtime} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />
                  <div className='ms-3'>
                    <h2>Top up Wallet</h2>
                    <span className=''>Ksh.{walletAirtimeBalance && walletAirtimeBalance.toLocaleString()}</span>
                    <Button style={{ marginTop: "0px", left: "75px" }} className='btn btn-sm bg-white text-dark border-0' onClick={() => handleShowModal('topupWallet')}>Topup</Button>
                  </div>
                </div>
              </Card>

              <Card className='subscription'>
                <div className=''>
                  <Image src={BUYairtime} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />
                  <div className='ms-3'>
                    <h2>Current Subscription</h2>
                    <span style={{ fontSize: "10px", borderRadius: "20px" }} className={`text-white p-2 text-capitalize ${subscriptionStatus === 'unpaid' ? 'bg-danger' : subscriptionStatus === 'expired' ? 'bg-warning' : 'bg-success'}`}>
                      {subscriptionStatus}
                    </span>
                    <Link className='text-decoration-none text-dark' to={`/subscription/${subscription}/`}>
                      <Button style={{ marginTop: "0px", left: "120px" }} className='btn btn-sm bg-white w-auto text-dark border-0'>View</Button>
                    </Link>
                  </div>
                </div>
              </Card>
            </div>

            <div className='cards2'>
              <Card className='pendingCard'>
                <div className=''>
                  <Image src={BUYairtime} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />
                  <div className='ms-3'>
                    <h2>Field Officer Top Up Airtime</h2>
                    <span>{fieldofficerCount && fieldofficerCount} Officers</span>
                    <Button className='btn btn-sm bg-white text-dark w-10 border-0' onClick={() => handleShowModal('fieldOfficer')}>Buy Airtime</Button>
                  </div>
                </div>
              </Card>

              <Card className='assignedCard'>
                <div className=''>
                  <Image src={BUYairtime} style={{ width: "25%", background: "white", padding: "10px", borderRadius: "50%" }} />
                  <div className='ms-3'>
                    <h2>Airtime For My Account</h2>
                    <span style={{ fontSize: "10px", borderRadius: "20px" }} className={`text-white p-2 text-capitalize ${subscriptionStatus === 'unpaid' ? 'bg-transparent' : subscriptionStatus === 'expired' ? 'bg-transparent' : 'bg-transparent'}`}>
                    </span>
                    <Button style={{ marginTop: "0px", left: "80px" }} onClick={() => handleShowModal('topupAccount')} className='btn btn-sm bg-white w-auto text-dark border-0'>Buy Airtime</Button>

                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <AirtimeTransactions/>

        <Modal show={showModal} onHide={handleCloseModal}>
          {renderModalContent()}
        </Modal>
      </div>
    </>
  );
}

export default BuyAirtime;
